/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react'

// import {
//   Link, // useLocation, // useNavigate,
// } from 'react-router-dom'
import { WarningOutlined } from '@ant-design/icons'
import { Button, Layout, Space } from 'antd'
import _ from 'lodash'
import Swal from 'sweetalert2'

import { CheckUserInfo, Loading, PageHeader, Trending } from '../../components'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import { filtersAPI } from '../../api'

import { formatFIs, formatTrendingColumns, formatTrendingColumns2 } from '../../common/utils/helpers'
import './trends-page2.css'

const { Content } = Layout

export const TrendsPage2 = () => {
  const { filterSelection, setFilterSelection, tagSelection, setTagSelection, chosenFIs, setChosenFIs, setFIs, timeValues } = useContext(FiltersContext)
  const { userInfo, getChosenFIs } = useContext(ThemeContext)
  const {
    setIsTrendsLoading,
    isTrendsLoading,
    trendsData,
    trendsTrendingData,
    setTrendsTrendingData,
    setIsTrendingLoading,
    isTrendingLoading,
    setTrendingResp,
    isTrendsCallActive,
    setIsTrendsCallActive,
    // setTrendsFilterSelection,
  } = useContext(DataContext)

  // const navigate = useNavigate()
  // const location = useLocation()
  // const prevPages = location.state?.prevPages

  // const [state, setState] = useState({
  //   isPXIYearly: false,
  //   isMentionYearly: false,
  //   isMentionYear: false,
  // })

  // general useEffect, empty callback
  useEffect(() => {
    window.scrollTo(0, 0)
    // setIsTrendsLoading(true)
    // setIsTrendingLoading(true)
    // setFilterSelection({})
    // setTrendsFilterSelection({})
    // setTagSelection({})
  }, [])

  // useEffect that listens for isTrendsCallActive
  useEffect(() => {
    if (!isTrendsCallActive && !_.isEmpty(trendsData)) {
      // setFIs(formatFIs(trendsData))
      return
    }

    if (isTrendsCallActive) {
      setTagSelection({
        ...tagSelection,
        time: timeValues.filter((time) => !isNaN(parseInt(time.charAt(0))) || time.charAt(0) === 'Q'),
      })

      // setFilterSelection({
      //   ...filterSelection,
      //   time: {
      //     year: filterSelection.time.year,
      //     quarter: filterSelection.time.quarter,
      //     month: [],
      //   },
      // })
      const sentData = {
        chosenFIs,
        filterSelection,
      }
      // get trending data
      updateTrendsData(sentData)
    }

    // if no trend chart data and no filters selected set trending to landing data
    // if (trendsData.length === 0) {
    //   console.log('No data found')
    // }
  }, [isTrendsCallActive])

  const updateTrendsData = (sentData) => {
    setIsTrendsLoading(true)
    setIsTrendingLoading(true)
    filtersAPI
      .getFilterSelectionsTest('/trends/trending', sentData)
      .then((trendingData) => {
        let filteredData = {}
        if (!_.isEmpty(trendingData)) {
          // console.log(trendingData)
          // let result = (({ customer_service, servMention, servPXI }) => ({ customer_service, servMention, servPXI }))(trendingData)
          for (const [key, value] of Object.entries(trendingData)) {
            // console.log(`${key}: ${value}`)
            let result = {}
            if (!_.isEmpty(sentData.filterSelection.customerService)) {
              result = _.pick(value, 'customer_service', 'servMention', 'servPXI')
            }
            if (!_.isEmpty(sentData.filterSelection.feesAndIncentives)) {
              result = _.pick(value, 'fees_and_incentives', 'feesMention', 'feesPXI')
            }
            if (!_.isEmpty(sentData.filterSelection.productOfferings)) {
              result = _.pick(value, 'product_offerings', 'prodMention', 'prodPXI')
            }
            if (!_.isEmpty(sentData.filterSelection.accessibility)) {
              result = _.pick(value, 'accessibility', 'accMention', 'accPXI')
            }
            if (!_.isEmpty(sentData.filterSelection.brandEquity)) {
              result = _.pick(value, 'brand_equity', 'brandMention', 'brandPXI')
            }
            if (!_.isEmpty(sentData.filterSelection.designAndFunctionality)) {
              result = _.pick(value, 'design_and_functionality', 'desMention', 'desPXI')
            }
            if (!_.isEmpty(result)) {
              // console.log(result)
              filteredData[key] = result
            }
          }
          // console.log(filteredData)
          if (_.isEmpty(filteredData)) {
            filteredData = trendingData
          }
          const filteredTrending = Object.keys(filteredData).map((key) => {
            return { _id: key, ...filteredData[key] }
          })
          // console.log(filteredTrending)
          // setTrendsFilterSelection(filterSelection)
          // console.log(formatTrendingColumns(filteredTrending))
          // setTrendsTrendingData(formatTrendingColumns(filteredTrending))
          setTrendsTrendingData(formatTrendingColumns2(filteredTrending, trendingData.avgs))
          setTrendingResp(filteredTrending)
        } else {
          // setTagSelection({})
          // setFilterSelection({})
          Swal.fire({
            text: 'Unfortunately these particular Trends Page filter selections yielded no results. Please select different filters and try again.',
          })
        }
      })
      .catch((error) => {
        console.warn(error)
      })
      .finally(() => {
        setIsTrendsLoading(false)
        setIsTrendingLoading(false)
        setIsTrendsCallActive(false)
      })
  }

  const checkUserInfo = async () => {
    const updatedChosenFIs = await getChosenFIs()
    if (!_.isEmpty(updatedChosenFIs)) {
      setChosenFIs(updatedChosenFIs)
      const sentData = {
        chosenFIs: updatedChosenFIs,
        filterSelection,
      }
      updateTrendsData(sentData)
    } else {
      setIsTrendsLoading(false)
      setIsTrendingLoading(false)
    }
  }

  useEffect(() => {
    checkUserInfo()
  }, [userInfo])

  return (
    <>
      <Content className="page-content">
        <div className="trends-pages-flex">
          <Loading isLoading={isTrendsLoading}>
            <CheckUserInfo>
              <PageHeader
                title="Trending Topics"
                description="This table identifies specific topics at the financial institution level that are mentioned significantly more or less than they are mentioned within competitor reviews. By understanding both the mention rate and the PXI vs. the average, you can derive greater insight into what is driving this spike or fall in activity as well as identify relative strengths and weaknesses."
              />
              {!_.isEmpty(trendsTrendingData) && <Trending isLoading={isTrendingLoading} trendingData={trendsTrendingData} />}
            </CheckUserInfo>
          </Loading>
          {/* <Space align="center">
            <Link className="breadcrumb" to={location.pathname} state={location.state}>
            <Link className="breadcrumb" to="/pxitrends" state={{ prevPages: [{ pageTitle: 'Trends', pagePath: '/trends' }] }}>
              <Button
                key="pxi"
                data-id="pxi"
                className="blue-button center-flex savedFIBtn trends-button"
                // onClick={() => {
                //   navigate('/pxitrends')
                // }}
              >
                PXI Trends
              </Button>
            </Link>
            <Link className="breadcrumb" to="/mentionratetrends" state={{ prevPages: [{ pageTitle: 'Trends', pagePath: '/trends' }] }}>
              <Button
                key="mentionrate"
                data-id="mentionrate"
                className="blue-button center-flex savedFIBtn trends-button"
                // onClick={() => {
                //   navigate('/mentionratetrends')
                // }}
              >
                Mention Rate Trends
              </Button>
            </Link>
          </Space> */}
        </div>
      </Content>
    </>
  )
}
