import { createContext, useState } from 'react'

import PropTypes from 'prop-types'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {
  /* DATA RESPONSE */
  const [resp, setResp] = useState([])

  /* SCORECARD PAGE STATE */
  const [scorecardData, setScorecardData] = useState([])
  const [scoregraphData, setScoregraphData] = useState([])
  const [landingScorecardData, setLandingScorecardData] = useState([])
  const [isScorecardLoading, setIsScorecardLoading] = useState(true)
  const [scorecardResp, setScorecardResp] = useState([])
  const [scorecardFilterSelection, setScorecardFilterSelection] = useState({})
  const [isScorecardCallActive, setIsScorecardCallActive] = useState(false)
  const [scorecardCsvData, setScorecardCsvData] = useState([])
  const [currentScorecardPage, setCurrentScorecardPage] = useState(1)
  const [totalScorecards, setTotalScorecards] = useState(0)

  /* TOPICS PAGE STATE */
  const [topicsData, setTopicsData] = useState([])
  const [isTopicsLoading, setIsTopicsLoading] = useState(true)
  const [topicsResponse, setTopicsResponse] = useState([])
  const [topicsFilterSelection, setTopicsFilterSelection] = useState({})
  const [isTopicsCallActive, setIsTopicsCallActive] = useState(false)
  const [currentTopicPage, setCurrentTopicPage] = useState(1)

  /* TRENDS PAGE STATE */
  const [trendsData, setTrendsData] = useState([])
  const [isTrendsLoading, setIsTrendsLoading] = useState(true)
  const [landingTrendsData, setLandingTrendsData] = useState([])
  const [isTrendsPageActive, setIsTrendsPageActive] = useState(false)
  const [trendsFilterSelection, setTrendsFilterSelection] = useState({})
  const [isTrendsCallActive, setIsTrendsCallActive] = useState(false)
  const [trendsState, setTrendsState] = useState({
    pxiData: [],
    pxiYearlyData: [],
    mentionData: [],
    mentionYearlyData: [],
    isMentionAgg: true,
    isPXIAgg: true,
    isMentionFI: false,
    isPXIFI: false,
    isMentionClassification: false,
    isPXIClassification: false,
    chartLabel: '',
    linePXIArr: [],
    lineMentionArr: [],
  })

  const { pxiData, pxiYearlyData, mentionData, mentionYearlyData, isMentionAgg, isPXIAgg, isMentionFI, isPXIFI, isMentionClassification, isPXIClassification, chartLabel, linePXIArr, lineMentionArr } =
    trendsState

  /* TRENDING SECTION STATE */
  const [trendsTrendingData, setTrendsTrendingData] = useState([])
  const [isTrendingLoading, setIsTrendingLoading] = useState(true)
  const [landingTrendingData, setLandingTrendingData] = useState([])
  const [trendingResp, setTrendingResp] = useState([])

  /* DATA TABLE PAGE STATE */
  const [datatableData, setDatatableData] = useState([])
  const [isDatatableLoading, setIsDatatableLoading] = useState(true)
  const [landingDatatableData, setLandingDatatableData] = useState([])
  const [isDatatableCallActive, setIsDatatableCallActive] = useState(false)
  const [datatableFilterSelection, setDatatableFilterSelection] = useState({})

  /* REVIEWS PAGE STATE */
  const [reviewsCsvData, setReviewCsvData] = useState([])
  const [reviewsData, setReviewsData] = useState([])
  const [isReviewsLoading, setIsReviewsLoading] = useState(true)
  const [landingReviewsData, setLandingReviewsData] = useState([])
  const [totalReviews, setTotalReviews] = useState(0)
  const [totalReviewsTextAndNoText, setTotalReviewsTextAndNoText] = useState(0)
  const [landingTotalReviews, setLandingTotalReviews] = useState(0)
  const [totalPerPage, setTotalPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [reviewsPXI, setReviewsPXI] = useState('')
  const [reviewsPXILanding, setReviewsPXILanding] = useState('')
  const [isReviewsPXILoading, setIsReviewsPXILoading] = useState(true)
  const [reviewsText, setReviewsText] = useState([])
  const [isTextAnd, setIsTextAnd] = useState(false)
  const [isUpdatePXI, setIsUpdatePXI] = useState(true)
  const [isReviewCardExpanded, setIsReviewCardExpanded] = useState(false)
  const [isReviewsCallActive, setIsReviewsCallActive] = useState(false)
  const [reviewsFilterSelection, setReviewsFilterSelection] = useState({})
  const [reviewsPXISentData, setReviewsPXISentData] = useState({
    time: Date.now(),
    action: 'reviews/pxi',
    filterSelection: {},
    fis: [],
    text: [],
    andor: false,
  })

  /* Forums PAGE STATE */
  const [isForumsCallActive, setIsForumsCallActive] = useState(true)
  const [forumsData, setForumsData] = useState([])
  const [totalForums, setTotalForums] = useState(0)

  /* DRIVERS PAGE STATE */
  const [driversData, setDriversData] = useState([])
  const [isDriversLoading, setIsDriversLoading] = useState(true)
  const [landingDriversData, setLandingDriversData] = useState([])
  const [landingDriversDirection, setLandingDriversDirection] = useState(true)
  const [isDirection, setIsDirection] = useState(true)
  const [driversResp, setDriversResp] = useState([])
  const [isDriversCallActive, setIsDriversCallActive] = useState(false)
  const [isFinancialSearchActive, setIsFinancialSearchActive] = useState(false)
  const [driversFilterSelection, setDriversFilterSelection] = useState({})
  const [driversSelection, setDriversSelection] = useState('')

  return (
    <DataContext.Provider
      value={{
        currentTopicPage,
        setCurrentTopicPage,
        resp,
        setResp,
        scorecardCsvData,
        setScorecardCsvData,
        scorecardData,
        setScorecardData,
        scoregraphData,
        setScoregraphData,
        currentScorecardPage,
        setCurrentScorecardPage,
        landingScorecardData,
        setLandingScorecardData,
        isScorecardLoading,
        setIsScorecardLoading,
        scorecardResp,
        setScorecardResp,
        scorecardFilterSelection,
        totalScorecards,
        setTotalScorecards,
        setScorecardFilterSelection,
        isScorecardCallActive,
        setIsScorecardCallActive,
        topicsData,
        setTopicsData,
        isTopicsLoading,
        setIsTopicsLoading,
        topicsResponse,
        setTopicsResponse,
        topicsFilterSelection,
        setTopicsFilterSelection,
        isTopicsCallActive,
        setIsTopicsCallActive,
        isTrendsCallActive,
        setIsTrendsCallActive,
        trendsFilterSelection,
        setTrendsFilterSelection,
        trendsData,
        setTrendsData,
        isTrendsLoading,
        setIsTrendsLoading,
        trendsState,
        setTrendsState,
        pxiData,
        pxiYearlyData,
        mentionData,
        mentionYearlyData,
        isMentionAgg,
        isPXIAgg,
        isMentionFI,
        isPXIFI,
        isMentionClassification,
        isPXIClassification,
        chartLabel,
        linePXIArr,
        lineMentionArr,
        landingTrendsData,
        setLandingTrendsData,
        trendsTrendingData,
        setTrendsTrendingData,
        isTrendingLoading,
        setIsTrendingLoading,
        landingTrendingData,
        setLandingTrendingData,
        trendingResp,
        setTrendingResp,
        isTrendsPageActive,
        setIsTrendsPageActive,
        datatableData,
        setDatatableData,
        isDatatableLoading,
        setIsDatatableLoading,
        landingDatatableData,
        setLandingDatatableData,
        isDatatableCallActive,
        setIsDatatableCallActive,
        datatableFilterSelection,
        setDatatableFilterSelection,
        reviewsCsvData,
        setReviewCsvData,
        reviewsData,
        setReviewsData,
        isReviewsLoading,
        setIsReviewsLoading,
        landingReviewsData,
        setLandingReviewsData,
        totalReviews,
        setTotalReviews,
        totalReviewsTextAndNoText,
        setTotalReviewsTextAndNoText,
        landingTotalReviews,
        setLandingTotalReviews,
        reviewsPXI,
        setReviewsPXI,
        reviewsPXILanding,
        setReviewsPXILanding,
        isReviewsPXILoading,
        setIsReviewsPXILoading,
        reviewsText,
        setReviewsText,
        isTextAnd,
        setIsTextAnd,
        reviewsPXISentData,
        setReviewsPXISentData,
        isUpdatePXI,
        setIsUpdatePXI,
        isReviewCardExpanded,
        setIsReviewCardExpanded,
        isReviewsCallActive,
        setIsReviewsCallActive,
        reviewsFilterSelection,
        setReviewsFilterSelection,
        totalPerPage,
        setTotalPerPage,
        currentPage,
        setCurrentPage,
        isForumsCallActive,
        setIsForumsCallActive,
        totalForums,
        setTotalForums,
        forumsData,
        setForumsData,
        driversData,
        setDriversData,
        isDriversLoading,
        setIsDriversLoading,
        isDriversCallActive,
        setIsDriversCallActive,
        isFinancialSearchActive,
        setIsFinancialSearchActive,
        driversFilterSelection,
        setDriversFilterSelection,
        landingDriversData,
        setLandingDriversData,
        landingDriversDirection,
        setLandingDriversDirection,
        isDirection,
        setIsDirection,
        driversResp,
        setDriversResp,
        driversSelection,
        setDriversSelection,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

DataProvider.propTypes = {
  children: PropTypes.any,
}
