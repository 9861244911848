import { API } from 'aws-amplify'

const apiName = process.env.REACT_APP_API_GATEWAY_NAME

export const reviewsAPI = {
  // putForumsNewPage: (page, total, filterSelection, fis, text, andor) =>
  //   API.put(apiName, '/forums/newPage', {
  //     body: {
  //       page,
  //       total,
  //       filterSelection,
  //       fis,
  //       text,
  //       andor,
  //     },
  //   }),
  putReviewsNewPage: (page, total, filterSelection, fis, text, andor, checkPage) =>
    API.put(apiName, '/reviews/newPage', {
      body: {
        page,
        total,
        filterSelection,
        fis,
        text,
        andor,
        checkPage,
      },
    }),
  putReviewsPXI: (filterSelection, fis, text, andor, selection) =>
    API.put(apiName, '/reviews/pxi', {
      body: {
        filterSelection,
        fis,
        text,
        andor,
        selection,
      },
    }),
  putReviews: (total, fis, filterSelection, text, andor, forum) =>
    API.put(apiName, '/reviews', {
      body: {
        total,
        fis,
        filterSelection,
        text,
        andor,
        forum,
      },
    }),
  putReviewsDrivers: (total, fis, filterSelection, text, andor, selection) =>
    API.put(apiName, '/reviews/drivers', {
      body: {
        total,
        fis,
        filterSelection,
        text,
        andor,
        selection,
      },
    }),
}
