import React, { useEffect, useState } from 'react'

import { Layout } from 'antd'

import './page-not-found.css'

const { Content } = Layout

export const PageNotFoundPage = () => {
  const [photo, setPhoto] = useState('./assets/splash-image.png')
  const getRandomPhoto = () => {
    const api = `https://api.unsplash.com/photos/random?query=nature&orientation=landscape&client_id=HQTv-GCCh7esm3jvRFIQFQ54TI5FJVCxsfK5L2e-7IA`
    fetch(api)
      .then((data) => data.json())
      .then((data) => {
        setPhoto(data.urls.raw)
      })
  }

  useEffect(() => {
    getRandomPhoto()
  }, [])

  const wrapper = {
    backgroundImage: `url('${photo})`,
  }

  return (
    <Content>
      <div style={wrapper} className="pnf-wrapper">
        <div className="pnf-div">
          <h1>404 Page Not Found</h1>
          <h3>Uh oh. Nothing to see here. Maybe you need a break before navigating away.</h3>
        </div>
      </div>
    </Content>
  )
}
