import { API } from 'aws-amplify'

const apiName = process.env.REACT_APP_API_GATEWAY_NAME
const myInit = {
  response: true, // OPTIONAL
}

export const filtersAPI = {
  getFiltersDataAPI: (filterType) => API.get(apiName, `/filters/${filterType}`, myInit),
  getFIs: () => API.get(apiName, '/filters/fi', myInit),
  getFilterSelections: (pathname, filterSelection) => {
    let body = {
      filterSelection,
    }
    if (pathname === '/data' || pathname === '/drivers') {
      body = filterSelection
    }
    return API.put(apiName, `${pathname}/filterSelection`, {
      body: body,
    })
  },
  getFilterSelectionsTest: (pathname, filterSelection) => {
    let body = {
      filterSelection,
    }
    if (pathname === '/data' || pathname === '/drivers') {
      body = filterSelection
    }
    return API.put(apiName, `${pathname}/filterSelectionTest`, {
      body: body,
    })
  },
}
