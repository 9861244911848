export const dataSet = [
  'mBank',
  'Zoona',
  'Woodforest National Bank',
  'Wells Fargo',
  'Wasatch Peaks Credit Union',
  'Varo Bank',
  'United Federal Credit Union',
  'Union Bank',
  'Uala',
  'USAA Bank',
  'US Bank',
  'Truliant Federal Credit Union',
  'Truist Bank',
  'Tangerine',
  'TIAA Bank',
  'TD Bank',
  'TD Ameritrade',
  'Synchrony Bank',
  'Suncoast Credit Union',
  'SunTrust Bank',
  'Summit Credit Union',
  'Stripe',
  'State Farm Bank',
  'Square',
  'Spectrum Credit Union',
  'SoFi',
  'Smarty Pig',
  'Simple',
  'Self Help Credit Union',
  'Santander',
  'Regions Bank',
  'Radius Bank',
  'REGO Payments',
  'RBC Bank',
  'Porter Capital Corporation',
  'Pinnacle Bank',
  'Piedmont Advantage Credit Union',
  'Pentagon Federal Credit Union',
  'PNC Bank',
  'Nova Credit Union',
  'Northwest Federal Credit Union',
  'Navy Federal Credit Union',
  'NHcash',
  "NC State Employees' Credit Union",
  'Monobank',
  'Merrick Bank',
  'Lending Club Mobile',
  'Kinecta Federal Credit Union',
  'KeyBank',
  'Hyperion Bank',
  'Huntington Bank',
  'Hiver',
  'Heritage Bank',
  'Hello Bank',
  'HSBC Bank',
  'Green Dot Bank',
  'Golden 1 Credit Union',
  'GiantBank.com',
  'Generations Credit Union',
  'First Technology Federal Credit Union',
  'First National Bank of Omaha',
  'First Citizens Bank',
  'Fifth Third Bank',
  'Fidelity Bank',
  'Elyps',
  'EQ Bank',
  'E*Trade Bank',
  'Discover Bank',
  'Digital Federal Credit Union',
  'Credit Sesame',
  'Coastal Federal Credit Union',
  'Citizens Bank',
  'CitiBank',
  'Chime',
  'Chase',
  'Charlotte Metro Credit Union',
  'Charles Schwab Bank',
  'Carbon',
  'Capital One Bank',
  'CIT Bank',
  'Broxel',
  'Boeing Employees Credit Union',
  'Beacon Credit Union Incorporated',
  'Bank of America',
  'Bank Zero',
  'Bank Of The West',
  'Bank Dora',
  'BBVA Bank',
  'BB&T',
  'Axos Bank',
  'American Express Bluebird',
  'Ally Bank',
  'Alliant Credit Union',
  'Allegacy Federal Credit Union',
  'Affinity Plus Federal Credit Union',
]

export const demoDataSet = [
  'BECU (Boeing Employees Credit Union)',
  'HSBC',
  'KeyBank',
  'Fifth Third Bank (OH)',
  'Barclays',
  'Tangerine',
  'Digital Federal Credit Union (DCU)',
  'Merrick Bank',
  'Synchrony Bank',
  'Affinity Federal Credit Union',
  'PNC Bank',
  'Bank of America',
  'USAA Bank',
  'Citi',
  'Regions Bank',
  'Chase Bank',
  'Capital One',
  'Credit Sesame',
  'Summit Credit Union (WI)',
  'Union Bank (San Francisco, CA)',
  'Santander Bank, N.A',
  'Woodforest National Bank',
  'Bank Of The West',
  'Wells Fargo Bank',
  "State Employees' Credit Union (NC)",
  'Navy Federal Credit Union',
  'Varo Bank',
  'Ally Bank',
  'Huntington National Bank',
  'SoFi',
  'America First Credit Union',
  'Alliant Credit Union',
  'TD Bank',
  'US Bank',
  'Truist',
  'TIAA Bank',
  'Green Dot Bank',
  'Pentagon Federal Credit Union',
  'Golden 1 Credit Union',
]
