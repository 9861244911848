import { useContext } from 'react'
import Highlighter from 'react-highlight-words'

import { blue } from '@ant-design/colors'
import { Button, Card, Col, Grid, Row } from 'antd'
import PropTypes from 'prop-types'

import { DataContext } from '../../providers'

import './forums-card.css'

const { useBreakpoint } = Grid

export const ForumsCard = ({ review_text, id, onMoreClick, index, title }) => {
  const { xs } = useBreakpoint()
  const { reviewsText } = useContext(DataContext)

  const formatHighlightedReviewsText = (reviewsText) => {
    let formattedArr = []
    reviewsText.map((text) => {
      if (text.includes('"')) {
        formattedArr.push(text.replace(/['"]+/g, ''))
      } else {
        const splitText = text.split(' ')
        splitText.map((text) => formattedArr.push(text))
      }
    })
    return formattedArr
  }

  return (
    <Card className="review-card">
      {!xs ? (
        <>
          <Row className="review-row">
            <Col span={8} className="review-card-col">
              <h5>
                {' '}
                <Highlighter highlightClassName="highlighter" searchWords={formatHighlightedReviewsText(reviewsText)} autoEscape={true} textToHighlight={title} />
              </h5>
            </Col>
          </Row>
          <Row className="review-row">
            <Col span={8} className="review-card-col">
              <p>
                <Button
                  onClick={(e) => {
                    onMoreClick(e)
                  }}
                  style={{ color: blue[4] }}
                  className="more-btn"
                  data-id={index}
                >
                  {id === 'expanded' ? '...less' : '...more'}
                </Button>
              </p>
            </Col>
          </Row>
          <Row id={id} className="hide-row">
            <Col span={24} className="review-card-col">
              <p>
                {' '}
                <Highlighter highlightClassName="highlighter" searchWords={formatHighlightedReviewsText(reviewsText)} autoEscape={true} textToHighlight={review_text} />
              </p>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <div className="review-row-xs">
            <h5 style={{ marginTop: '20px' }}>{title}</h5>
            <div className="date-username-row">
              <div style={{ top: '-7px', position: 'relative', width: '240px' }}>
                <p className="username">
                  <Button
                    onClick={(e) => {
                      onMoreClick(e)
                    }}
                    style={{ color: blue[4] }}
                    className="more-btn"
                  >
                    {id === 'expanded' ? '...less' : '...more'}
                  </Button>
                </p>
              </div>
            </div>
            <Row id={id} className="hide-row">
              <Col span={24}>
                <p>{review_text}</p>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Card>
  )
}

ForumsCard.propTypes = {
  review_text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onMoreClick: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
