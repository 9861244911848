import { Tag } from 'antd'
import PropTypes from 'prop-types'

export const ReviewTag = ({ name, onClose }) => {
  const style = {
    border: '0.5px solid rgba(214, 216, 224, 0.1)',
    borderRadius: '5px',
    padding: '1px 5px',
    margin: '0 0 0 5px',
    color: '#d6d8e0',
    background: '#232d37',
  }

  return (
    <Tag closable style={style} onClose={onClose} id={name}>
      <span>{name}</span>
    </Tag>
  )
}

ReviewTag.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}
