import { Spin } from 'antd'
import PropTypes from 'prop-types'

import './loading.css'

export const Loading = (props) => {
  const { isLoading, children } = props

  return (
    <Spin size="large" spinning={isLoading} tip="Loading...">
      {children}
    </Spin>
  )
}

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}
