import { useContext } from 'react'
import Highlighter from 'react-highlight-words'

// import { blue } from '@ant-design/colors'
import { Button, Card, Col, Grid, Rate, Row } from 'antd'
import PropTypes from 'prop-types'

import { DataContext } from '../../providers'

import './review-card.css'

const { useBreakpoint } = Grid

export const ReviewCard = ({ fi, rating, username, review_date, review_text, id, onMoreClick, index, title }) => {
  const { xs } = useBreakpoint()
  const { reviewsText } = useContext(DataContext)

  const formatHighlightedReviewsText = (reviewsText) => {
    let formattedArr = []
    reviewsText.map((text) => {
      if (text.includes('"')) {
        formattedArr.push(text.replace(/['"]+/g, ''))
      } else {
        const splitText = text.split(' ')
        splitText.map((text) => formattedArr.push(text))
      }
    })
    return formattedArr
  }

  return (
    <Card className="review-card">
      {!xs ? (
        <>
          <Row className="review-row">
            <Col span={8} className="review-card-col">
              <h4
              //  style={{ color: 'var(--main-font)' }}
              >
                {' '}
                <Highlighter highlightClassName="highlighter" searchWords={formatHighlightedReviewsText(reviewsText)} autoEscape={true} textToHighlight={title} />
              </h4>
            </Col>
            <Col span={8} className="review-card-col">
              <h4
              //  style={{ color: grey[5] }}
              >
                {fi}
              </h4>
            </Col>
            <Col span={8} className="review-card-col">
              <Rate disabled value={parseInt(rating)} style={{ color: '#D6D8E0' }} className="review-rate" />
            </Col>
          </Row>
          <Row className="review-row">
            <Col span={8} className="review-card-col">
              <p
              //  style={{ color: grey[5] }}
              >
                {`${username} says `}
                <Button
                  onClick={(e) => {
                    onMoreClick(e)
                  }}
                  // style={{ color: blue[4] }}
                  className="more-btn"
                  data-id={index}
                >
                  {id === 'expanded' ? '...less' : '...more'}
                </Button>
              </p>
            </Col>
            <Col span={8} className="review-card-col">
              <p
              //  style={{ color: grey[5] }}
              >
                {review_date}
              </p>
            </Col>
          </Row>
          <Row id={id} data-id={`${index}-${id}`} className="hide-row">
            <Col span={24} className="review-card-col">
              <p
              //  style={{ color: grey[5] }}
              >
                {' '}
                <Highlighter highlightClassName="highlighter" searchWords={formatHighlightedReviewsText(reviewsText)} autoEscape={true} textToHighlight={review_text} />
              </p>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <div className="review-row-xs">
            <h4
            // style={{ color: grey[5] }}
            >
              {fi}
            </h4>
            <Rate disabled value={parseInt(rating)} style={{ color: '#D6D8E0' }} className="review-rate" />
            <h4
              style={{
                // color: 'var(--main-font)',
                marginTop: '20px',
              }}
            >
              {title}
            </h4>
            <div className="date-username-row">
              <div style={{ top: '-7px', position: 'relative', width: '240px' }}>
                <p
                  // style={{ color: grey[5] }}
                  className="username"
                >
                  {`${username} says `}
                  <Button
                    onClick={(e) => {
                      onMoreClick(e)
                    }}
                    // style={{ color: blue[4] }}
                    className="more-btn"
                    data-id={index}
                  >
                    {id === 'expanded' ? '...less' : '...more'}
                  </Button>
                </p>
              </div>
              <div>
                <p
                // style={{ color: grey[5] }}
                >
                  {review_date}
                </p>
              </div>
            </div>
            <Row id={id} data-id={`${index}-${id}`} className="hide-row">
              <Col span={24}>
                <p
                // style={{ color: grey[5] }}
                >
                  {review_text}
                </p>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Card>
  )
}

ReviewCard.propTypes = {
  fi: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  review_date: PropTypes.string.isRequired,
  review_text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onMoreClick: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
