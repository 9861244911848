import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

import { Card, Grid, Layout } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Swal from 'sweetalert2'

import { CheckUserInfo, Loading, PageHeader } from '../../components'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import { filtersAPI } from '../../api'

import arrowDown from '../../assets/arrow_down.svg'
import arrowUp from '../../assets/arrow_up.svg'
import { camelCase, findHeightChart, findSubTopicChildren, topicDriversData2 } from '../../common/utils/helpers'
import './drivers-page.css'

const { useBreakpoint } = Grid
const { Content } = Layout

const colors = ['rgba(100, 95, 227, .5)', 'rgba(87, 222, 56, .5)', 'rgba(244, 127, 127, .5)']

export const DriversPage = () => {
  const navigate = useNavigate()
  const { xs, sm, lg } = useBreakpoint()
  const {
    driversData,
    setDriversData,
    setDriversSelection,
    // landingDriversData,
    // setLandingDriversData,
    // setLandingDriversDirection,
    isDriversLoading,
    setIsDriversLoading,
    // isDirection,
    // setIsDirection,
    driversResp,
    setDriversResp,
    setDriversFilterSelection,
    isDriversCallActive,
    setIsDriversCallActive,
    setIsReviewsCallActive,
  } = useContext(DataContext)
  const { setIsReviewsDrivers, setTagSelection, topics, setTopicFilterValues, chosenFIs, setChosenFIs, filterSelection, setFilterSelection, tagSelection, selectedFilterValue, handleCheckedValues } =
    useContext(FiltersContext)
  const { userInfo, getChosenFIs } = useContext(ThemeContext)
  const [height, setHeight] = useState(findHeightChart())
  const [viewOptions, setViewOptions] = useState([{ value: 'Competitive Set', label: 'Competitive Set' }])
  const [selectedView, setSelectedView] = useState({})
  const [topicBarClicked, setTopicBarClicked] = useState(false)

  useEffect(() => {
    if (topicBarClicked) {
      if (!selectedView.value || selectedView.value === 'Competitive Set') {
        navigate('/reviews', {
          state: {
            prevPages: [
              { pageTitle: 'Home', pagePath: '/' },
              { pageTitle: 'Drivers', pagePath: '/drivers' },
            ],
          },
        })
      } else {
        let encodedFi = encodeURIComponent(selectedView.value)
        navigate(`/reviews?fi=${encodedFi}`, {
          state: {
            prevPages: [
              { pageTitle: 'Home', pagePath: '/' },
              { pageTitle: 'Drivers', pagePath: '/drivers' },
            ],
          },
        })
      }
    }
  }, [topicBarClicked])

  const handleBarClick = (data) => {
    // TODO: update topic filter for reviews page
    const { name } = data
    setIsReviewsDrivers(true)
    if (name === 'Starting PXI' || name === 'Ending PXI') {
      if (selectedView.value && selectedView.value !== 'Competitive Set') {
        let encodedFi = encodeURIComponent(selectedView.value)
        navigate(`/reviews?fi=${encodedFi}`, {
          state: {
            prevPages: [
              { pageTitle: 'Home', pagePath: '/' },
              { pageTitle: 'Drivers', pagePath: '/drivers' },
            ],
          },
        })
      } else {
        navigate('/reviews', {
          state: {
            prevPages: [
              { pageTitle: 'Home', pagePath: '/' },
              { pageTitle: 'Drivers', pagePath: '/drivers' },
            ],
          },
        })
      }
    } else {
      setDriversSelection(name.toLowerCase())
      const filterType = camelCase(name)
      let children = findSubTopicChildren(topics, name)
      const childrenLabels = children.map((item) => item.label)
      const unselectedTopics = topics.filter((item) => item.label !== name && item.label !== 'Customer' && item.label !== 'Product')
      const unselectedObj = {}
      unselectedTopics.forEach((item) => {
        unselectedObj[camelCase(item.label)] = []
      })
      setFilterSelection({ ...filterSelection, ...unselectedObj, [`${filterType}`]: children })
      setTagSelection({ ...tagSelection, ...unselectedObj, [`${filterType}`]: children })
      handleCheckedValues(childrenLabels, filterType)
      setTopicBarClicked(true)
    }
    setIsReviewsCallActive(true)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // setFilterSelection({})
    setTopicFilterValues({
      customerServiceValues: [],
      feesAndIncentivesValues: [],
      productOfferingsValues: [],
      brandEquityValues: [],
      accessibilityValues: [],
      designAndFunctionalityValues: [],
    })
    setTagSelection({
      ...tagSelection,
      customerService: [],
      feesAndIncentives: [],
      productOfferings: [],
      accessibility: [],
      brandEquity: [],
      designAndFunctionality: [],
    })
    function resizeHandler() {
      setHeight(findHeightChart())
    }
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const checkUserInfo = async () => {
    const updatedChosenFIs = await getChosenFIs()
    if (!_.isEmpty(updatedChosenFIs)) {
      setChosenFIs(updatedChosenFIs)
      const formattedFIs = updatedChosenFIs.map((fi) => ({
        value: fi,
        label: fi,
      }))
      setSelectedView(formattedFIs)
      setViewOptions([{ value: 'Competitive Set', label: 'Competitive Set' }, ...formattedFIs])
      const sentData = {
        // filterSelection: selectedFilterValue,
        filterSelection,
        driversFilterSelection: {
          ...filterSelection,
          customerService: [],
          feesAndIncentives: [],
          productOfferings: [],
          accessibility: [],
          brandEquity: [],
          designAndFunctionality: [],
        },
        check: 'finalsearch and filters',
        chosenFIs: updatedChosenFIs,
      }
      updateDriversData(sentData)
    } else {
      setIsDriversLoading(false)
    }
  }

  useEffect(() => {
    checkUserInfo()
  }, [userInfo])

  useEffect(() => {
    if (!isDriversCallActive && !_.isEmpty(driversData)) {
      return
    }
    // if there are selected filters clear out any topics and make a db call
    if (isDriversCallActive) {
      setIsDriversLoading(true)
      setTagSelection({
        ...tagSelection,
        customerService: [],
        feesAndIncentives: [],
        productOfferings: [],
        accessibility: [],
        brandEquity: [],
        designAndFunctionality: [],
      })
      if (!_.isEmpty(chosenFIs)) {
        let sentData = {
          filterSelection,
          driversFilterSelection: {
            ...filterSelection,
            customerService: [],
            feesAndIncentives: [],
            productOfferings: [],
            accessibility: [],
            brandEquity: [],
            designAndFunctionality: [],
          },
          check: 'filters',
          chosenFIs,
        }
        if (!_.isEmpty(selectedFilterValue)) {
          sentData = { ...sentData, filterSelection: selectedFilterValue }
        }
        updateDriversData(sentData)
      }
    }
    //sets landing data
    // if (_.isEmpty(landingDriversData)) {
    //   // const { finalData, direction } = formatDriversData(landing)
    //   // setLandingDriversData(finalData)
    //   // setLandingDriversDirection(direction)

    //   //if no selected filters and no data use landing data
    //   if (_.isEmpty(driversData) && _.isEmpty(filterSelection)) {
    //     // const { finalData } = formatDriversData(landing)
    //     const { finalData } = topicDriversData(topicdatas)
    //     console.log(finalData)
    //     // setIsDirection(direction)
    //     setIsDriversLoading(false)
    //     setDriversResp(landing)
    //     setDriversData(finalData)
    //     // setFIs(formatFIs(landing))
    //   }
    // }
  }, [isDriversCallActive])

  const updateDriversData = (sentData) => {
    filtersAPI
      .getFilterSelections('/drivers', sentData)
      .then((response) => {
        if (!_.isEmpty(response)) {
          setDriversResp(response)
          const compSet = response.find((el) => el._id === 'Competitive Set')
          let withoutUserFI = [compSet]
          if (_.isEmpty(compSet)) {
            withoutUserFI = [...response].slice(0, response.length - 1)
          }
          const { finalData } = topicDriversData2(withoutUserFI)
          setDriversData(finalData)
          // setIsDirection(direction)
          setIsDriversLoading(false)
          // setFIs(formatFIs(response))
          setIsDriversCallActive(false)
        } else if (_.isEmpty(response)) {
          Swal.fire({
            text: 'Unfortunately these particular Drivers filter selections yielded no results. Please select different filters and try again.',
          })
          setIsDriversLoading(false)
          setIsDriversCallActive(false)
        }
        setDriversFilterSelection({ ...filterSelection, customerService: [], feesAndIncentives: [], productOfferings: [], accessibility: [], brandEquity: [], designAndFunctionality: [] })
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  const handleViewOptionOnChange = (selectedOption) => {
    let viewDriversData
    setSelectedView(selectedOption)
    if (selectedOption.value === 'Competitive Set') {
      const compSet = driversResp.find((el) => el._id === 'Competitive Set')
      let withoutUserFI = [compSet]
      if (_.isEmpty(compSet)) {
        withoutUserFI = [...driversResp].slice(0, driversResp.length - 1)
      }
      viewDriversData = topicDriversData2(withoutUserFI)
    } else {
      viewDriversData = topicDriversData2([driversResp.find((e) => e._id === selectedOption.value)])
    }
    const { finalData } = viewDriversData
    if (!_.isEmpty(finalData)) {
      setDriversData(finalData)
    }
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Card
          bordered={false}
          style={{ borderRadius: '10px' }}
          headStyle={{
            color: '#d6d8e0',
            backgroundColor: '#606f7d',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            paddingLeft: '20px',
          }}
          bodyStyle={{
            color: '#d6d8e0',
            backgroundColor: '#606f7d',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            padding: '6px 0 20px 20px',
          }}
          title={<span>{payload[0].payload.name}</span>}
        >
          <span>{`PXI: ${payload[0].payload.PXI}`}</span>
          {payload[0].payload.PXI > 0 ? (
            <span>
              <img src={arrowUp} style={{ padding: '0 10px', height: '18px' }} />
            </span>
          ) : (
            <span>
              <img src={arrowDown} style={{ padding: '0 10px', height: '18px' }} />
            </span>
          )}
        </Card>
      )
    }

    return null
  }

  CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
  }

  return (
    <>
      <Content className="page-content">
        <Loading isLoading={isDriversLoading}>
          <CheckUserInfo>
            <PageHeader
              title="PXI Driver Analysis"
              description="Starting PXI is the calculated PXI for reviews where no topic is present. Ending PXI includes all reviews. PXI impact takes into account the percent of positive and negative feedback in addition to the volume of that feedback at the topic level. This gives us the ability to understand what is driving the ending PXI and what changes could have the largest overall impact."
            />
            {!_.isEmpty(driversData) && (
              <div>
                <h3 className="drivers-fi-selector-label">Select Drivers View:</h3>
                <Select
                  className="fi-selector"
                  classNamePrefix="select"
                  options={viewOptions}
                  defaultValue={viewOptions[0]}
                  onChange={handleViewOptionOnChange}
                  closeMenuOnSelect={true}
                  styles={{
                    container: (provided) => ({ ...provided, width: '33.3%', minWidth: '220px', maxWidth: '500px' }),
                    dropdownIndicator: (provided) => ({ ...provided, color: '#464f57' }),
                    indicatorSeparator: (provided) => ({ ...provided, backgroundColor: '#464f57' }),
                    menuPortal: (base, state) => ({ ...base, zIndex: 1, color: '#d6d8e0 !important', backgroundColor: state.isActive ? 'rgba(96, 111, 125, 0.27)' : 'transparent' }),
                    control: (base) => ({ ...base, border: '0.5px solid rgba(214, 216, 224, 0.1) !important', margin: '0px 0 20px 0' }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(96, 111, 125, 0.27)',
                      primary: 'rgba(96, 111, 125, 0.27)',
                      neutral0: '#232d37',
                      primary50: '#232d37',
                      primary75: '#232d37',
                      neutral20: '#d6d8e0',
                      neutral50: '#d6d8e0',
                      neutral80: '#d6d8e0',
                      neutral5: '#d6d8e0',
                      neutral30: '#d6d8e0',
                      neutral60: '#d6d8e0',
                      neutral90: '#d6d8e0',
                      neutral40: '#d6d8e0',
                      neutral70: '#d6d8e0',
                      neutral10: '#171E26',
                      dangerLight: '#f47f7f',
                    },
                  })}
                />
                <Card className="page-card align-items space-between" bordered={false}>
                  <ResponsiveContainer height={height} width="100%">
                    <BarChart
                      height={height}
                      data={driversData}
                      margin={{
                        top: 20,
                        right: 5,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid vertical={false} strokeDasharray="1 1" stroke="var(--cartesian-gridline)" />
                      <XAxis dataKey="name" style={{ fontSize: xs ? '0.5em' : sm && !lg ? '0.6em' : '.9em' }} height={40} stroke="var(--gridlines)" />
                      <YAxis padding={{ bottom: 40 }} type="number" tickCount={11} style={{ fontWeight: 'bold' }} stroke="var(--gridlines)" domain={['auto', 'dataMin - 5']} allowDataOverflow={true} />
                      <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
                      <Bar dataKey="clearBar" stackId="a" fill="transparent" />
                      <Bar dataKey="PXI" stackId="a" legendType="none" onClick={handleBarClick}>
                        <LabelList style={{ fontSize: '1.3em', fill: 'var(--gridlines)' }} dataKey="PXI" position="top" />
                        {driversData.map((value) => {
                          if (value.name === 'Starting PXI' || value.name === 'Ending PXI') {
                            return <Cell key={value.name} fill={colors[0]} radius={5} cursor="pointer" />
                          } else if (value.PXI > 0) {
                            return <Cell key={value.name} fill={colors[1]} radius={5} cursor="pointer" />
                          } else {
                            return <Cell key={value.name} fill={colors[2]} radius={5} cursor="pointer" />
                          }
                        })}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Card>
              </div>
            )}
          </CheckUserInfo>
        </Loading>
      </Content>
    </>
  )
}
