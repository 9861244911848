import './footer.css'

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <div className="flex justify-center footer">
      <p className="footer-text">
        <span>
          <a className="footer-text" href="/">
            &copy; {currentYear} Hearful
          </a>
        </span>{' '}
      </p>
    </div>
  )
}
