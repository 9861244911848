import { API } from 'aws-amplify'

const apiName = process.env.REACT_APP_API_GATEWAY_NAME
const myInit = {
  response: true, // OPTIONAL
}

export const dataTableAPI = {
  //can reuse this call to get the source columns
  getDataTable: () => API.get(apiName, '/datatable', myInit),
  getDataTableCached: () => API.get(apiName, '/datatable/cached', myInit),
}
