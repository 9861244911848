/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

import { EditOutlined } from '@ant-design/icons'
import { Button, Card, Form, Grid, Input, Layout, Modal, Spin } from 'antd'
import { Auth } from 'aws-amplify'
import _ from 'lodash'
import Swal from 'sweetalert2'

import { FiltersContext, ThemeContext } from '../../providers'

import { usersAPI } from '../../api'

import bgpng from '../../assets/bg_design_32124settings.jpeg'
import Gear from '../../assets/gear.svg'
import './settings-page.css'

const { useBreakpoint } = Grid

const { Content } = Layout

export const SettingsPage = () => {
  const { setChosenFIs, FIs, value, setValue } = useContext(FiltersContext)
  const { userInfo, setUserInfo, getChosenFIs } = useContext(ThemeContext)
  const [userFIValue, setUserFIValue] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { xs, sm, md } = useBreakpoint()
  const [isLoading, setIsLoading] = useState(true)
  const [form] = Form.useForm()
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })
  const [passwordSuccessMessage, setPasswordSuccessMessage] = useState({
    message: '',
    color: '',
  })
  const [savedFISets, setSavedFISets] = useState([])
  const competitiveSetName = process.env.REACT_APP_COMPETITIVE_SET_NAME

  const { currentPassword, newPassword, confirmNewPassword } = passwordForm

  /* password form styling */
  const formItemLayout = {
    layout: 'vertical',
    labelCol: {
      span: 6,
      style: {
        padding: '0px',
      },
    },
    wrapperCol: {
      span: 8,
    },
  }
  const buttonItemLayout = {
    wrapperCol: {
      span: 14,
    },
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleUserFIOnChange = (selectedOptions) => {
    setUserFIValue(selectedOptions)
    // console.log(selectedOptions.value)
  }

  const handleFIOnChange = (selectedOptions) => {
    setValue(selectedOptions)
  }

  const handleOk = async () => {
    setIsModalVisible(false)
    const compFISetToSave = { name: competitiveSetName, selectedFIs: value }

    try {
      const userFI = userFIValue.value
      await usersAPI.putUserFISets(compFISetToSave, userInfo.email)
      await usersAPI.putUserFI(userFI, userInfo.email)
      await usersAPI.putUserCompFISet(value, userInfo.email)
      setUserInfo({ ...userInfo, competitiveSet: value, userFI: userFI })
      const compSetFIs = value.map((fi) => fi.value)
      let updatedChosenFIs = [...compSetFIs]

      if (!_.isEmpty(userFI)) {
        let index = updatedChosenFIs.findIndex((item) => item.name === userFI)
        if (index === -1) {
          updatedChosenFIs.push(userFI)
        } else {
          // If found, log a message indicating that the userFI already exists
          console.log(`userFI ${userFI} already exists`)
        }
      }
      // console.log(updatedChosenFIs)
      setChosenFIs(updatedChosenFIs)

      const pEl1 = document.createElement('p')
      pEl1.innerHTML = 'These can be changed in the settings page'

      Swal.fire('Set of competitive financial institutions has been updated/saved!', pEl1, 'success')

      const savedCompFISetIndex = savedFISets.findIndex((obj) => obj.name == competitiveSetName)
      let newSavedFISets = [...savedFISets]
      if (!_.isEmpty(newSavedFISets[savedCompFISetIndex])) {
        // Update existing comp set
        newSavedFISets[savedCompFISetIndex].selectedFIs = value
        setSavedFISets(newSavedFISets)
      } else {
        // Add new comp set
        const newCompSet = [...savedFISets, compFISetToSave]
        setSavedFISets(newCompSet)
      }
    } catch (err) {
      console.error(err)
      const pEl2 = document.createElement('p')
      pEl2.innerHTML = 'Please try again or contact <a href="mailto:support@hearfulhub.com">support@hearfulhub.com</a>.'

      Swal.fire('Something went wrong.', pEl2, 'error')
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false)
    }
    checkUserInfo()
  }, [userInfo])

  const checkUserInfo = async () => {
    if (!_.isEmpty(userInfo)) {
      const { savedFISets, competitiveSet, userFI } = userInfo
      if (_.isEmpty(userFI) || _.isEmpty(competitiveSet)) {
        const pEl3 = document.createElement('p')
        pEl3.innerHTML = 'To get started, set up your customized set of financial institutions for analysis'
        Swal.fire({
          title: 'Welcome to Hearful!',
          html: pEl3,
          confirmButtonText: 'Competitive Set',
        }).then((result) => {
          if (result.isConfirmed && !isModalVisible) {
            setIsModalVisible(true)
          }
        })
      }
      if (!_.isEmpty(savedFISets)) {
        setSavedFISets(savedFISets)
      }

      if (!_.isEmpty(userFI)) {
        setUserFIValue({ label: userFI, value: userFI })
      }

      if (!_.isEmpty(competitiveSet)) {
        setValue(competitiveSet)
        const compSetFIs = competitiveSet.map((fi) => fi.value)
        let updatedChosenFIs = [...compSetFIs]

        if (!_.isEmpty(userFI)) {
          let index = updatedChosenFIs.findIndex((item) => item.name === userFI)
          if (index === -1) {
            updatedChosenFIs.push(userFI)
          } else {
            // If found, log a message indicating that the userFI already exists
            console.log(`userFI ${userFI} already exists`)
          }
        }
        // console.log(updatedChosenFIs)
        setChosenFIs(updatedChosenFIs)
      }
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    })
  }

  const onPasswordChange = () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      return setPasswordSuccessMessage({
        message: 'All fields are required',
      })
    } else if (newPassword !== confirmNewPassword) {
      return setPasswordSuccessMessage({
        message: 'Passwords do not match',
      })
    } else if (/\s/.test(newPassword)) {
      return setPasswordSuccessMessage({
        message: 'Password cannot contains spaces',
      })
    } else if (newPassword.length < 8) {
      return setPasswordSuccessMessage({
        message: 'Password must be 8 or more characters',
      })
    } else if (!(/[A-Z]/.test(newPassword) && /[a-z]/.test(newPassword) && /[0-9]/.test(newPassword) && /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(newPassword))) {
      return setPasswordSuccessMessage({
        message: 'Password must contain both upper and lower cases as well as at least one number and one special character',
      })
    } else {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, currentPassword, newPassword)
        })
        .then(() => {
          setPasswordSuccessMessage({
            message: 'Successfully updated your password!',
            color: 'green',
          })
        })
        .catch((err) => {
          console.warn(err)
          setPasswordSuccessMessage({
            message: `${err.message}`,
            color: 'red',
          })
        })
    }

    setPasswordForm({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    })
  }

  return (
    <>
      <Content className="settings-page-content">
        <Card className="page-card" id="settings-card" bordered={false}>
          {isLoading ? (
            <div className="flex justify-center">
              <Spin tip="Loading..." />
            </div>
          ) : (
            <>
              <div className="settings-background-container">
                <img className="settings-background" src={bgpng} alt="home" />
                <div className="icon-flex-container">
                  <div className="icon-container">
                    <img className="settings-lg" src={Gear} alt="large settings icon" />
                  </div>
                </div>
              </div>
              <div className={xs ? 'show options-flex' : sm && !md ? 'show options-flex' : 'options-flex'}>
                <div className="set-fi-form">
                  <h3 onClick={showModal}>Click to View and Update</h3>
                  <Button onClick={showModal}>Competitive Set</Button>{' '}
                </div>
                <div className="password-form">
                  <h3>
                    Change Password <EditOutlined />
                  </h3>
                  <Form {...formItemLayout} form={form}>
                    <Form.Item>
                      <Input className="password-input" type="password" name="currentPassword" placeholder="Current Password" value={currentPassword} onChange={handleInputChange} />
                    </Form.Item>
                    <Form.Item>
                      <Input className="password-input" type="password" name="newPassword" placeholder="New Password" value={newPassword} onChange={handleInputChange} />
                    </Form.Item>
                    <Form.Item>
                      <Input className="password-input" type="password" name="confirmNewPassword" placeholder="Confirm New Password" value={confirmNewPassword} onChange={handleInputChange} />
                    </Form.Item>
                    <Form.Item {...buttonItemLayout}>
                      <Button type="submit" onClick={onPasswordChange}>
                        Change Password
                      </Button>
                      {!passwordSuccessMessage.message !== '' ? <p className="password-message">{passwordSuccessMessage.message}</p> : null}
                    </Form.Item>
                  </Form>
                </div>
              </div>
              <Modal
                className="fi-modal"
                title="Competitive FI Set"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Submit"
                okButtonProps={{ style: { backgroundColor: '#12a969', border: 'none', color: 'black' } }}
                cancelButtonProps={{ style: { backgroundColor: '#f47f7f', border: 'none', color: 'black' } }}
              >
                <div>
                  <p className="fi-modal-header">
                    Welcome and thank you for using Hearful&rsquo;s Financial Services platform. Before you get started, please select the financial institution representing you or your organization.
                  </p>
                  {!_.isEmpty(FIs) && (
                    <Select
                      options={FIs}
                      className="fi-selector"
                      classNamePrefix="select"
                      closeMenuOnSelect={true}
                      onChange={handleUserFIOnChange}
                      value={userFIValue}
                      menuPortalTarget={document.body}
                      styles={{
                        dropdownIndicator: (provided) => ({ ...provided, color: '#464f57' }),
                        indicatorSeparator: (provided) => ({ ...provided, backgroundColor: '#464f57' }),
                        menuPortal: (base, state) => ({ ...base, zIndex: 9996, color: '#d6d8e0 !important', backgroundColor: state.isActive ? 'rgba(96, 111, 125, 0.27)' : 'transparent' }),
                        control: (base) => ({ ...base, zIndex: 9996, border: '0.5px solid rgba(214, 216, 224, 0.1) !important' }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(96, 111, 125, 0.27)',
                          primary: 'rgba(96, 111, 125, 0.27)',
                          neutral0: '#232d37',
                          primary50: '#232d37',
                          primary75: '#232d37',
                          neutral20: '#d6d8e0',
                          neutral50: '#d6d8e0',
                          neutral80: '#d6d8e0',
                          neutral5: '#d6d8e0',
                          neutral30: '#d6d8e0',
                          neutral60: '#d6d8e0',
                          neutral90: '#d6d8e0',
                          neutral40: '#d6d8e0',
                          neutral70: '#d6d8e0',
                          neutral10: '#171E26',
                          dangerLight: '#f47f7f',
                        },
                      })}
                    />
                  )}
                </div>
                <p className="fi-modal-text">Please select up to 11 financial institutions to compare against or which you are interested in analyzing alongside your own.</p>
                {!_.isEmpty(FIs) && (
                  <Select
                    isMulti
                    options={FIs}
                    className="fi-selector"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={handleFIOnChange}
                    value={value}
                    isOptionDisabled={() => value.length >= 11}
                    menuPortalTarget={document.body}
                    styles={{
                      clearIndicator: (provided) => ({ ...provided, color: '#464f57' }),
                      dropdownIndicator: (provided) => ({ ...provided, color: '#464f57' }),
                      indicatorSeparator: (provided) => ({ ...provided, backgroundColor: '#464f57' }),
                      menuPortal: (base, state) => ({ ...base, zIndex: 9996, color: '#d6d8e0 !important', backgroundColor: state.isActive ? 'rgba(96, 111, 125, 0.27)' : 'transparent' }),
                      control: (base) => ({ ...base, zIndex: 9996, border: '0.5px solid rgba(214, 216, 224, 0.1) !important' }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(96, 111, 125, 0.27)',
                        primary: 'rgba(96, 111, 125, 0.27)',
                        neutral0: '#232d37',
                        primary50: '#232d37',
                        primary75: '#232d37',
                        neutral20: '#d6d8e0',
                        neutral50: '#d6d8e0',
                        neutral80: '#d6d8e0',
                        neutral5: '#d6d8e0',
                        neutral30: '#d6d8e0',
                        neutral60: '#d6d8e0',
                        neutral90: '#d6d8e0',
                        neutral40: '#d6d8e0',
                        neutral70: '#d6d8e0',
                        neutral10: '#171E26',
                        dangerLight: '#f47f7f',
                      },
                    })}
                  />
                )}
                <p className="fi-modal-text">
                  Your selections above will determine default views of graphs and charts. At any time, you can change these selections in your settings page. When you are finished, please click
                  submit to continue.
                </p>
              </Modal>
            </>
          )}
        </Card>
      </Content>
    </>
  )
}
