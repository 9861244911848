import { Table } from 'antd'
import PropTypes from 'prop-types'

import './scorecard-table.css'

export const ScorecardTable = (props) => {
  const { columns, data, height, isPagination } = props

  return (
    <Table
      rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
      className="table"
      scroll={{ y: height }}
      dataSource={data}
      columns={columns}
      pagination={isPagination}
    />
  )
}

ScorecardTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  isPagination: PropTypes.bool.isRequired,
}
