import { useContext, useEffect, useState } from 'react'

// import { Link } from 'react-router-dom'
import { Layout } from 'antd'
import _ from 'lodash'
import Swal from 'sweetalert2'

import { CheckUserInfo, Loading, PageHeader, TrendLineChart } from '../../components'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import { filtersAPI } from '../../api'

import {
  findHeightChart,
  formatQuarterlyAggData2,
  formatQuarterlyClassData2,
  formatQuarterlyFIData2,
  formatYearlyAggData2,
  formatYearlyClassData2,
  formatYearlyFIData2,
} from '../../common/utils/helpers'
import './pxitrends-page.css'

const { Content } = Layout

export const PXITrendsPage = () => {
  const { filterSelection, tagSelection, setTagSelection, chosenFIs, setChosenFIs, timeValues } = useContext(FiltersContext)
  const { userInfo, getChosenFIs } = useContext(ThemeContext)
  const {
    isTrendsLoading,
    trendsData,
    setIsTrendingLoading,
    setResp,
    setTrendsData,
    setTrendsState,
    trendsState,
    pxiData,
    pxiYearlyData,
    isPXIAgg,
    isPXIFI,
    isPXIClassification,
    chartLabel,
    linePXIArr,
    setIsTrendsLoading,
    isTrendsCallActive,
    setIsTrendsCallActive,
    // setTrendsFilterSelection,
  } = useContext(DataContext)

  const [state, setState] = useState({
    isPXIYearly: false,
    height: findHeightChart(),
  })

  const { isPXIYearly, height } = state

  const width = window.innerWidth - 0.02 * window.innerHeight - 6

  const getPXIdata = (data, quarterlyFunction, yearlyFunction) => {
    let pxiData = []
    let pxiYearlyData = []
    let quarterlyAggData = quarterlyFunction(data, 'PXI')
    let yearlyAggData = yearlyFunction(data, 'PXI')
    let userFITrendsData = data.filter((item) => item._id.fi_id === userInfo.userFI)

    let userFIQuarterlyData = formatQuarterlyFIData2(userFITrendsData, 'PXI')
    quarterlyAggData.forEach((item2) => {
      const objIndex = userFIQuarterlyData.findIndex((item) => item.name === item2.name)
      if (objIndex !== -1) {
        pxiData.push(Object.assign(userFIQuarterlyData[objIndex], item2))
      } else {
        pxiData.push(item2)
      }
    })

    let userFIYearlyData = formatYearlyFIData2(userFITrendsData, 'PXI')
    yearlyAggData.forEach((item2) => {
      const objIndex = userFIYearlyData.findIndex((item) => item.name === item2.name)
      if (objIndex !== -1) {
        pxiYearlyData.push(Object.assign(userFIYearlyData[objIndex], item2))
      } else {
        pxiYearlyData.push(item2)
      }
    })

    return { pxiData, pxiYearlyData }
  }

  const onClassToggleClick = () => {
    const { pxiData, pxiYearlyData } = getPXIdata(trendsData, formatQuarterlyClassData2, formatYearlyClassData2)

    setTrendsState({
      ...trendsState,
      pxiData: pxiData,
      pxiYearlyData: pxiYearlyData,
      isPXIAgg: false,
      isPXIClassification: true,
      isPXIFI: false,
      linePXIArr: ['Bank', 'Credit Union', 'Digital Bank', 'Unclassified', userInfo.userFI],
      chartLabel: 'Classifications',
    })
  }
  const onAggToggle = () => {
    const { pxiData, pxiYearlyData } = getPXIdata(trendsData, formatQuarterlyAggData2, formatYearlyAggData2)

    setTrendsState({
      ...trendsState,
      pxiData: pxiData,
      pxiYearlyData: pxiYearlyData,
      isPXIAgg: true,
      isPXIFI: false,
      isPXIClassification: false,
      linePXIArr: ['Competitive Set', userInfo.userFI],
      chartLabel: 'Aggregation',
    })
  }
  const onFIToggleClick = () => {
    if (!_.isEmpty(chosenFIs) && chosenFIs.length < 11) {
      const { pxiData, pxiYearlyData } = getPXIdata(trendsData, formatQuarterlyFIData2, formatYearlyFIData2)
      setTrendsState({
        ...trendsState,
        pxiData: pxiData,
        pxiYearlyData: pxiYearlyData,
        isPXIAgg: false,
        isPXIFI: true,
        isPXIClassification: false,
        // linePXIArr: createLineArr(trendsData),
        linePXIArr: chosenFIs,
        chartLabel: 'Financial Institutions',
      })
    }
  }
  const onYearlyToggle = () => {
    setState({ ...state, isPXIYearly: !isPXIYearly })
  }

  const checkUserInfo = async () => {
    const updatedChosenFIs = await getChosenFIs()
    if (!_.isEmpty(updatedChosenFIs)) {
      setChosenFIs(updatedChosenFIs)
      const sentData = {
        chosenFIs: updatedChosenFIs,
        filterSelection,
      }
      // console.log(sentData)
      updateTrendsData(sentData)
    } else {
      setIsTrendsLoading(false)
      setIsTrendingLoading(false)
    }
  }

  // general useEffect, empty callback
  useEffect(() => {
    window.scrollTo(0, 0)
    // setIsTrendsLoading(true)
    // setIsTrendingLoading(true)
    // setFilterSelection({})
    // setTrendsFilterSelection({})
    // setTagSelection({})

    function resizeHandler() {
      setState({ ...state, height: findHeightChart() })
    }
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    checkUserInfo()
  }, [userInfo])

  // useEffect that listens for isTrendsCallActive
  useEffect(() => {
    if (!isTrendsCallActive && !_.isEmpty(trendsData)) {
      // setFIs(formatFIs(trendsData))
      return
    }

    if (isTrendsCallActive) {
      setTagSelection({
        ...tagSelection,
        time: timeValues.filter((time) => !isNaN(parseInt(time.charAt(0))) || time.charAt(0) === 'Q'),
      })

      // setFilterSelection({
      //   ...filterSelection,
      //   time: {
      //     year: filterSelection.time.year,
      //     quarter: filterSelection.time.quarter,
      //     month: [],
      //   },
      // })
      const sentData = {
        chosenFIs,
        filterSelection,
      }
      // get trending data
      updateTrendsData(sentData)
    }

    // if no trend chart data and no filters selected set trending to landing data
    // if (trendsData.length === 0) {
    //   console.log('No data found')
    // }
  }, [isTrendsCallActive])

  const updateTrendsData = (sentData) => {
    setIsTrendsLoading(true)
    setIsTrendingLoading(true)
    filtersAPI
      .getFilterSelectionsTest('/trends', sentData)
      .then((data) => {
        if (!_.isEmpty(data)) {
          // console.log(data)
          // setTrendsFilterSelection(filterSelection)
          setResp(data)
          setTrendsData(data)
          // setFIs(formatFIs(data))
          if (isPXIClassification) {
            const { pxiData, pxiYearlyData } = getPXIdata(data, formatQuarterlyClassData2, formatYearlyClassData2)
            setTrendsState({
              ...trendsState,
              pxiData: pxiData,
              pxiYearlyData: pxiYearlyData,
            })
          } else {
            const { pxiData, pxiYearlyData } = getPXIdata(data, formatQuarterlyAggData2, formatYearlyAggData2)
            setTrendsState({
              ...trendsState,
              pxiData: pxiData,
              pxiYearlyData: pxiYearlyData,
              isPXIAgg: true,
              ixPXIFI: false,
              linePXIArr: ['Competitive Set', userInfo.userFI],
              chartLabel: 'Aggregation',
            })
          }
        } else {
          // setTagSelection({})
          // setFilterSelection({})
          Swal.fire({
            text: 'Unfortunately these particular Trends Page filter selections yielded no results. Please select different filters and try again.',
          })
        }
      })
      .catch((error) => {
        console.warn(error)
      })
      .finally(() => {
        setIsTrendsLoading(false)
        setIsTrendingLoading(false)
        setIsTrendsCallActive(false)
      })
  }

  return (
    <>
      <Content className="page-content">
        <div className="trends-pages-flex">
          <Loading isLoading={isTrendsLoading}>
            <CheckUserInfo>
              <PageHeader
                title="PXI Trends"
                description="PXI stands for Product Experience Indicator and it is calculated by taking the percent of positive reviews and subtracting from it the percent of negative reviews. This metric is useful in benchmarking against competition, in assessing performance over time, and in identifying risks and opportunities."
              />
              {!_.isEmpty(pxiData) && (
                <div style={{ display: 'block', justifyContent: 'center' }}>
                  <div className="contentWrapper">
                    <div className="content active" id="pxi">
                      <TrendLineChart
                        data={isPXIYearly ? pxiYearlyData : pxiData}
                        lineArr={linePXIArr}
                        height={height < 0.8 * width ? height : width > 370 ? 0.8 * width : 300}
                        dataValue="PXI"
                        chartType={chartLabel}
                        legendName={isPXIYearly ? 'Yearly View' : 'Quarterly View'}
                        btnClassClass={isPXIClassification ? 'trends-btn-active' : ''}
                        btnFIClass={isPXIFI ? 'trends-btn-active' : ''}
                        btnAggClass={isPXIAgg ? 'trends-btn-active' : ''}
                        onAggToggle={onAggToggle}
                        onClassifiClick={onClassToggleClick}
                        onFIClick={onFIToggleClick}
                        onYearlyToggle={onYearlyToggle}
                        timeBtnName={isPXIYearly ? 'Show Quarterly View' : 'Show Yearly View'}
                      />
                    </div>
                  </div>
                </div>
              )}
            </CheckUserInfo>
          </Loading>
          {/* <Space align="center">
          <Link className="breadcrumb" to={location.pathname} state={location.state}>
          <Link className="breadcrumb" to="/trends" state={{ prevPages: [{ pageTitle: 'PXI Trends', pagePath: '/pxitrends' }] }}>
            <Button
              key="trends"
              data-id="trends"
              className="blue-button center-flex savedFIBtn trends-button"
              // onClick={() => {
              //   navigate('/trends')
              // }}
            >
              Trends
            </Button>
          </Link>
          <Link className="breadcrumb" to="/mentionratetrends" state={{ prevPages: [{ pageTitle: 'PXI Trends', pagePath: '/pxitrends' }] }}>
            <Button
              key="mentionrate"
              data-id="mentionrate"
              className="blue-button center-flex savedFIBtn trends-button"
              // onClick={() => {
              //   navigate('/mentionratetrends')
              // }}
            >
              Mention Rate Trends
            </Button>
          </Link>
        </Space> */}
        </div>
      </Content>
    </>
  )
}
