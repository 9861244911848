/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react'
// import { CSVLink } from 'react-csv'
import { useSearchParams } from 'react-router-dom'
import Select from 'react-select'

import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'
import { Card, Grid, Layout, List, Pagination, Space } from 'antd'
import _ from 'lodash'

import { CheckUserInfo, Loading, PageHeader, ReviewCard, ReviewControls } from '../../components'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import { reviewsAPI } from '../../api'

import { changeKeyData, formatReviewFIs, formatReviews } from '../../common/utils/helpers'
import './review.css'

const { Content } = Layout
const { useBreakpoint } = Grid
export const ReviewMiningPage = () => {
  const {
    fis,
    setFis,
    chosenFIs,
    setChosenFIs,
    filterSelection,
    setFilterSelection,
    isReviewsDrivers,
    setIsReviewsDrivers,
    topics,
    customerServiceValues,
    feesAndIncentivesValues,
    productOfferingsValues,
    brandEquityValues,
    accessibilityValues,
    designAndFunctionalityValues,
  } = useContext(FiltersContext)
  const { md, lg, xl, xxl } = useBreakpoint()
  const {
    reviewsData,
    setReviewsData,
    setLandingReviewsData,
    isReviewsLoading,
    setIsReviewsLoading,
    setResp,
    totalReviews,
    setTotalReviews,
    totalReviewsTextAndNoText,
    setTotalReviewsTextAndNoText,
    totalPerPage,
    setTotalPerPage,
    setLandingTotalReviews,
    currentPage,
    setCurrentPage,
    reviewsPXI,
    isReviewsPXILoading,
    isTextAnd,
    setIsTextAnd,
    reviewsText,
    setIsReviewsPXILoading,
    setReviewsPXI,
    setReviewsPXILanding,
    isReviewCardExpanded,
    setIsReviewCardExpanded,
    driversSelection,
    setDriversSelection,
    unsubmittedFilters,
    isReviewsCallActive,
    setIsReviewsCallActive,
    setReviewsFilterSelection,
    // reviewsCsvData,
    // setReviewCsvData,
  } = useContext(DataContext)
  const { userInfo, getChosenFIs } = useContext(ThemeContext)
  const [searchParams] = useSearchParams()
  const [viewOptions, setViewOptions] = useState([])
  const [reviewFIValues, setReviewFIValues] = useState([])
  // const [fi, setFi] = useState('')

  //general useEffect, empty callback
  useEffect(() => {
    window.scrollTo(0, 0)

    if (!_.isEmpty(searchParams.get('fi'))) {
      setFis([searchParams.get('fi')])
    }
    checkUserInfo()
  }, [])

  // // useEffect to handle searchParams
  // useEffect(() => {
  //   console.log(`searchParams: ${searchParams.get('fi')}`)
  //   if (!_.isEmpty(searchParams.get('fi'))) {
  //     setFi(searchParams.get('fi'))
  //   } else {
  //     setFi('')
  //   }
  //   // setIsReviewsCallActive(true)
  // }, [searchParams])

  const checkUserInfo = async () => {
    const updatedChosenFIs = await getChosenFIs()
    if (!_.isEmpty(updatedChosenFIs)) {
      setChosenFIs(updatedChosenFIs)
      const formattedFIs = updatedChosenFIs.map((fi) => ({
        value: fi,
        label: fi,
      }))
      setViewOptions(formattedFIs)
      setReviewFIValues(formattedFIs)

      // let selectedFis = updatedChosenFIs
      // if (!_.isEmpty(fi)) {
      //   selectedFis = [fi]
      // }
      // setIsReviewsLoading(true)
      // reviewsAPI.putReviewsNewPage(1, 10, {}, selectedFis, [], false, false).then((response) => {
      //   setIsReviewsLoading(false)
      //   setReviewsFilterSelection(filterSelection)
      //   const { data, total } = response
      //   // const updatedKeyData = changeKeyData(data)
      //   // setReviewCsvData(updatedKeyData)
      //   const newData = formatReviews(data, isReviewCardExpanded)
      //   setReviewsData(newData)
      //   setCurrentPage(1)
      //   // setTotalPerPage(total)
      //   setTotalReviews(total)
      //   setLandingTotalReviews(total)
      //   setLandingReviewsData(response)
      //   setResp(response)
      //   setIsReviewsCallActive(false)
      //   // if (fi) setFIs(formatReviewFIs(fi))
      // })
      // fetchPXI()
      setIsReviewsCallActive(true)
      return formattedFIs
    } else {
      setIsReviewsLoading(false)
    }
    return []
  }

  useEffect(() => {
    checkUserInfo()
  }, [userInfo])

  //useEffect to handle isReviewsCallActive
  useEffect(async () => {
    if (!isReviewsCallActive) return
    if (isReviewsCallActive) {
      const importedFilterSelection = {
        ...filterSelection,
        ...unsubmittedFilters,
      }
      setFilterSelection(importedFilterSelection)
      let reviewFIs = reviewFIValues
      let review_fis = []
      if (_.isEmpty(reviewFIs)) {
        reviewFIs = await checkUserInfo()
      }
      if (isReviewsDrivers) {
        review_fis = reviewFIs.map((fi) => fi.value).slice(0, -1)
      } else {
        review_fis = reviewFIs.map((fi) => fi.value)
      }
      if (!_.isEmpty(review_fis)) {
        let selectedFis = review_fis
        if (!_.isEmpty(searchParams.get('fi'))) {
          const paramFi = searchParams.get('fi')
          setFis([paramFi])
          selectedFis = [paramFi]
        }
        const formattedFIs = selectedFis.map((fi) => ({
          value: fi,
          label: fi,
        }))
        setReviewFIValues(formattedFIs)
        setIsReviewsLoading(true)
        reviewsAPI.putReviewsNewPage(1, 10, importedFilterSelection, selectedFis, reviewsText, false, false).then((response) => {
          setIsReviewsLoading(false)
          setReviewsFilterSelection(importedFilterSelection)
          const { data, total, fi } = response
          const newData = formatReviews(data, isReviewCardExpanded)
          setReviewsData(newData)
          setTotalReviews(total)
          setLandingTotalReviews(total)
          setLandingReviewsData(response)
          setResp(response)
          // const updatedKeyData = changeKeyData(data)
          // setReviewCsvData(updatedKeyData)
          // setTotalPerPage(total)
          // if (fi) setFIs(formatReviewFIs(fi))
        })
        if (isReviewsDrivers) {
          setIsReviewsDrivers(false)
        }
        setCurrentPage(1)
        setIsReviewsCallActive(false)
        fetchPXI(selectedFis)
      }
      // reviewsAPI.putReviewsDrivers(10, review_fis, filterSelection, [fis], false, driversSelection).then((response) => {
      //   if (fi) setFIs(formatReviewFIs(fi))
      // })
      // //if no reviews and no filters selected
      // if (!_.isEmpty(chosenFIs) && _.isEmpty(reviewsData)) {
      //   // if (_.isEmpty(searchParams.get('fi')) {
      //   reviewsAPI.putReviewsNewPage(1, 10, {}, chosenFIs, [], false, false).then((response) => {
      //     // if (fi) setFIs(formatReviewFIs(fi))
      //   })
      // }
    }
  }, [isReviewsCallActive])

  const fetchReviews = (page, total) => {
    setIsReviewsLoading(true)
    let review_fis = reviewFIValues.map((fi) => fi.value)
    if (!_.isEmpty(review_fis)) {
      reviewsAPI.putReviewsNewPage(page, total, filterSelection, review_fis, reviewsText, isTextAnd, false).then(({ data, total, fis }) => {
        setIsReviewsLoading(false)
        // const updatedKeyData = changeKeyData(data)
        // setReviewCsvData(updatedKeyData)
        const newData = formatReviews(data, isReviewCardExpanded)
        setReviewsData(newData)
        setTotalReviews(total)
        // if (fi) setFIs(formatReviewFIs(fi))
      })
    }
  }

  const fetchPXI = (review_fis) => {
    const importedFilterSelection = {
      ...filterSelection,
      ...unsubmittedFilters,
    }
    setIsReviewsPXILoading(true)
    // let review_fis = reviewFIValues.map((fi) => fi.value)
    // if (!_.isEmpty(fis)) {
    //   review_fis = fis
    // }
    if (!_.isEmpty(review_fis)) {
      //pxi call
      reviewsAPI.putReviewsPXI(importedFilterSelection, review_fis, reviewsText, isTextAnd, driversSelection).then((data) => {
        setIsReviewsPXILoading(false)
        // if (data.pxi.toString()) {
        //   setTotalReviewsTextAndNoText(data.totalTextAndNoText)
        //   setReviewsPXI(data.pxi.toString())
        //   setReviewsPXILanding(data.pxi.toString())
        //   setDriversSelection('')
        // }
        if (data) {
          setReviewsPXI(data.toString())
          setReviewsPXILanding(data.toString())
          setDriversSelection('')
        } else {
          setReviewsPXI('none')
          setDriversSelection('')
        }
      })
    }
  }

  const handleExpandClick = () => {
    const cardData = reviewsData.map((item) => {
      const { index, _id, rating, username, review_date, review_text, review_title } = item
      if (isReviewCardExpanded) {
        return {
          index,
          _id,
          rating,
          username,
          review_date,
          review_text,
          id: 'collapsed',
          review_title,
        }
      } else {
        return {
          index,
          _id,
          rating,
          username,
          review_date,
          review_text,
          id: 'expanded',
          review_title,
        }
      }
    })
    setReviewsData(cardData)
    setIsReviewCardExpanded(!isReviewCardExpanded)
  }

  const handleSubmit = () => {
    setIsReviewsLoading(true)
    setIsReviewsPXILoading(true)
    const review_fis = reviewFIValues.map((fi) => fi.value)
    if (!_.isEmpty(review_fis)) {
      //pxi call
      reviewsAPI.putReviewsNewPage(1, totalPerPage, filterSelection, review_fis, reviewsText, false, false).then((response) => {
        setIsReviewsLoading(false)
        setReviewsFilterSelection(filterSelection)
        const { data, total, fi } = response
        const newData = formatReviews(data, isReviewCardExpanded)
        setReviewsData(newData)
        setCurrentPage(1)
        setTotalReviews(total)
        setLandingTotalReviews(total)
        setLandingReviewsData(response)
        setResp(response)
        setIsReviewsCallActive(false)
      })
      fetchPXI(review_fis)
    }
  }

  const handleViewOptionOnChange = (selectedOption) => {
    setReviewFIValues(selectedOption)
  }

  const handleToggle = (value) => {
    setIsTextAnd(value)
  }

  const onMoreClick = (e) => {
    let btnText
    let btnId
    if (e.target.tagName === 'SPAN') {
      btnText = e.target.innerHTML
      btnId = e.target.parentNode.dataset.id
    } else {
      btnText = e.target.firstElementChild.innerHTML
      btnId = e.target.dataset.id
    }

    if (btnText === '...more') {
      const cardData = reviewsData.map((item) => {
        const { index, _id, rating, username, review_date, review_text, id, review_title } = item
        if (btnId === item.index) {
          return {
            index,
            _id,
            rating,
            username,
            review_date,
            review_text,
            id: 'expanded',
            review_title,
          }
        } else {
          return {
            index,
            _id,
            rating,
            username,
            review_date,
            review_text,
            id,
            review_title,
          }
        }
      })
      setReviewsData(cardData)
    } else if (btnText === '...less') {
      const cardData = reviewsData.map((item) => {
        const { index, _id, rating, username, review_date, review_text, id, review_title } = item
        if (btnId === item.index) {
          return {
            index,
            _id,
            rating,
            username,
            review_date,
            review_text,
            id: 'collapsed',
            review_title,
          }
        } else {
          return {
            index,
            _id,
            rating,
            username,
            review_date,
            review_text,
            id,
            review_title,
          }
        }
      })
      setReviewsData(cardData)
    }
  }

  const ExpandName = () => {
    if (!isReviewCardExpanded) {
      return <span>{!md ? <ArrowsAltOutlined /> : 'Expand All'}</span>
    } else {
      return <span>{!md ? <ShrinkOutlined /> : `Collapse All`}</span>
    }
  }

  return (
    <>
      <Content className="page-content">
        <Loading isLoading={isReviewsLoading}>
          <CheckUserInfo>
            <PageHeader
              title="Reviews"
              description="Search for key words or phrases and view the qualitative data driving the PXI scores."
              // description="Search for key words or phrases and view the qualitative data driving the PXI scores. Please note that only reviews with text will appear here. However, all reviews, even those without text, are included in both the PXI and review count calculations."
            />
            {/* <CSVLink data={reviewsCsvData} filename="Reviews.csv">
            Download CSV
            </CSVLink> */}
            {reviewsData.length > 0 ? (
              <>
                <div className="selector-controls-flex-container">
                  <div className="reviews-select-flex-container">
                    <Select
                      isMulti
                      options={viewOptions}
                      value={reviewFIValues}
                      onChange={handleViewOptionOnChange}
                      className="fi-selector"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      menuPortalTarget={document.body}
                      styles={{
                        container: (base) => ({ ...base, flex: 1 }),
                        clearIndicator: (provided) => ({ ...provided, color: '#d6d8e0', '&:hover': { color: '#de350b', cursor: 'pointer' } }),
                        dropdownIndicator: (provided) => ({ ...provided, color: '#464f57' }),
                        indicatorSeparator: (provided) => ({ ...provided, backgroundColor: '#464f57' }),
                        placeholder: (provided) => ({ ...provided, textAlign: 'left' }),
                        menuPortal: (base, state) => ({ ...base, zIndex: 1, color: '#d6d8e0 !important', backgroundColor: state.isActive ? 'rgba(96, 111, 125, 0.27)' : 'transparent' }),
                        control: (base) => ({ ...base, zIndex: 1, border: '0.5px solid rgba(214, 216, 224, 0.1) !important', color: '#d6d8e0 !important' }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(96, 111, 125, 0.27)',
                          primary: 'rgba(96, 111, 125, 0.27)',
                          neutral0: '#232d37',
                          primary50: '#232d37',
                          primary75: '#232d37',
                          neutral20: '#d6d8e0',
                          neutral50: '#d6d8e0',
                          neutral80: '#d6d8e0',
                          neutral5: '#d6d8e0',
                          neutral30: '#d6d8e0',
                          neutral60: '#d6d8e0',
                          neutral90: '#d6d8e0',
                          neutral40: '#d6d8e0',
                          neutral70: '#d6d8e0',
                          neutral10: '#171E26',
                          dangerLight: '#f47f7f',
                        },
                      })}
                    />
                    <button className="select-submit" disabled={_.isEmpty(reviewFIValues)} onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                  <div className="controls-flex-container">
                    <ReviewControls
                      handleToggle={handleToggle}
                      isToggleAnd={isTextAnd}
                      handleExpandClick={handleExpandClick}
                      pxi={reviewsPXI}
                      pxiLoading={isReviewsPXILoading}
                      // total={new Intl.NumberFormat().format(totalReviewsTextAndNoText)}
                      forum="reviews"
                      expandName={<ExpandName />}
                      reviewFIValues={reviewFIValues}
                    />
                  </div>
                </div>
                <List
                  bordered={false}
                  className="reviews-list"
                  itemLayout="vertical"
                  size="small"
                  pagination={{
                    size: 'small',
                    current: currentPage,
                    className: 'reviews-pagination',
                    onChange: (page, total) => {
                      setCurrentPage(page)
                      setTotalPerPage(total)
                      fetchReviews(page, total)
                    },
                    showSizeChanger: true,
                    pageSize: totalPerPage,
                    position: 'both',
                    showQuickJumper: xl || xxl ? true : false,
                    total: totalReviews,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showTotal: (total, range) =>
                      md || lg || xl || xxl ? `${new Intl.NumberFormat().format(range[0])} - ${new Intl.NumberFormat().format(range[1])} of ${new Intl.NumberFormat().format(total)} reviews` : null,
                    // showTotal: (total, range) =>
                    //   md || lg || xl || xxl ? `${new Intl.NumberFormat().format(range[0])} - ${new Intl.NumberFormat().format(range[1])} of ${new Intl.NumberFormat().format(total)} reviews (with text)` : null,
                  }}
                  dataSource={reviewsData}
                  renderItem={(item, i) => {
                    if (item.review_text.trim().length) {
                      return (
                        <Card className="page-card reviews-card" bordered={false}>
                          <List.Item>
                            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                              <ReviewCard
                                key={item.id}
                                fi={item._id}
                                rating={item.rating}
                                username={item.username}
                                review_date={item.review_date}
                                review_text={item.review_text}
                                id={item.id}
                                onMoreClick={onMoreClick}
                                index={item.index}
                                title={item.review_title}
                              />
                            </Space>
                          </List.Item>
                        </Card>
                      )
                    } else return null
                  }}
                ></List>
                {/* Uncomment the following (and all the other changes dealing with no-text reviews) when those are implemented */}
                {/* <p className="review-page-footnote">
                  Only reviews with text will appear here. However, all reviews, even those without text, are included in both the PXI and review count calculations.
                </p> */}
              </>
            ) : isReviewsLoading ? null : (
              <>
                <div className="reviews-select-flex-container-no-results">
                  <Select
                    isMulti
                    options={viewOptions}
                    value={reviewFIValues}
                    onChange={handleViewOptionOnChange}
                    className="fi-selector"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    menuPortalTarget={document.body}
                    styles={{
                      container: (base) => ({ ...base, flex: 1 }),
                      clearIndicator: (provided) => ({ ...provided, color: '#d6d8e0', '&:hover': { color: '#de350b', cursor: 'pointer' } }),
                      dropdownIndicator: (provided) => ({ ...provided, color: '#464f57' }),
                      indicatorSeparator: (provided) => ({ ...provided, backgroundColor: '#464f57' }),
                      placeholder: (provided) => ({ ...provided, textAlign: 'left' }),
                      menuPortal: (base, state) => ({ ...base, zIndex: 1, color: '#d6d8e0 !important', backgroundColor: state.isActive ? 'rgba(96, 111, 125, 0.27)' : 'transparent' }),
                      control: (base) => ({ ...base, zIndex: 1, border: '0.5px solid rgba(214, 216, 224, 0.1) !important', color: '#d6d8e0 !important' }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(96, 111, 125, 0.27)',
                        primary: 'rgba(96, 111, 125, 0.27)',
                        neutral0: '#232d37',
                        primary50: '#232d37',
                        primary75: '#232d37',
                        neutral20: '#d6d8e0',
                        neutral50: '#d6d8e0',
                        neutral80: '#d6d8e0',
                        neutral5: '#d6d8e0',
                        neutral30: '#d6d8e0',
                        neutral60: '#d6d8e0',
                        neutral90: '#d6d8e0',
                        neutral40: '#d6d8e0',
                        neutral70: '#d6d8e0',
                        neutral10: '#171E26',
                        dangerLight: '#f47f7f',
                      },
                    })}
                  />
                  <button className="select-submit-no-results" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
                <p className="no-results">No results found for the selected filters. Please adjust your filter selections.</p>
              </>
            )}
          </CheckUserInfo>
        </Loading>
      </Content>
    </>
  )
}
