import { Col, Row } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { renderTags } from '../../common/utils/helpers'
import '../ModalFilters/modal-filters.css'

export const TagsRow = (props) => {
  const { tagSelection } = props
  const { accessibility, brandEquity, classifications, customer, customerService, feesAndIncentives, designAndFunctionality, product, productOfferings, sizes, sources, stars } = tagSelection

  return (
    <Row className="tag-row">
      {!_.isEmpty(stars) && (
        <div className="tag-col">
          <p style={{ color: 'var(--red-tag-title)' }}>Stars</p>
          {renderTags(tagSelection, 'stars')}
        </div>
      )}
      {!_.isEmpty(sources) && (
        <div className="tag-col">
          <p style={{ color: 'var(--red-tag-title)' }}>Sources</p>
          {renderTags(tagSelection, 'sources')}
        </div>
      )}
      {!_.isEmpty(classifications) && (
        <Col className="tag-col">
          <p style={{ color: 'var(--magenta-tag-title)' }}>Classifications</p>
          {renderTags(tagSelection, 'classifications')}
        </Col>
      )}
      {!_.isEmpty(sizes) && (
        <Col className="tag-col">
          <p style={{ color: 'var(--purple-tag-title)' }}>Sizes</p>
          {renderTags(tagSelection, 'sizes')}
        </Col>
      )}
      {!(_.isEmpty(customer) && _.isEmpty(product)) && (
        <Col className="tag-col">
          <p style={{ color: 'var(--cyan-tag-title)' }}>Attributes</p>
          {renderTags(tagSelection, 'attributes')}
        </Col>
      )}
      {!(_.isEmpty(feesAndIncentives) && _.isEmpty(productOfferings) && _.isEmpty(customerService) && _.isEmpty(brandEquity) && _.isEmpty(accessibility) && _.isEmpty(designAndFunctionality)) && (
        <Col className="tag-col">
          <p style={{ color: 'var(--green-tag-title)' }}>Topics</p>
          {renderTags(tagSelection, 'topics')}
        </Col>
      )}
    </Row>
  )
}

TagsRow.propTypes = {
  tagSelection: PropTypes.object.isRequired,
}
