/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { forwardRef, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { Button, Collapse, Modal, Select, Slider } from 'antd'
import _ from 'lodash'
// import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'

import { Checkboxes, TimeTabs } from '../../components'

import { FiltersContext, ThemeContext } from '../../providers'

// import { usersAPI } from '../../api'
import { camelCase, lowercase, yearQuarterDecimal } from '../../common/utils/helpers'
import './modal-component.css'

const { Panel } = Collapse
const { Option } = Select

export const ModalComponent = forwardRef((props, ref) => {
  const { pathname } = useLocation()
  // const [yearRange, setYearRange] = useState([moment().subtract(5, 'year').year(), moment().year()])
  // const [quarterRange, setQuarterRange] = useState(['Q1', 'Q4'])

  // Handler for slider change
  const {
    sources,
    stars,
    topics,
    filterSelection,
    customerServiceValues,
    feesAndIncentivesValues,
    productOfferingsValues,
    brandEquityValues,
    accessibilityValues,
    designAndFunctionalityValues,
    topicFilterValues,
    topicTitle,
    selectedTopics,
    attributes,
    customer_attrs,
    product_attrs,
    customerValues,
    productValues,
    attributeFilterValues,
    openPanels,
    isTrendsMentionRate,
    sourcesValues,
    starsValues,
    setTimeFlag,
    yearRange,
    setYearRange,
    quarterRange,
    setQuarterRange,
    sliderValue,
    setSliderValue,
    setSelectedTopics,
    setTopicFilterValues,
  } = useContext(FiltersContext)
  const { userInfo } = useContext(ThemeContext)

  const {
    isModalOpen,
    handleOk,
    handleCancel,
    classificationValues,
    sizeValues,
    isExpanded,
    isCheckboxDisabled,
    isCustomerServiceToggleAnd,
    isFeesAndIncentivesToggleAnd,
    isProductOfferingsToggleAnd,
    isAccessibilityToggleAnd,
    isBrandEquityToggleAnd,
    isDesignAndFunctionalityToggleAnd,
    isCustomerToggleAnd,
    isProductToggleAnd,
    handleResetFilter,
    handleSubmit,
    handleExpandAll,
    updateCheckedFilters,
    handleSelectChange,
    handleSelectAll,
    handleAndOrChange,
    checkSelectionsChanged,
    savedFilterSet,
    setSavedFilterSet,
    mapTimesToObject,
    callback,
    callbackTopics,
    openTopicPanels,
  } = props
  const [topicValue, setTopicValue] = useState('Select a topic')
  const today = new Date()
  const fiveYearsAgo = today.getFullYear() - 5

  const handleSliderChange = (value) => {
    setSliderValue(value)
    const startYear = Math.floor(value[0])
    const startQuarter = value[0] - startYear
    const endYear = Math.floor(value[1])
    const endQuarter = value[1] - endYear
    setYearRange([value[0], value[1]])
    // setQuarterRange([quarterMarks[startQuarter * 4 + 1], quarterMarks[endQuarter * 4 + 1]])
    setQuarterRange([quarterMarks[startQuarter], quarterMarks[endQuarter]])

    // const yearStart = moment().subtract(value[0], 'year').year();
    // const yearEnd = moment().subtract(value[1], 'year').year();
    // setYearRange([yearStart, yearEnd]);
    // setQuarterRange([1, 4]);
    // console.log(value, startQuarter, endQuarter)
  }

  // Marks for quarter slider
  const quarterMarks = {
    0: 'Q1',
    0.25: 'Q2',
    0.5: 'Q3',
    0.75: 'Q4',
  }
  const [savedFilterSets, setSavedFilterSets] = useState([])

  const [timeData, setTimeData] = useState({
    month: [],
    quarter: [],
    year: [],
  })

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      const { savedFilterSets } = userInfo
      setSavedFilterSets(savedFilterSets)
    }
  }, [savedFilterSet])

  useEffect(() => {
    if (topicTitle === '') {
      setTopicValue('Select a topic')
    }
  }, [topicTitle])

  useEffect(() => {
    // Check if yearRange has changed
    if (yearRange[0] !== fiveYearsAgo || yearRange[1] !== today.getFullYear()) {
      // Update status flag to 1
      setTimeFlag(1)
    } else {
      setTimeFlag(0)
    }
  }, [yearRange])

  const handleTopicSelectChange = (value) => {
    setTopicValue(value)
    handleSelectChange(value)
  }

  const formatter = (value) => `${Math.floor(value)} ${quarterMarks[value - Math.floor(value)]}`

  return (
    <Modal
      open={isModalOpen}
      title="Select Your Filters"
      maskClosable={true}
      onOk={handleOk}
      onCancel={handleCancel}
      bodyStyle={{ maxHeight: '57vh', overflowY: 'auto', overflowX: 'hidden' }}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'space-between' }} key="footer-div">
          <Button type="link" key="clear" className="clear-filters-button" onClick={handleResetFilter}>
            Clear Filters
          </Button>
          {checkSelectionsChanged() && (
            <>
              <Button key="submit" className="modal-submit-btn" onClick={handleSubmit}>
                Submit
              </Button>
            </>
          )}
        </div>,
      ]}
    >
      <div className="ant-modal-toggles">
        <Button key="expand-or-collapse" type="link" onClick={handleExpandAll}>
          {isExpanded ? 'Collapse All' : 'Expand All'}
        </Button>
      </div>
      <Collapse ghost={true} activeKey={openPanels} onChange={callback}>
        {!_.isEmpty(stars) && pathname === '/reviews' && (
          <Panel header="Star Rating" key="star">
            <Checkboxes isSwitchVisible={false} onChange={updateCheckedFilters} filterType="stars" handleSelectAll={handleSelectAll} apiData={stars} values={starsValues} />
          </Panel>
        )}
        {!_.isEmpty(sources) && pathname !== '/data' && pathname !== '/forums' && (
          <Panel header="Sources" key="source">
            {<Checkboxes isSwitchVisible={false} onChange={updateCheckedFilters} filterType="sources" handleSelectAll={handleSelectAll} apiData={sources} values={sourcesValues} />}
          </Panel>
        )}
        <Panel header="Time Period" key="time">
          <div>
            <Slider range min={fiveYearsAgo} max={yearQuarterDecimal(today)} value={sliderValue} onChange={handleSliderChange} tooltip={{ formatter }} step={0.25} />
            <div className="sliderrange">
              {Math.floor(sliderValue[0])} {quarterRange[0]} - {Math.floor(sliderValue[1])} {quarterRange[1]}
            </div>
          </div>
        </Panel>
        {pathname !== '/topics' && pathname !== '/drivers' && pathname !== '/trends' && !isTrendsMentionRate && !_.isEmpty(topics) && !_.isEmpty(topicFilterValues) ? (
          <Panel header="Topics" key="topics">
            <Collapse ghost={true} activeKey={openTopicPanels} onChange={callbackTopics}>
              {topics.map((item) => {
                if (item.label !== 'Customer' && item.label !== 'Product') {
                  const checkValues = topicFilterValues[`${camelCase(item.label)}Values`]
                  return (
                    <Panel header={item.label} key={item.label}>
                      <Checkboxes
                        isDisabled={isCheckboxDisabled}
                        isSwitchVisible={false}
                        handleAndOrChange={handleAndOrChange}
                        filterType={camelCase(item.label)}
                        isToggleAnd={props[`is${item.label.replace(/\s+/g, '')}ToggleAnd`]}
                        onChange={updateCheckedFilters}
                        handleSelectAll={handleSelectAll}
                        apiData={item.children}
                        values={checkValues}
                        category={item.category}
                      />
                    </Panel>
                  )
                }
              })}
            </Collapse>
          </Panel>
        ) : (
          (pathname === '/topics' || pathname === '/trends' || isTrendsMentionRate) && (
            <Panel header="Topics" key="topics">
              <Collapse ghost={true} activeKey={openTopicPanels} onChange={callbackTopics}>
                <div className="single-topic-select">
                  <Select
                    defaultValue={'Select a topic'}
                    style={{
                      width: 300,
                    }}
                    onChange={handleTopicSelectChange}
                    value={topicValue}
                  >
                    {topics.map((item) => {
                      if (item.label !== 'Customer' && item.label !== 'Product') {
                        return (
                          <Option value={item.label} key={item.label}>
                            {item.label}
                          </Option>
                        )
                      }
                    })}
                  </Select>
                </div>
              </Collapse>
            </Panel>
          )
        )}
        {_.isEmpty(customer_attrs) &&
          _.isEmpty(product_attrs) &&
          attributes.map((item) => {
            return (
              <Panel header={item.label} key={lowercase(item.label)}>
                <Checkboxes
                  isDisabled={isCheckboxDisabled}
                  isSwitchVisible={false}
                  handleAndOrChange={handleAndOrChange}
                  filterType={camelCase(item.label)}
                  isToggleAnd={props[`is${item.label.replace(/\s+/g, '')}ToggleAnd`]}
                  onChange={updateCheckedFilters}
                  handleSelectAll={handleSelectAll}
                  apiData={item.children}
                  values={attributeFilterValues[`${camelCase(item.label)}Values`]}
                />
              </Panel>
            )
          })}
        {customer_attrs && (
          <Panel header="Customer" key="customer">
            <Checkboxes
              isDisabled={isCheckboxDisabled}
              isSwitchVisible={false}
              handleAndOrChange={handleAndOrChange}
              filterType="customer"
              isToggleAnd={props[`isCustomerToggleAnd`]}
              onChange={updateCheckedFilters}
              handleSelectAll={handleSelectAll}
              apiData={customer_attrs.map((item) => ({
                ...item,
                label: _.startCase(item.label),
              }))}
              values={attributeFilterValues[`customerValues`]}
            />
          </Panel>
        )}
        {product_attrs && (
          <Panel header="Product" key="product">
            <Checkboxes
              isDisabled={isCheckboxDisabled}
              isSwitchVisible={false}
              handleAndOrChange={handleAndOrChange}
              filterType="product"
              isToggleAnd={props[`isProductToggleAnd`]}
              onChange={updateCheckedFilters}
              handleSelectAll={handleSelectAll}
              apiData={product_attrs.map((item) => ({
                ...item,
                label: _.startCase(item.label),
              }))}
              values={attributeFilterValues[`productValues`]}
            />
          </Panel>
        )}
      </Collapse>
    </Modal>
  )
})

ModalComponent.displayName = 'ModalComponent'

ModalComponent.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  classificationValues: PropTypes.array.isRequired,
  sizeValues: PropTypes.array.isRequired,
  timeValues: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isCheckboxDisabled: PropTypes.bool.isRequired,
  isCustomerServiceToggleAnd: PropTypes.bool.isRequired,
  isFeesAndIncentivesToggleAnd: PropTypes.bool.isRequired,
  isDesignAndFunctionalityToggleAnd: PropTypes.bool.isRequired,
  isProductOfferingsToggleAnd: PropTypes.bool.isRequired,
  isAccessibilityToggleAnd: PropTypes.bool.isRequired,
  isBrandEquityToggleAnd: PropTypes.bool.isRequired,
  isCustomerToggleAnd: PropTypes.bool.isRequired,
  isProductToggleAnd: PropTypes.bool.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleExpandAll: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleAndOrChange: PropTypes.func.isRequired,
  updateCheckedFilters: PropTypes.func.isRequired,
  checkSelectionsChanged: PropTypes.func.isRequired,
  savedFilterSet: PropTypes.object.isRequired,
  setSavedFilterSet: PropTypes.func.isRequired,
  mapTimesToObject: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  callbackTopics: PropTypes.func.isRequired,
  openTopicPanels: PropTypes.array.isRequired,
}
