import { API } from 'aws-amplify'

const apiName = process.env.REACT_APP_API_GATEWAY_NAME

export const scorecardAPI = {
  putScorecardNewPage: (currentScorecardPage, filterSelection) =>
    API.put(apiName, '/scorecard/landing', {
      body: {
        currentScorecardPage,
        filterSelection,
      },
    }),
  putScorecardFilterSelection: (filterSelection) =>
    API.put(apiName, '/scorecard/filterSelection', {
      body: filterSelection,
    }),
}
