import { useContext, useEffect, useState } from 'react'

// import { Link } from 'react-router-dom'
import { Layout } from 'antd'
import _ from 'lodash'
import Swal from 'sweetalert2'

import { CheckUserInfo, InstructionDiv, Loading, PageHeader, TrendLineChart } from '../../components'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import { filtersAPI } from '../../api'

import {
  findHeightChart,
  formatQuarterlyAggData2,
  formatQuarterlyClassData2,
  formatQuarterlyFIData2,
  formatYearlyAggData2,
  formatYearlyClassData2,
  formatYearlyFIData2,
} from '../../common/utils/helpers'
import './mentionratetrends-page.css'

const { Content } = Layout

export const MentionRateTrendsPage = () => {
  const { filterSelection, setFilterSelection, tagSelection, setTagSelection, topicTitle, chosenFIs, setChosenFIs, timeValues } = useContext(FiltersContext)
  const { userInfo, getChosenFIs } = useContext(ThemeContext)
  const {
    isTrendsLoading,
    trendsData,
    setIsTrendingLoading,
    setResp,
    setTrendsData,
    setTrendsState,
    trendsState,
    mentionData,
    mentionYearlyData,
    isMentionAgg,
    isMentionFI,
    isMentionClassification,
    chartLabel,
    lineMentionArr,
    setIsTrendsLoading,
    isTrendsCallActive,
    setIsTrendsCallActive,
    setTrendsFilterSelection,
  } = useContext(DataContext)

  const [state, setState] = useState({
    isMentionYearly: false,
    height: findHeightChart(),
    isMentionYear: false,
  })

  const { isMentionYearly, height, isMentionYear } = state

  const width = window.innerWidth - 0.02 * window.innerHeight - 6

  const getMentiondata = (data, quarterlyFunction, yearlyFunction) => {
    let mentionData = []
    let mentionYearlyData = []
    let quarterlyAggData = quarterlyFunction(data, 'Mention Rate')
    let yearlyAggData = yearlyFunction(data, 'Mention Rate')
    let userFITrendsData = data.filter((item) => item._id.fi_id === userInfo.userFI)

    let userFIQuarterlyData = formatQuarterlyFIData2(userFITrendsData, 'Mention Rate')
    quarterlyAggData.forEach((item2) => {
      const objIndex = userFIQuarterlyData.findIndex((item) => item.name === item2.name)
      if (objIndex !== -1) {
        mentionData.push(Object.assign(userFIQuarterlyData[objIndex], item2))
      } else {
        mentionData.push(item2)
      }
    })

    let userFIYearlyData = formatYearlyFIData2(userFITrendsData, 'Mention Rate')
    yearlyAggData.forEach((item2) => {
      const objIndex = userFIYearlyData.findIndex((item) => item.name === item2.name)
      if (objIndex !== -1) {
        mentionYearlyData.push(Object.assign(userFIYearlyData[objIndex], item2))
      } else {
        mentionYearlyData.push(item2)
      }
    })

    return { mentionData, mentionYearlyData }
  }

  const onClassToggleClick = () => {
    const { mentionData, mentionYearlyData } = getMentiondata(trendsData, formatQuarterlyClassData2, formatYearlyClassData2)
    setTrendsState({
      ...trendsState,
      mentionData: mentionData,
      mentionYearlyData: mentionYearlyData,
      isMentionAgg: false,
      isMentionClassification: true,
      isMentionFI: false,
      lineMentionArr: ['Bank', 'Credit Union', 'Digital Bank', 'Unclassified', userInfo.userFI],
      chartLabel: 'Classifications',
    })
  }
  const onAggToggle = () => {
    const { mentionData, mentionYearlyData } = getMentiondata(trendsData, formatQuarterlyAggData2, formatYearlyAggData2)
    setTrendsState({
      ...trendsState,
      mentionData: mentionData,
      mentionYearlyData: mentionYearlyData,
      isMentionAgg: true,
      isMentionFI: false,
      isMentionClassification: false,
      lineMentionArr: ['Competitive Set', userInfo.userFI],
      chartLabel: 'Aggregation',
    })
  }
  const onFIToggleClick = () => {
    if (!_.isEmpty(chosenFIs) && chosenFIs.length < 11) {
      const { mentionData, mentionYearlyData } = getMentiondata(trendsData, formatQuarterlyFIData2, formatYearlyFIData2)
      setTrendsState({
        ...trendsState,
        mentionData: mentionData,
        mentionYearlyData: mentionYearlyData,
        isMentionAgg: false,
        isMentionFI: true,
        isMentionClassification: false,
        // lineMentionArr: createLineArr(trendsData),
        lineMentionArr: chosenFIs,
        chartLabel: 'Financial Institution',
      })
    }
  }
  const onYearlyToggle = () => {
    setState({ ...state, isMentionYearly: !isMentionYearly })
  }

  const checkUserInfo = async () => {
    const updatedChosenFIs = await getChosenFIs()
    if (!_.isEmpty(updatedChosenFIs)) {
      setChosenFIs(updatedChosenFIs)
      Swal.fire({
        text: 'Only one topic or topic category may be selected at a time. Please open up the filters and adjust your selections.',
      })
    } else {
      setIsTrendsLoading(false)
      setIsTrendingLoading(false)
    }
  }

  // general useEffect, empty callback
  useEffect(() => {
    window.scrollTo(0, 0)
    setFilterSelection({})
    setTrendsFilterSelection({})
    setTagSelection({})

    function resizeHandler() {
      setState({ ...state, height: findHeightChart() })
    }
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    checkUserInfo()
  }, [userInfo])

  // useEffect that listens for isTrendsCallActive
  useEffect(() => {
    if (!isTrendsCallActive && !_.isEmpty(trendsData)) {
      // setFIs(formatFIs(trendsData))
      return
    }

    if (isTrendsCallActive) {
      setTagSelection({
        ...tagSelection,
        time: timeValues.filter((time) => !isNaN(parseInt(time.charAt(0))) || time.charAt(0) === 'Q'),
      })

      // setFilterSelection({
      //   ...filterSelection,
      //   time: {
      //     year: filterSelection.time.year,
      //     quarter: filterSelection.time.quarter,
      //     month: [],
      //   },
      // })
      const sentData = {
        chosenFIs: chosenFIs,
        filterSelection,
      }
      const { customerService, feesAndIncentives, productOfferings, accessibility, brandEquity, designAndFunctionality } = filterSelection
      if (
        !_.isEmpty(filterSelection) &&
        (!_.isEmpty(customerService) || !_.isEmpty(feesAndIncentives) || !_.isEmpty(productOfferings) || !_.isEmpty(accessibility) || !_.isEmpty(brandEquity) || !_.isEmpty(designAndFunctionality))
      ) {
        // get trending data
        updateTrendsData(sentData)
      } else {
        setTrendsState({
          ...trendsState,
          mentionData: [],
          mentionYearlyData: [],
        })
        Swal.fire({
          text: 'Only one topic or topic category may be selected at a time. Please open up the filters and adjust your selections.',
        })
      }
      setIsTrendsCallActive(false)
    }

    // if no trend chart data and no filters selected set trending to landing data
    // if (trendsData.length === 0) {
    //   console.log('No data found')
    // }
  }, [isTrendsCallActive])

  const updateTrendsData = (sentData) => {
    setIsTrendsLoading(true)
    setIsTrendingLoading(true)
    filtersAPI
      .getFilterSelectionsTest('/trends', sentData)
      .then((data) => {
        // filtersAPI.getFilterSelections('/trends', sentData).then((data) => {
        if (!_.isEmpty(data)) {
          // setTrendsFilterSelection(filterSelection)
          setResp(data)
          setTrendsData(data)
          // setFIs(formatFIs(data))
          if (isMentionClassification) {
            const { mentionData, mentionYearlyData } = getMentiondata(data, formatQuarterlyClassData2, formatYearlyClassData2)
            setTrendsState({
              ...trendsState,
              mentionData: mentionData,
              mentionYearlyData: mentionYearlyData,
            })
          } else {
            // console.log(formatQuarterlyAggData2(data, 'Mention Rate'))
            const { mentionData, mentionYearlyData } = getMentiondata(data, formatQuarterlyAggData2, formatYearlyAggData2)
            setTrendsState({
              ...trendsState,
              mentionData: mentionData,
              mentionYearlyData: mentionYearlyData,
              lineMentionArr: ['Competitive Set', userInfo.userFI],
              chartLabel: 'Aggregation',
              isMentionAgg: true,
              isMentionFI: false,
            })
          }
        } else {
          // setTagSelection({})
          // setFilterSelection({})
          Swal.fire({
            text: 'Unfortunately these particular Trends Page filter selections yielded no results. Please select different filters and try again.',
          })
          // setResp(data)
          // setTrendsData(data)
          // setFIs(formatFIs(data))
        }
      })
      .catch((error) => {
        console.warn(error)
      })
      .finally(() => {
        setIsTrendsLoading(false)
        setIsTrendingLoading(false)
        setIsTrendsCallActive(false)
      })
  }

  return (
    <>
      <Content className="page-content">
        <div className="trends-pages-flex">
          <Loading isLoading={isTrendsLoading}>
            <CheckUserInfo>
              <PageHeader
                // title={`Topic Mention Rate Analysis - ${topicTitle}`}
                title={topicTitle.length > 0 ? `Topic Mention Rate Trends - ${topicTitle}` : `Topic Mention Rate Trends`}
                description="Topic Mention Rate is the percent of all reviews that contain at least one of the selected topics in the title or body of the review."
              />
              {!_.isEmpty(mentionData) ? (
                <div style={{ justifyContent: 'center' }}>
                  <div className="contentWrapper">
                    <div className="content active" id="mentionRate">
                      {/* {isFiltersActive ? ( */}
                      <TrendLineChart
                        data={isMentionYearly ? mentionYearlyData : mentionData}
                        lineArr={lineMentionArr}
                        height={height < 0.8 * width ? height : width > 370 ? 0.8 * width : 300}
                        dataValue="Mention Rate"
                        chartType={chartLabel}
                        legendName={isMentionYearly ? 'Yearly View' : 'Quarterly View'}
                        periodName={isMentionYear ? 'Show Quarterly View' : 'Show Yearly View'}
                        btnClassClass={isMentionClassification ? 'trends-btn-active' : ''}
                        btnFIClass={isMentionFI ? 'trends-btn-active' : ''}
                        btnAggClass={isMentionAgg ? 'trends-btn-active' : ''}
                        onAggToggle={onAggToggle}
                        onClassifiClick={onClassToggleClick}
                        onFIClick={onFIToggleClick}
                        onYearlyToggle={onYearlyToggle}
                        timeBtnName={isMentionYearly ? 'Show Quarterly View' : 'Show Yearly View'}
                      />
                      {/* ) : (
                      <InstructionDiv />
                    )} */}
                    </div>
                  </div>
                </div>
              ) : (
                <InstructionDiv />
              )}
            </CheckUserInfo>
          </Loading>
          {/* <Space align="center">
            <Link className="breadcrumb" to={location.pathname} state={location.state}>
            <Link className="breadcrumb" to="/trends" state={{ prevPages: [{ pageTitle: 'Mention Rate Trends', pagePath: '/mentionratetrends' }] }}>
              <Button
                key="trends"
                data-id="trends"
                className="blue-button center-flex savedFIBtn trends-button"
                // onClick={() => {
                //   navigate('/trends')
                // }}
              >
                Trends
              </Button>
            </Link>
            <Link className="breadcrumb" to="/pxitrends" state={{ prevPages: [{ pageTitle: 'Mention Rate Trends', pagePath: '/mentionratetrends' }] }}>
              <Button
                key="pxi"
                data-id="pxi"
                className="blue-button center-flex savedFIBtn trends-button"
                // onClick={() => {
                //   navigate('/pxitrends')
                // }}
              >
                PXI Trends
              </Button>
            </Link>
          </Space> */}
        </div>
      </Content>
    </>
  )
}
