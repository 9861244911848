// import { Link, useLocation } from 'react-router-dom' Uncomment this and other commented sections to re-enable breadcrumbs
import { QuestionCircleFilled } from '@ant-design/icons'
import { Popover, Row } from 'antd'
// import _ from 'lodash'
import PropTypes from 'prop-types'

import './page-header.css'

export const PageHeader = ({ title, description }) => {
  // const location = useLocation()
  // const prevPages = location.state?.prevPages

  const data = <div className="title-popover">{description}</div>

  return (
    <>
      <Row className="header-row">
        <h2 className="page-title">{title}</h2>
        <Popover placement="bottomRight" content={data}>
          <QuestionCircleFilled style={{ color: 'var(--text-heading)', fontSize: '0.75em' }} />
        </Popover>
      </Row>
      {/* <Row>
        {!_.isEmpty(prevPages) &&
          prevPages.map((page, i) => (
            <div key={i}
            //  className="mb-35"
             >
              <Link className="breadcrumb" to={page.pagePath} state={{ prevPages: [{ pageTitle: 'Home', pagePath: '/' }] }}>
                {page.pageTitle}
              </Link>
              <span className="breadcrumb-delimiter">&gt;</span>
            </div>
          ))}
        <Link className="breadcrumb" to={location.pathname} state={location.state}>
          {title}
        </Link>
      </Row> */}
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  classNameDiv: PropTypes.string,
  classNameEl: PropTypes.string,
  fontSize: PropTypes.string,
}
