import { useContext } from 'react'

import { ModalFilters, TagsRow } from '../../components'

import { FiltersContext } from '../../providers'

import './top-filters.css'

export const TopFilters = () => {
  const { tagSelection } = useContext(FiltersContext)
  return (
    <div>
      <TagsRow tagSelection={tagSelection} />
      <div className="top-filters">
        {/* <FinancialInstitutionSearch /> */}
        <ModalFilters />
      </div>
    </div>
  )
}
