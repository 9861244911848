import { createContext, useEffect, useRef, useState } from 'react'

import { Cache } from 'aws-amplify'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { filtersAPI } from '../../api'

import { getQuarter, mapFilters, yearQuarterDecimal } from '../../common/utils/helpers'

export const FiltersContext = createContext()

export const FiltersProvider = ({ children }) => {
  const today = new Date()
  const fiveYearsAgo = today.getFullYear() - 5
  const [fis, setFis] = useState([])
  const [yearRange, setYearRange] = useState([fiveYearsAgo, yearQuarterDecimal(today)])
  const [quarterRange, setQuarterRange] = useState(['Q1', `Q${getQuarter(today)}`])
  const [sliderValue, setSliderValue] = useState([fiveYearsAgo, yearQuarterDecimal(today)])
  localStorage.setItem('yearRange', JSON.stringify([fiveYearsAgo, yearQuarterDecimal(today)]))
  localStorage.setItem('quarterRange', JSON.stringify(['Q1', `Q${getQuarter(today)}`]))
  localStorage.setItem('sliderValue', JSON.stringify([fiveYearsAgo, yearQuarterDecimal(today)]))
  const [isFiltersActive, setIsFiltersActive] = useState(false)
  const [isReviewsDrivers, setIsReviewsDrivers] = useState(false)
  const [topicSelections, setTopicSelections] = useState({})
  const [topicTitle, setTopicTitle] = useState('')
  const [selectedTopics, setSelectedTopics] = useState([])
  const [fiTags, setFITags] = useState([])
  const filters = useRef(null)
  const [state, setState] = useState({
    sources: [],
    stars: [],
    classifications: [],
    sizes: [],
    topics: [],
    attributes: [],
    customer_attrs: [],
    product_attrs: [],
  })

  const [topicFilterValues, setTopicFilterValues] = useState({
    customerServiceValues: [],
    feesAndIncentivesValues: [],
    productOfferingsValues: [],
    brandEquityValues: [],
    accessibilityValues: [],
    designAndFunctionalityValues: [],
  })
  const [attributeFilterValues, setAttributeFilterValues] = useState({
    customerValues: [],
    productValues: [],
  })

  const [toggleValues, setToggleValues] = useState({
    isCustomerServiceToggleAnd: false,
    isFeesAndIncentivesToggleAnd: false,
    isDesignAndFunctionalityToggleAnd: false,
    isProductOfferingsToggleAnd: false,
    isAccessibilityToggleAnd: false,
    isBrandEquityToggleAnd: false,
    isCustomerToggleAnd: false,
    isProductToggleAnd: false,
  })
  const [classificationValues, setClassificationValues] = useState([])
  const [sizeValues, setSizeValues] = useState([])
  const [sourcesValues, setSourcesValues] = useState([])
  const [starsValues, setStarsValues] = useState([])
  const [topicValues, setTopicValues] = useState([])
  const [timeValues, setTimeValues] = useState([])
  const [timeFlag, setTimeFlag] = useState(0)
  const [filterSelection, setFilterSelection] = useState({})
  const [tagSelection, setTagSelection] = useState({})
  const [FIs, setFIs] = useState([])
  const [value, setValue] = useState([])
  const [chosenFIs, setChosenFIs] = useState([])
  const [openPanels, setOpenPanels] = useState([])
  const [selectedFilterValue, setSelectedFilterValue] = useState({})
  const [unsubmittedFilters, setUnsubmittedFilters] = useState(filterSelection)
  const [isTrendsMentionRate, setIsTrendsMentionRate] = useState(false)
  const { sources, stars, classifications, sizes, topics, attributes, customer_attrs, product_attrs } = state
  const { customerServiceValues, feesAndIncentivesValues, productOfferingsValues, brandEquityValues, accessibilityValues, designAndFunctionalityValues } = topicFilterValues
  const { customerValues, productValues } = attributeFilterValues

  const getFilterData = async () => {
    let dataObj = {}
    dataObj.sources = [
      { label: 'App Store', value: '0', _id: 'App Store', key: 'sources-0', length: 2, category: 'source', dbName: 'appstore' },
      { label: 'Google Play Store', value: '1', _id: 'Google Play Store', key: 'sources-1', length: 2, category: 'source', dbName: 'google_play_store' },
    ]
    dataObj.stars = [
      { label: '1 star', value: '0', _id: '1 star', key: 'stars-1', length: 5, category: 'star' },
      { label: '2 stars', value: '1', _id: '2 stars', key: 'stars-2', length: 5, category: 'star' },
      { label: '3 stars', value: '2', _id: '3 stars', key: 'stars-3', length: 5, category: 'star' },
      { label: '4 stars', value: '3', _id: '4 stars', key: 'stars-4', length: 5, category: 'star' },
      { label: '5 stars', value: '4', _id: '5 stars', key: 'stars-5', length: 5, category: 'star' },
    ]
    dataObj.sizes = await filtersAPI.getFiltersDataAPI('sizes')
    dataObj.classifications = await filtersAPI.getFiltersDataAPI('classifications')
    dataObj.topics = await filtersAPI.getFiltersDataAPI('topics')
    dataObj.attributes = await filtersAPI.getFiltersDataAPI('attributes')
    dataObj.customer_attrs = await filtersAPI.getFiltersDataAPI('customer_attrs')
    dataObj.product_attrs = await filtersAPI.getFiltersDataAPI('product_attrs')
    return dataObj
  }

  /* TIME TABS FNS */
  const mapTimesToObject = (yearRange, quarterRange) => {
    let startDate
    if (quarterRange[0] === 'Q1') {
      startDate = `${Math.floor(yearRange[0])}-01-01` // January 1st
    } else if (quarterRange[0] === 'Q2') {
      startDate = `${Math.floor(yearRange[0])}-04-01` // April 1st
    } else if (quarterRange[0] === 'Q3') {
      startDate = `${Math.floor(yearRange[0])}-07-01` // July 1st
    } else if (quarterRange[0] === 'Q4') {
      startDate = `${Math.floor(yearRange[0])}-10-01` // October 1st
    }
    let endDate
    if (quarterRange[1] === 'Q1') {
      endDate = `${Math.floor(yearRange[1])}-04-01` // April 1st
    } else if (quarterRange[1] === 'Q2') {
      endDate = `${Math.floor(yearRange[1])}-07-01` // July 1st
    } else if (quarterRange[1] === 'Q3') {
      endDate = `${Math.floor(yearRange[1])}-10-01` // October 1st
    } else if (quarterRange[1] === 'Q4') {
      endDate = `${Math.floor(yearRange[1]) + 1}-01-01` // January 1st, next year
    }
    return {
      beforetimepage: 'reviews',
      startDate: startDate,
      endDate: endDate,
      startYear: yearRange[0],
      startQuarter: quarterRange[0],
      endYear: yearRange[1],
      endQuarter: quarterRange[1],
    }
  }

  /* FILTER SELECTION FN'S */
  const emptyFilterSelection = {
    sources: [],
    stars: [],
    classifications: [],
    sizes: [],
    time: mapTimesToObject(yearRange, quarterRange),
    customerService: [],
    feesAndIncentives: [],
    productOfferings: [],
    accessibility: [],
    brandEquity: [],
    designAndFunctionality: [],
    customer: [],
    product: [],
    isCustomerServiceToggleAnd: false,
    isFeesAndIncentivesToggleAnd: false,
    isDesignAndFunctionalityToggleAnd: false,
    isProductOfferingsToggleAnd: false,
    isAccessibilityToggleAnd: false,
    isBrandEquityToggleAnd: false,
    isCustomerToggleAnd: false,
    isProductToggleAnd: false,
    // pathname,
    isTrendsMentionRate: false,
    isMultipleTopics: false,
  }

  const handleCheckedValues = (list, filterType) => {
    if (filterType === 'stars') {
      setStarsValues(list)
    } else if (filterType === 'sources') {
      setSourcesValues(list)
    } else if (filterType === 'topic') {
      setTopicFilterValues(list)
    } else if (filterType === 'customer' || filterType === 'product') {
      setAttributeFilterValues({ ...attributeFilterValues, [`${filterType}Values`]: list })
    } else setTopicFilterValues({ ...topicFilterValues, [`${filterType}Values`]: list })
  }

  const resetFilterData = () => {
    const yearRangeLocal = JSON.parse(localStorage.getItem('yearRange'))
    const quarterRangeLocal = JSON.parse(localStorage.getItem('quarterRange'))
    const sliderValueLocal = JSON.parse(localStorage.getItem('sliderValue'))
    const filterResetObj = {
      sources: [],
      stars: [],
      classifications: [],
      sizes: [],
      time: mapTimesToObject(yearRangeLocal, quarterRangeLocal),
      customerService: [],
      feesAndIncentives: [],
      productOfferings: [],
      accessibility: [],
      brandEquity: [],
      designAndFunctionality: [],
      customer: [],
      product: [],
      isCustomerServiceToggleAnd: false,
      isFeesAndIncentivesToggleAnd: false,
      isDesignAndFunctionalityToggleAnd: false,
      isProductOfferingsToggleAnd: false,
      isAccessibilityToggleAnd: false,
      isBrandEquityToggleAnd: false,
      isCustomerToggleAnd: false,
      isProductToggleAnd: false,
      // pathname,
      isTrendsMentionRate: false,
      isMultipleTopics: false,
    }
    setQuarterRange(quarterRangeLocal)
    setYearRange(yearRangeLocal)
    setSliderValue(sliderValueLocal)
    setTagSelection({})
    setSourcesValues([])
    setStarsValues([])
    setTopicTitle('')
    setTopicFilterValues({
      customerServiceValues: [],
      feesAndIncentivesValues: [],
      productOfferingsValues: [],
      brandEquityValues: [],
      accessibilityValues: [],
      designAndFunctionalityValues: [],
    })
    setAttributeFilterValues({
      productValues: [],
      customerValues: [],
    })
    setTimeValues([])
    setFilterSelection(filterResetObj)
    setUnsubmittedFilters(filterResetObj)
  }

  useEffect(() => {
    if (_.isEmpty(topicSelections)) {
      setIsFiltersActive(false)
      setTopicTitle('')
    } else {
      let selectedTopicsArr = []
      for (const [key, value] of Object.entries(topicSelections)) {
        if (value.length > 0) {
          selectedTopicsArr.push(key)
        }
      }
      if (!_.isEmpty(selectedTopicsArr)) {
        if (selectedTopicsArr.length > 1) {
          setTopicTitle(`Multiple`)
        } else {
          let startCasedTopic = _.startCase(selectedTopicsArr[0])
          if (startCasedTopic.includes(' And ')) {
            startCasedTopic = startCasedTopic.replace(' And ', ' & ')
          }
          if (topicSelections[selectedTopicsArr[0]].length === 1) {
            setTopicTitle(`${startCasedTopic} - ${topicSelections[selectedTopicsArr[0]][0].label}`)
          } else setTopicTitle(`${startCasedTopic}`)
        }
      } else setTopicTitle('')
      setIsFiltersActive(true)
    }
  }, [topicSelections])

  const filterDataCacheKey = 'filterData'
  const getFilterDataCache = () => {
    // if (filters.current) {
    //   return
    // } else {
    getFilterData().then((data) => {
      filters.current = data
      let newState = {
        ...state,
        sources: data.sources,
        stars: data.stars,
        topics: mapFilters(data.topics.data, 'topics'),
        selectedTopics: mapFilters(data.topics.data, 'topics'),
        classifications: mapFilters(data.classifications.data, 'classifications'),
        sizes: mapFilters(data.sizes.data, 'sizes'),
        attributes: mapFilters(data.attributes.data, 'attributes'),
        customer_attrs: mapFilters(data.customer_attrs.data, 'customer_attrs'),
        product_attrs: mapFilters(data.product_attrs.data, 'product_attrs'),
      }
      setState(newState)

      let expiration = new Date().getTime() + +process.env.REACT_APP_CACHE_EXPIRATION_MS
      Cache.setItem(filterDataCacheKey, newState, { expires: expiration })
      // console.log(Cache.getItem(filterDataCacheKey))
    })
    // }
  }

  const fisCacheKey = 'formattedFIs'
  const getFisCache = () => {
    filtersAPI.getFIs().then(({ data }) => {
      let formattedFIs = data.sort().map((fi) => ({
        value: fi,
        label: fi,
      }))
      setFIs(formattedFIs)

      let expiration = new Date().getTime() + +process.env.REACT_APP_CACHE_EXPIRATION_MS
      Cache.setItem(fisCacheKey, formattedFIs, { expires: expiration })
      // console.log(Cache.getItem(fisCacheKey))
    })
  }

  useEffect(() => {
    const cachedFilterData = Cache.getItem(filterDataCacheKey)
    const cachedFIs = Cache.getItem(fisCacheKey)

    if (cachedFilterData === null) {
      getFilterDataCache()
    } else {
      setState(cachedFilterData)
    }
    if (cachedFIs === null) {
      getFisCache()
    } else {
      setFIs(cachedFIs)
    }

    // Implementing the setInterval method
    const interval = setInterval(() => {
      getFilterDataCache()
      getFisCache()
    }, +process.env.REACT_APP_CACHE_EXPIRATION_MS)

    // Clearing the interval
    return () => clearInterval(interval)
  }, [])

  return (
    <FiltersContext.Provider
      value={{
        fis,
        setFis,
        isFiltersActive,
        setTopicSelections,
        isReviewsDrivers,
        setIsReviewsDrivers,
        topicTitle,
        setTopicTitle,
        sources,
        stars,
        classifications,
        sizes,
        topics,
        attributes,
        customer_attrs,
        product_attrs,
        customerServiceValues,
        feesAndIncentivesValues,
        productOfferingsValues,
        brandEquityValues,
        accessibilityValues,
        designAndFunctionalityValues,
        topicFilterValues,
        setTopicFilterValues,
        customerValues,
        productValues,
        attributeFilterValues,
        setAttributeFilterValues,
        toggleValues,
        setToggleValues,
        selectedTopics,
        setSelectedTopics,
        selectedFilterValue,
        setSelectedFilterValue,
        unsubmittedFilters,
        setUnsubmittedFilters,
        emptyFilterSelection,
        handleCheckedValues,
        FIs,
        setFIs,
        value,
        setValue,
        chosenFIs,
        setChosenFIs,
        fiTags,
        setFITags,
        filterSelection,
        setFilterSelection,
        tagSelection,
        setTagSelection,
        openPanels,
        setOpenPanels,
        isTrendsMentionRate,
        setIsTrendsMentionRate,
        sourcesValues,
        setSourcesValues,
        starsValues,
        setStarsValues,
        topicValues,
        setTopicValues,
        classificationValues,
        setClassificationValues,
        sizeValues,
        setSizeValues,
        timeValues,
        setTimeValues,
        timeFlag,
        setTimeFlag,
        yearRange,
        setYearRange,
        quarterRange,
        setQuarterRange,
        sliderValue,
        setSliderValue,
        mapTimesToObject,
        resetFilterData,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

FiltersProvider.propTypes = {
  children: PropTypes.any,
}
