import React, { useContext, useEffect, useRef, useState } from 'react'

// import { CSVLink } from 'react-csv'
import { QuestionCircleFilled } from '@ant-design/icons'
import { Card, Layout, Popover, Tabs } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { CheckUserInfo, Loading, PageHeader, ScorecardBarGraph, ScorecardTable } from '../../components'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import { scorecardAPI } from '../../api'

// import { changeScoredKey, formatFIs } from '../../common/utils/helpers'

const { Content } = Layout
// const { TabPane } = Tabs

export const ScorecardPage = ({ dataSet }) => {
  const dataBank = useRef(null)

  const {
    currentScorecardPage,
    isScorecardCallActive,
    setIsScorecardCallActive,
    // scorecardResp,
    scorecardData,
    setScorecardData,
    scoregraphData,
    setScoregraphData,
    isScorecardLoading,
    setIsScorecardLoading,
    // scorecardCsvData,
    // setScorecardCsvData,
  } = useContext(DataContext)
  const { chosenFIs, setChosenFIs, emptyFilterSelection, filterSelection } = useContext(FiltersContext)
  const { userInfo, getChosenFIs } = useContext(ThemeContext)
  const [totalscoregraph, setTotalscoregraph] = useState(0)
  const [loadingCheck, setLoadingCheck] = useState(false)
  const [scrollHeight, setScrollHeight] = useState(window.innerHeight - 0.25 * window.innerHeight - 163.86)

  const emptyFilterSelectionWithPath = { ...emptyFilterSelection, pathname: '/scorecard' }

  useEffect(() => {
    window.scrollTo(0, 0)

    function resizeHandler() {
      setScrollHeight(window.innerHeight - 0.25 * window.innerHeight - 163.86)
      setIsScorecardLoading(false)
    }
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const handleTabChange = () => {
    setIsScorecardLoading(true)
    if (loadingCheck) {
      setIsScorecardLoading(false)
    }
  }

  const checkUserInfo = async () => {
    const updatedChosenFIs = await getChosenFIs()
    if (!_.isEmpty(updatedChosenFIs)) {
      setChosenFIs(updatedChosenFIs)
      updateScorecardData(filterSelection, updatedChosenFIs)
    } else {
      setIsScorecardLoading(false)
    }
  }

  useEffect(() => {
    checkUserInfo()
  }, [userInfo])

  const updateScorecardData = (filterSelection, chosenFIs) => {
    if (!_.isEmpty(chosenFIs)) {
      const sentData = {
        filterSelection,
        chosenFIs,
      }
      setIsScorecardLoading(true)
      scorecardAPI
        .putScorecardFilterSelection(sentData)
        .then((response) => {
          if (!_.isEmpty(response)) {
            const { data, totalCount } = response
            setTotalscoregraph(totalCount)
            data.map((item, i) => {
              item.key = `${item._id}-${i}`
              item.fi = item._id
              item.img = `https://fi-ui-brand-logos.s3.amazonaws.com/${item._id.toLowerCase().replaceAll(' ', '-')}.png`
              item.PXI = item.pxi.toFixed(0)
              if (_.isEqual(filterSelection, emptyFilterSelection) || _.isEqual(filterSelection, emptyFilterSelectionWithPath)) {
                item.mentionRate = 'N/A'
              } else {
                item.mentionRate = item.mentionRate ? `${item.mentionRate.toFixed(2)}%` : '0%'
              }
              item.totalReviews = new Intl.NumberFormat().format(item.total)
            })
            setScorecardData(data)
            setScoregraphData(data)
            // setScorecardCsvData(changeScoredKey(data))
            setIsScorecardCallActive(false)
            if (data.length > 0) {
              setIsScorecardLoading(false)
              setLoadingCheck(true)
            }
          }
        })
        .catch((error) => {
          console.warn(error)
        })
    }
  }

  // general use useEffect, empty callback
  useEffect(() => {
    window.scrollTo(0, 0)
    if (dataBank.current) return
  }, [])

  // useEffect that listens for isScorecardCallActive
  useEffect(() => {
    if (!isScorecardCallActive && !_.isEmpty(scorecardData)) {
      // setFIs(formatFIs(scorecardResp))
      return
    }
    // setScorecardCsvData(changeScoredKey(landing))

    if (isScorecardCallActive) {
      if (!_.isEmpty(filterSelection) && !_.isEmpty(chosenFIs)) {
        // setTopicsFilterSelection(filterSelection)
        updateScorecardData(filterSelection, chosenFIs)
      }
    }
  }, [isScorecardCallActive, currentScorecardPage])

  const columns = [
    {
      title: 'Financial Institution',
      dataIndex: 'fi',
      key: 'fi',
      render: (text, record) => {
        const tooltipData = (
          <Card title={text} bordered={false} headStyle={{ padding: '0', textAlign: 'left', fontSize: '1.2em' }} bodyStyle={{ paddingBottom: '16px', textAlign: 'left', minWidth: '180px' }}>
            <img className="pxi-scorecard-logo" src={record.img} alt={`${record.fi} logo`} style={{ float: 'right' }} />
            <p>PXI: {record.PXI}</p>
            {/* <a href="/reviews">Reviews</a> uncomment when reviews filter properly */}
          </Card>
        )
        return (
          <Popover content={tooltipData} placement="topRight" trigger="hover">
            <span>{record.fi}</span>
          </Popover>
        )
      },
      // defaultSortOrder: 'ascend',
      sorter: (a, b) => a.fi.localeCompare(b.fi),
    },
    {
      title: () => {
        const data = (
          <div className="title-popover">
            PXI stands for Product Experience Indicator and it is calculated by taking the percent of positive reviews and subtracting from it the percent of negative reviews. This metric is useful in
            benchmarking against competition, in assessing performance over time, and in identifying risks and opportunities.
          </div>
        )
        return (
          <span>
            PXI{' '}
            <span>
              <Popover content={data} placement="topRight" title="PXI Definition">
                <QuestionCircleFilled style={{ fontSize: '0.6em', verticalAlign: 'text-top' }} />
              </Popover>
            </span>
          </span>
        )
      },
      dataIndex: 'PXI',
      key: 'PXI',
      sorter: (a, b) => a.PXI - b.PXI,
      onCell: ({ PXI }) => ({
        style: { color: PXI < 0 ? 'rgb(244, 127, 127)' : 'rgb(87, 222, 56)' },
      }),
    },
    {
      title: () => {
        const data = (
          <div className="title-popover">
            Topic Mention Rate is the percent of all reviews that contain at least one of the selected topics in the title or body of the review. If you have not selected any topics from the Filters
            then you will see the percentage of reviews that have at least one topic present.
          </div>
        )
        return (
          <span>
            Topic Mention Rate
            <span>
              <Popover content={data} placement="topRight" title="Topic Mention Rate Definition">
                <QuestionCircleFilled style={{ fontSize: '0.6em', verticalAlign: 'text-top' }} />
              </Popover>
            </span>
          </span>
        )
      },
      dataIndex: 'mentionRate',
      key: 'mentionRate',
      sorter: (a, b) => +a.mentionRate.split('%')[0] - +b.mentionRate.split('%')[0], // putting a "+" in front of a string is a shortcut for parseInt(str)
    },
    {
      title: 'Reviews',
      key: 'totalReviews',
      dataIndex: 'totalReviews',
      defaultSortOrder: 'descend',
      sorter: (a, b) => +a.totalReviews.replace(',', '') - +b.totalReviews.replace(',', ''), // putting a "+" in front of a string is a shortcut for parseInt(str)
    },
  ]

  const items = [
    {
      key: 'table',
      label: <span>Table</span>,
      children:
        scorecardData.length > 0 ? (
          <Card className="page-card align-items space-between" bordered={false}>
            <ScorecardTable key="scorecard-table" columns={columns} data={scorecardData} height={scrollHeight} isPagination={false} />{' '}
          </Card>
        ) : null,
    },
    {
      key: 'chart',
      label: <span>Bar Chart</span>,
      children: scoregraphData ? (
        <Card className="page-card align-items space-between" bordered={false}>
          <ScorecardBarGraph key="scorecard-bargraph" data={scoregraphData.sort((a, b) => a.fi.localeCompare(b.fi))} dataSet={dataSet} totalCount={totalscoregraph} imgHeight="25%" />
        </Card>
      ) : null,
    },
  ]
  return (
    <>
      <Content className="page-content">
        <Loading isLoading={isScorecardLoading}>
          <CheckUserInfo>
            <PageHeader
              title="Scorecard"
              description="A quick glance at each institution's PXI, percent of reviews with topics mentioned, and the total amount of reviews. Use the filters to drill down on data and use the table's column sorters for a new perspective."
            />
            <div className="link-charts-container">
              {/* <CSVLink className="csv-link" data={scorecardCsvData} filename="Scorecard.csv">
              Download CSV
            </CSVLink> */}
              {/* {<ScorecardPagination totalScorecards={totalScorecards} />} */}
              {scorecardData && (
                <Tabs defaultActiveKey="table" centered tabBarGutter={50} size="small" tabBarStyle={{ color: 'var(--text-heading)' }} onChange={handleTabChange} items={items}>
                  {/* <TabPane tab={<span>Table</span>} key="table">
                    {scorecardData.length > 0 ? <ScorecardTable key="scorecard-table" columns={columns} data={scorecardData} isPagination={false} /> : null}
                  </TabPane>
                  <TabPane tab={<span>Bar Chart</span>} key="chart">
                    {scoregraphData ? <ScorecardBarGraph key="scorecard-bargraph" data={scoregraphData} dataSet={dataSet} totalCount={totalscoregraph} imgHeight="25%" /> : null}
                  </TabPane> */}
                </Tabs>
              )}
            </div>
          </CheckUserInfo>
        </Loading>
      </Content>
    </>
  )
}

ScorecardPage.propTypes = {
  dataSet: PropTypes.array.isRequired,
}
