import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import 'antd/dist/antd.min.css'

import { EllipsisOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Divider, Dropdown, Layout, Menu } from 'antd'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import logo from '../../assets/hearful-logo-white.svg'
import { findWidth } from '../../common/utils/helpers'
import './navbardesign.css'

const { Header } = Layout

const navLinkTitles = [
  { label: 'Home', key: '' },
  { label: 'Scorecard', key: 'scorecard' },
  { label: 'Topics', key: 'topics' },
  {
    label: 'Trends',
    key: 'trendsmenu',
    children: [
      { label: 'PXI Trends', key: 'pxitrends' },
      { label: 'Mention Rate Trends', key: 'mentionratetrends' },
      { label: 'Trending Topics', key: 'trends' },
    ],
  },
  { label: 'Data Table', key: 'data' },
  { label: 'Reviews', key: 'reviews' },
  { label: 'Drivers', key: 'drivers' },
  // { label: 'Forums', key: 'forums' }, uncomment when forums page is set up
]

export const NavbarDesign = () => {
  const [width, setWidth] = useState(findWidth())
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const { setIsReviewsCallActive } = useContext(DataContext)
  const { setFis, resetFilterData } = useContext(FiltersContext)
  const { setUserInfo } = useContext(ThemeContext)
  const navigate = useNavigate()
  const location = useLocation()

  let navKey = location.pathname.split('/')[1]
  const [current, setCurrent] = useState(navKey)

  useEffect(() => {
    function resizeHandler() {
      setWidth(findWidth())
    }
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    navKey = location.pathname.split('/')[1]
    setCurrent(navKey)
  }, [location])

  const handleMenuItemClick = (event) => {
    resetFilterData()
    if (event.key === 'reviews') {
      setFis([])
      setIsReviewsCallActive(true)
    }
    setCurrent(event.key)
    // navigate(`/${value}`, { state: { prevPages: [{ pageTitle: 'Home', pagePath: '/' }] } })
    navigate(`/${event.key}`)
  }

  const handleLogoutClick = () => {
    // Remove saved username data from localStorage
    localStorage.removeItem('username')
    setUserInfo({})
    signOut()
    navigate('/login')
  }

  const menu = (
    <Menu
      style={{ padding: '10px 10px', borderRadius: '10px' }}
      className="username-menu"
      trigger={['click']}
      onClick={handleLogoutClick}
      items={[
        {
          key: 'logout',
          icon: <LogoutOutlined />,
          label: 'Logout',
        },
      ]}
    ></Menu>
  )

  return (
    <Layout className="layout">
      <Header className="navbar-design" role="navigation">
        <div className="logo-container">
          <Link className="logo" to="/" onClick={() => setCurrent(navKey)}>
            <img src={logo} alt="hearful logo" />
          </Link>
        </div>
        <div className="divider-container">
          <Divider type="vertical" className="straight-line" />
          <div className="flex-container">
            <div className="icons">
              <a href="/settings" className="nav-icon tooltip">
                <SettingOutlined />
                <span className="tooltiptext">Settings</span>
              </a>
            </div>
            <Dropdown className="dropdown" overlay={menu}>
              {width > 550 ? (
                <a className="nav-username" onClick={(e) => e.preventDefault()}>
                  {user?.attributes?.email}
                </a>
              ) : (
                <a className="nav-username-ellipsis" onClick={(e) => e.preventDefault()}>
                  <EllipsisOutlined />
                </a>
              )}
            </Dropdown>
          </div>
        </div>
        <div className="navbar-content">
          <Menu mode="horizontal" className="custom-menu" selectedKeys={[current]} items={navLinkTitles} onClick={handleMenuItemClick} />
        </div>
      </Header>
    </Layout>
  )
}
