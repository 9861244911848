import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Layout } from 'antd'
import _ from 'lodash'

import { HomePageLinkRow, NavbarDesign } from '../../components'

import { ThemeContext } from '../../providers'

import homepng from '../../assets/bg_design_32124_transparent.png'
import datatableUrl from '../../assets/data_table.png'
import driverUrl from '../../assets/drivers.png'
import reviewUrl from '../../assets/reviews.png'
import scorecardUrl from '../../assets/scorecard.png'
import topicUrl from '../../assets/topics.png'
import trendUrl from '../../assets/trends.png'
import { findHeight, findWidth } from '../../common/utils/helpers'
import './home-page.css'

const { Content } = Layout

const imageUrls = [topicUrl, trendUrl, datatableUrl, scorecardUrl, reviewUrl, driverUrl]
const urlValues = ['topics', 'pxitrends', 'data', 'scorecard', 'reviews', 'drivers']

export const HomePage = () => {
  const { userInfo } = useContext(ThemeContext)
  const navigate = useNavigate()
  const [height, setHeight] = useState(findHeight())
  const [width, setWidth] = useState(findWidth())

  const handleImageClick = (index) => {
    let urlValue = urlValues[index]
    navigate(`/${urlValue}`, { state: { prevPages: [{ pageTitle: 'Home', pagePath: '/' }] } })
  }

  useEffect(() => {
    function resizeHandler() {
      setHeight(findHeight())
      setWidth(findWidth())
    }
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(userInfo) && _.isEmpty(userInfo?.competitiveSet)) {
      navigate('/settings')
    }
  }, [userInfo])

  const handleHomepageLinks = () => {
    let rowNumbersArray = []
    if (width > height * 1.2) {
      rowNumbersArray = [1, 2]
    } else if (width > height * 0.8) {
      rowNumbersArray = [1, 2, 3]
    } else {
      rowNumbersArray = [1, 2, 3, 4, 5, 6]
    }
    return rowNumbersArray.map((rowNumber, index) => (
      <HomePageLinkRow key={`${urlValues[index]}-${index}`} imageUrls={imageUrls} handleImageClick={handleImageClick} numberRows={rowNumbersArray.length} rowNumber={rowNumber} urlValues={urlValues} />
    ))
  }

  return (
    <div className="homepage-container">
      <NavbarDesign />
      <Content className="homepage-content">
        <div className="background-container">
          <img className="background" src={homepng} alt="home" />
          <div className="text-overlay">
            <h1 className="h1-text">
              Insights from
              <br />
              Customer Reviews.
            </h1>
          </div>
        </div>
        <div className="homepage-links-container">{handleHomepageLinks()}</div>
      </Content>
    </div>
  )
}
