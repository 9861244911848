export const landing = [
  {
    _id: 'BB&T',
    aggData: {
      '2018Q1Total': 52,
      '2018Q1Pos': 45,
      '2018Q1Neg': 3,
      '2018Q2Total': 32,
      '2018Q2Pos': 29,
      '2018Q2Neg': 3,
      '2018Q3Total': 49,
      '2018Q3Pos': 38,
      '2018Q3Neg': 5,
      '2018Q4Total': 34,
      '2018Q4Pos': 28,
      '2018Q4Neg': 4,
      '2019Q1Total': 44,
      '2019Q1Pos': 34,
      '2019Q1Neg': 7,
      '2019Q2Total': 37,
      '2019Q2Pos': 24,
      '2019Q2Neg': 6,
      '2019Q3Total': 34,
      '2019Q3Pos': 22,
      '2019Q3Neg': 3,
      '2019Q4Total': 38,
      '2019Q4Pos': 27,
      '2019Q4Neg': 9,
      '2020Q1Total': 55,
      '2020Q1Pos': 36,
      '2020Q1Neg': 10,
      '2020Q2Total': 27,
      '2020Q2Pos': 20,
      '2020Q2Neg': 2,
      '2020Q3Total': 18,
      '2020Q3Pos': 9,
      '2020Q3Neg': 8,
      '2020Q4Total': 28,
      '2020Q4Pos': 17,
      '2020Q4Neg': 7,
      '2021Q1Total': 32,
      '2021Q1Pos': 22,
      '2021Q1Neg': 5,
      '2021Q2Total': 24,
      '2021Q2Pos': 15,
      '2021Q2Neg': 7,
      '2021Q3Total': 25,
      '2021Q3Pos': 18,
      '2021Q3Neg': 4,
      '2021Q4Total': 33,
      '2021Q4Pos': 17,
      '2021Q4Neg': 11,
      '2022Q1Total': 18,
      '2022Q1Pos': 11,
      '2022Q1Neg': 5,
      '2022Q2Total': 7,
      '2022Q2Pos': 4,
      '2022Q2Neg': 2,
      '2022Q3Total': 10,
      '2022Q3Pos': 5,
      '2022Q3Neg': 3,
      '2022Q4Total': 21,
      '2022Q4Pos': 12,
      '2022Q4Neg': 7,
      '2023Q1Total': 55,
      '2023Q1Pos': 22,
      '2023Q1Neg': 11,
      '2023Q2Total': 33,
      '2023Q2Pos': 11,
      '2023Q2Neg': 10,
      '2023Q3Total': 77,
      '2023Q3Pos': 33,
      '2023Q3Neg': 22,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
      '2023Q1UnclassTotal': 66,
      '2023Q1UnclassPos': 22,
      '2023Q1UnclassNeg': 17,
      '2023Q2UnclassTotal': 25,
      '2023Q2UnclassPos': 10,
      '2023Q2UnclassNeg': 11,
      '2023Q3UnclassTotal': 26,
      '2023Q3UnclassPos': 11,
      '2023Q3UnclassNeg': 13,
    },
    cuData: {
      '2018Q1CUTotal': 52,
      '2018Q1CUPos': 45,
      '2018Q1CUNeg': 3,
      '2018Q2CUTotal': 32,
      '2018Q2CUPos': 29,
      '2018Q2CUNeg': 3,
      '2018Q3CUTotal': 49,
      '2018Q3CUPos': 38,
      '2018Q3CUNeg': 5,
      '2018Q4CUTotal': 34,
      '2018Q4CUPos': 28,
      '2018Q4CUNeg': 4,
      '2019Q1CUTotal': 44,
      '2019Q1CUPos': 34,
      '2019Q1CUNeg': 7,
      '2019Q2CUTotal': 37,
      '2019Q2CUPos': 24,
      '2019Q2CUNeg': 6,
      '2019Q3CUTotal': 34,
      '2019Q3CUPos': 22,
      '2019Q3CUNeg': 3,
      '2019Q4CUTotal': 38,
      '2019Q4CUPos': 27,
      '2019Q4CUNeg': 9,
      '2020Q1CUTotal': 55,
      '2020Q1CUPos': 36,
      '2020Q1CUNeg': 10,
      '2020Q2CUTotal': 27,
      '2020Q2CUPos': 20,
      '2020Q2CUNeg': 2,
      '2020Q3CUTotal': 18,
      '2020Q3CUPos': 9,
      '2020Q3CUNeg': 8,
      '2020Q4CUTotal': 28,
      '2020Q4CUPos': 17,
      '2020Q4CUNeg': 7,
      '2021Q1CUTotal': 32,
      '2021Q1CUPos': 22,
      '2021Q1CUNeg': 5,
      '2021Q2CUTotal': 24,
      '2021Q2CUPos': 15,
      '2021Q2CUNeg': 7,
      '2021Q3CUTotal': 25,
      '2021Q3CUPos': 18,
      '2021Q3CUNeg': 4,
      '2021Q4CUTotal': 33,
      '2021Q4CUPos': 17,
      '2021Q4CUNeg': 11,
      '2022Q1CUTotal': 18,
      '2022Q1CUPos': 11,
      '2022Q1CUNeg': 5,
      '2022Q2CUTotal': 7,
      '2022Q2CUPos': 4,
      '2022Q2CUNeg': 2,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 0,
      '2021Q2BankPos': 0,
      '2021Q2BankNeg': 0,
      '2021Q3BankTotal': 0,
      '2021Q3BankPos': 0,
      '2021Q3BankNeg': 0,
      '2021Q4BankTotal': 0,
      '2021Q4BankPos': 0,
      '2021Q4BankNeg': 0,
      '2022Q1BankTotal': 0,
      '2022Q1BankPos': 0,
      '2022Q1BankNeg': 0,
      '2022Q2BankTotal': 0,
      '2022Q2BankPos': 0,
      '2022Q2BankNeg': 0,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'HSBC',
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 0,
      '2019Q1Pos': 0,
      '2019Q1Neg': 0,
      '2019Q2Total': 25,
      '2019Q2Pos': 9,
      '2019Q2Neg': 16,
      '2019Q3Total': 193,
      '2019Q3Pos': 23,
      '2019Q3Neg': 158,
      '2019Q4Total': 184,
      '2019Q4Pos': 18,
      '2019Q4Neg': 155,
      '2020Q1Total': 226,
      '2020Q1Pos': 12,
      '2020Q1Neg': 212,
      '2020Q2Total': 240,
      '2020Q2Pos': 58,
      '2020Q2Neg': 167,
      '2020Q3Total': 154,
      '2020Q3Pos': 31,
      '2020Q3Neg': 120,
      '2020Q4Total': 259,
      '2020Q4Pos': 54,
      '2020Q4Neg': 183,
      '2021Q1Total': 166,
      '2021Q1Pos': 58,
      '2021Q1Neg': 95,
      '2021Q2Total': 193,
      '2021Q2Pos': 57,
      '2021Q2Neg': 116,
      '2021Q3Total': 72,
      '2021Q3Pos': 30,
      '2021Q3Neg': 37,
      '2021Q4Total': 161,
      '2021Q4Pos': 44,
      '2021Q4Neg': 100,
      '2022Q1Total': 67,
      '2022Q1Pos': 19,
      '2022Q1Neg': 42,
      '2022Q2Total': 60,
      '2022Q2Pos': 9,
      '2022Q2Neg': 44,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 25,
      '2019Q2BankPos': 9,
      '2019Q2BankNeg': 16,
      '2019Q3BankTotal': 193,
      '2019Q3BankPos': 23,
      '2019Q3BankNeg': 158,
      '2019Q4BankTotal': 184,
      '2019Q4BankPos': 18,
      '2019Q4BankNeg': 155,
      '2020Q1BankTotal': 226,
      '2020Q1BankPos': 12,
      '2020Q1BankNeg': 212,
      '2020Q2BankTotal': 240,
      '2020Q2BankPos': 58,
      '2020Q2BankNeg': 167,
      '2020Q3BankTotal': 154,
      '2020Q3BankPos': 31,
      '2020Q3BankNeg': 120,
      '2020Q4BankTotal': 259,
      '2020Q4BankPos': 54,
      '2020Q4BankNeg': 183,
      '2021Q1BankTotal': 166,
      '2021Q1BankPos': 58,
      '2021Q1BankNeg': 95,
      '2021Q2BankTotal': 193,
      '2021Q2BankPos': 57,
      '2021Q2BankNeg': 116,
      '2021Q3BankTotal': 72,
      '2021Q3BankPos': 30,
      '2021Q3BankNeg': 37,
      '2021Q4BankTotal': 161,
      '2021Q4BankPos': 44,
      '2021Q4BankNeg': 100,
      '2022Q1BankTotal': 67,
      '2022Q1BankPos': 19,
      '2022Q1BankNeg': 42,
      '2022Q2BankTotal': 60,
      '2022Q2BankPos': 9,
      '2022Q2BankNeg': 44,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'Charles Schwab Bank',
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 0,
      '2019Q1Pos': 0,
      '2019Q1Neg': 0,
      '2019Q2Total': 0,
      '2019Q2Pos': 0,
      '2019Q2Neg': 0,
      '2019Q3Total': 0,
      '2019Q3Pos': 0,
      '2019Q3Neg': 0,
      '2019Q4Total': 0,
      '2019Q4Pos': 0,
      '2019Q4Neg': 0,
      '2020Q1Total': 0,
      '2020Q1Pos': 0,
      '2020Q1Neg': 0,
      '2020Q2Total': 0,
      '2020Q2Pos': 0,
      '2020Q2Neg': 0,
      '2020Q3Total': 0,
      '2020Q3Pos': 0,
      '2020Q3Neg': 0,
      '2020Q4Total': 0,
      '2020Q4Pos': 0,
      '2020Q4Neg': 0,
      '2021Q1Total': 0,
      '2021Q1Pos': 0,
      '2021Q1Neg': 0,
      '2021Q2Total': 110,
      '2021Q2Pos': 20,
      '2021Q2Neg': 85,
      '2021Q3Total': 660,
      '2021Q3Pos': 269,
      '2021Q3Neg': 339,
      '2021Q4Total': 581,
      '2021Q4Pos': 271,
      '2021Q4Neg': 243,
      '2022Q1Total': 391,
      '2022Q1Pos': 164,
      '2022Q1Neg': 196,
      '2022Q2Total': 258,
      '2022Q2Pos': 85,
      '2022Q2Neg': 151,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 110,
      '2021Q2BankPos': 20,
      '2021Q2BankNeg': 85,
      '2021Q3BankTotal': 660,
      '2021Q3BankPos': 269,
      '2021Q3BankNeg': 339,
      '2021Q4BankTotal': 581,
      '2021Q4BankPos': 271,
      '2021Q4BankNeg': 243,
      '2022Q1BankTotal': 391,
      '2022Q1BankPos': 164,
      '2022Q1BankNeg': 196,
      '2022Q2BankTotal': 258,
      '2022Q2BankPos': 85,
      '2022Q2BankNeg': 151,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'Coastal Credit Union',
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 0,
      '2019Q1Pos': 0,
      '2019Q1Neg': 0,
      '2019Q2Total': 0,
      '2019Q2Pos': 0,
      '2019Q2Neg': 0,
      '2019Q3Total': 0,
      '2019Q3Pos': 0,
      '2019Q3Neg': 0,
      '2019Q4Total': 0,
      '2019Q4Pos': 0,
      '2019Q4Neg': 0,
      '2020Q1Total': 0,
      '2020Q1Pos': 0,
      '2020Q1Neg': 0,
      '2020Q2Total': 0,
      '2020Q2Pos': 0,
      '2020Q2Neg': 0,
      '2020Q3Total': 0,
      '2020Q3Pos': 0,
      '2020Q3Neg': 0,
      '2020Q4Total': 0,
      '2020Q4Pos': 0,
      '2020Q4Neg': 0,
      '2021Q1Total': 0,
      '2021Q1Pos': 0,
      '2021Q1Neg': 0,
      '2021Q2Total': 0,
      '2021Q2Pos': 0,
      '2021Q2Neg': 0,
      '2021Q3Total': 0,
      '2021Q3Pos': 0,
      '2021Q3Neg': 0,
      '2021Q4Total': 0,
      '2021Q4Pos': 0,
      '2021Q4Neg': 0,
      '2022Q1Total': 1305,
      '2022Q1Pos': 843,
      '2022Q1Neg': 349,
      '2022Q2Total': 695,
      '2022Q2Pos': 411,
      '2022Q2Neg': 219,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 0,
      '2021Q2BankPos': 0,
      '2021Q2BankNeg': 0,
      '2021Q3BankTotal': 0,
      '2021Q3BankPos': 0,
      '2021Q3BankNeg': 0,
      '2021Q4BankTotal': 0,
      '2021Q4BankPos': 0,
      '2021Q4BankNeg': 0,
      '2022Q1BankTotal': 1305,
      '2022Q1BankPos': 843,
      '2022Q1BankNeg': 349,
      '2022Q2BankTotal': 695,
      '2022Q2BankPos': 411,
      '2022Q2BankNeg': 219,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'Fifth Third Bank',
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 177,
      '2019Q1Pos': 111,
      '2019Q1Neg': 47,
      '2019Q2Total': 265,
      '2019Q2Pos': 142,
      '2019Q2Neg': 93,
      '2019Q3Total': 101,
      '2019Q3Pos': 18,
      '2019Q3Neg': 80,
      '2019Q4Total': 223,
      '2019Q4Pos': 138,
      '2019Q4Neg': 63,
      '2020Q1Total': 51,
      '2020Q1Pos': 14,
      '2020Q1Neg': 33,
      '2020Q2Total': 72,
      '2020Q2Pos': 19,
      '2020Q2Neg': 48,
      '2020Q3Total': 45,
      '2020Q3Pos': 14,
      '2020Q3Neg': 28,
      '2020Q4Total': 253,
      '2020Q4Pos': 125,
      '2020Q4Neg': 109,
      '2021Q1Total': 180,
      '2021Q1Pos': 62,
      '2021Q1Neg': 108,
      '2021Q2Total': 102,
      '2021Q2Pos': 16,
      '2021Q2Neg': 82,
      '2021Q3Total': 74,
      '2021Q3Pos': 18,
      '2021Q3Neg': 52,
      '2021Q4Total': 162,
      '2021Q4Pos': 30,
      '2021Q4Neg': 121,
      '2022Q1Total': 125,
      '2022Q1Pos': 50,
      '2022Q1Neg': 66,
      '2022Q2Total': 170,
      '2022Q2Pos': 65,
      '2022Q2Neg': 92,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 177,
      '2019Q1BankPos': 111,
      '2019Q1BankNeg': 47,
      '2019Q2BankTotal': 265,
      '2019Q2BankPos': 142,
      '2019Q2BankNeg': 93,
      '2019Q3BankTotal': 101,
      '2019Q3BankPos': 18,
      '2019Q3BankNeg': 80,
      '2019Q4BankTotal': 223,
      '2019Q4BankPos': 138,
      '2019Q4BankNeg': 63,
      '2020Q1BankTotal': 51,
      '2020Q1BankPos': 14,
      '2020Q1BankNeg': 33,
      '2020Q2BankTotal': 72,
      '2020Q2BankPos': 19,
      '2020Q2BankNeg': 48,
      '2020Q3BankTotal': 45,
      '2020Q3BankPos': 14,
      '2020Q3BankNeg': 28,
      '2020Q4BankTotal': 253,
      '2020Q4BankPos': 125,
      '2020Q4BankNeg': 109,
      '2021Q1BankTotal': 180,
      '2021Q1BankPos': 62,
      '2021Q1BankNeg': 108,
      '2021Q2BankTotal': 102,
      '2021Q2BankPos': 16,
      '2021Q2BankNeg': 82,
      '2021Q3BankTotal': 74,
      '2021Q3BankPos': 18,
      '2021Q3BankNeg': 52,
      '2021Q4BankTotal': 162,
      '2021Q4BankPos': 30,
      '2021Q4BankNeg': 121,
      '2022Q1BankTotal': 125,
      '2022Q1BankPos': 50,
      '2022Q1BankNeg': 66,
      '2022Q2BankTotal': 170,
      '2022Q2BankPos': 65,
      '2022Q2BankNeg': 92,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'First Citizens Bank',
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 0,
      '2019Q1Pos': 0,
      '2019Q1Neg': 0,
      '2019Q2Total': 0,
      '2019Q2Pos': 0,
      '2019Q2Neg': 0,
      '2019Q3Total': 0,
      '2019Q3Pos': 0,
      '2019Q3Neg': 0,
      '2019Q4Total': 0,
      '2019Q4Pos': 0,
      '2019Q4Neg': 0,
      '2020Q1Total': 0,
      '2020Q1Pos': 0,
      '2020Q1Neg': 0,
      '2020Q2Total': 1,
      '2020Q2Pos': 1,
      '2020Q2Neg': 0,
      '2020Q3Total': 18,
      '2020Q3Pos': 15,
      '2020Q3Neg': 1,
      '2020Q4Total': 367,
      '2020Q4Pos': 161,
      '2020Q4Neg': 168,
      '2021Q1Total': 265,
      '2021Q1Pos': 65,
      '2021Q1Neg': 171,
      '2021Q2Total': 142,
      '2021Q2Pos': 37,
      '2021Q2Neg': 96,
      '2021Q3Total': 353,
      '2021Q3Pos': 199,
      '2021Q3Neg': 111,
      '2021Q4Total': 318,
      '2021Q4Pos': 160,
      '2021Q4Neg': 118,
      '2022Q1Total': 285,
      '2022Q1Pos': 121,
      '2022Q1Neg': 134,
      '2022Q2Total': 251,
      '2022Q2Pos': 129,
      '2022Q2Neg': 91,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 0,
      '2021Q2BankPos': 0,
      '2021Q2BankNeg': 0,
      '2021Q3BankTotal': 0,
      '2021Q3BankPos': 0,
      '2021Q3BankNeg': 0,
      '2021Q4BankTotal': 0,
      '2021Q4BankPos': 0,
      '2021Q4BankNeg': 0,
      '2022Q1BankTotal': 0,
      '2022Q1BankPos': 0,
      '2022Q1BankNeg': 0,
      '2022Q2BankTotal': 0,
      '2022Q2BankPos': 0,
      '2022Q2BankNeg': 0,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 1,
      '2020Q2DigPos': 1,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 18,
      '2020Q3DigPos': 15,
      '2020Q3DigNeg': 1,
      '2020Q4DigTotal': 367,
      '2020Q4DigPos': 161,
      '2020Q4DigNeg': 168,
      '2021Q1DigTotal': 265,
      '2021Q1DigPos': 65,
      '2021Q1DigNeg': 171,
      '2021Q2DigTotal': 142,
      '2021Q2DigPos': 37,
      '2021Q2DigNeg': 96,
      '2021Q3DigTotal': 353,
      '2021Q3DigPos': 199,
      '2021Q3DigNeg': 111,
      '2021Q4DigTotal': 318,
      '2021Q4DigPos': 160,
      '2021Q4DigNeg': 118,
      '2022Q1DigTotal': 285,
      '2022Q1DigPos': 121,
      '2022Q1DigNeg': 134,
      '2022Q2DigTotal': 251,
      '2022Q2DigPos': 129,
      '2022Q2DigNeg': 91,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'First National Bank Texas',
    aggData: {
      '2018Q1Total': 15,
      '2018Q1Pos': 7,
      '2018Q1Neg': 6,
      '2018Q2Total': 17,
      '2018Q2Pos': 8,
      '2018Q2Neg': 5,
      '2018Q3Total': 31,
      '2018Q3Pos': 17,
      '2018Q3Neg': 10,
      '2018Q4Total': 23,
      '2018Q4Pos': 16,
      '2018Q4Neg': 6,
      '2019Q1Total': 25,
      '2019Q1Pos': 15,
      '2019Q1Neg': 4,
      '2019Q2Total': 34,
      '2019Q2Pos': 29,
      '2019Q2Neg': 1,
      '2019Q3Total': 33,
      '2019Q3Pos': 23,
      '2019Q3Neg': 6,
      '2019Q4Total': 33,
      '2019Q4Pos': 26,
      '2019Q4Neg': 2,
      '2020Q1Total': 36,
      '2020Q1Pos': 25,
      '2020Q1Neg': 7,
      '2020Q2Total': 32,
      '2020Q2Pos': 21,
      '2020Q2Neg': 6,
      '2020Q3Total': 37,
      '2020Q3Pos': 28,
      '2020Q3Neg': 4,
      '2020Q4Total': 27,
      '2020Q4Pos': 22,
      '2020Q4Neg': 4,
      '2021Q1Total': 31,
      '2021Q1Pos': 17,
      '2021Q1Neg': 8,
      '2021Q2Total': 81,
      '2021Q2Pos': 52,
      '2021Q2Neg': 20,
      '2021Q3Total': 94,
      '2021Q3Pos': 68,
      '2021Q3Neg': 19,
      '2021Q4Total': 81,
      '2021Q4Pos': 65,
      '2021Q4Neg': 9,
      '2022Q1Total': 639,
      '2022Q1Pos': 69,
      '2022Q1Neg': 549,
      '2022Q2Total': 265,
      '2022Q2Pos': 59,
      '2022Q2Neg': 195,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 15,
      '2018Q1CUPos': 7,
      '2018Q1CUNeg': 6,
      '2018Q2CUTotal': 17,
      '2018Q2CUPos': 8,
      '2018Q2CUNeg': 5,
      '2018Q3CUTotal': 31,
      '2018Q3CUPos': 17,
      '2018Q3CUNeg': 10,
      '2018Q4CUTotal': 23,
      '2018Q4CUPos': 16,
      '2018Q4CUNeg': 6,
      '2019Q1CUTotal': 25,
      '2019Q1CUPos': 15,
      '2019Q1CUNeg': 4,
      '2019Q2CUTotal': 34,
      '2019Q2CUPos': 29,
      '2019Q2CUNeg': 1,
      '2019Q3CUTotal': 33,
      '2019Q3CUPos': 23,
      '2019Q3CUNeg': 6,
      '2019Q4CUTotal': 33,
      '2019Q4CUPos': 26,
      '2019Q4CUNeg': 2,
      '2020Q1CUTotal': 36,
      '2020Q1CUPos': 25,
      '2020Q1CUNeg': 7,
      '2020Q2CUTotal': 32,
      '2020Q2CUPos': 21,
      '2020Q2CUNeg': 6,
      '2020Q3CUTotal': 37,
      '2020Q3CUPos': 28,
      '2020Q3CUNeg': 4,
      '2020Q4CUTotal': 27,
      '2020Q4CUPos': 22,
      '2020Q4CUNeg': 4,
      '2021Q1CUTotal': 31,
      '2021Q1CUPos': 17,
      '2021Q1CUNeg': 8,
      '2021Q2CUTotal': 81,
      '2021Q2CUPos': 52,
      '2021Q2CUNeg': 20,
      '2021Q3CUTotal': 94,
      '2021Q3CUPos': 68,
      '2021Q3CUNeg': 19,
      '2021Q4CUTotal': 81,
      '2021Q4CUPos': 65,
      '2021Q4CUNeg': 9,
      '2022Q1CUTotal': 639,
      '2022Q1CUPos': 69,
      '2022Q1CUNeg': 549,
      '2022Q2CUTotal': 265,
      '2022Q2CUPos': 59,
      '2022Q2CUNeg': 195,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 0,
      '2021Q2BankPos': 0,
      '2021Q2BankNeg': 0,
      '2021Q3BankTotal': 0,
      '2021Q3BankPos': 0,
      '2021Q3BankNeg': 0,
      '2021Q4BankTotal': 0,
      '2021Q4BankPos': 0,
      '2021Q4BankNeg': 0,
      '2022Q1BankTotal': 0,
      '2022Q1BankPos': 0,
      '2022Q1BankNeg': 0,
      '2022Q2BankTotal': 0,
      '2022Q2BankPos': 0,
      '2022Q2BankNeg': 0,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'Flagstar Bank',
    aggData: {
      '2018Q1Total': 13,
      '2018Q1Pos': 3,
      '2018Q1Neg': 10,
      '2018Q2Total': 20,
      '2018Q2Pos': 11,
      '2018Q2Neg': 4,
      '2018Q3Total': 12,
      '2018Q3Pos': 5,
      '2018Q3Neg': 7,
      '2018Q4Total': 18,
      '2018Q4Pos': 9,
      '2018Q4Neg': 9,
      '2019Q1Total': 66,
      '2019Q1Pos': 34,
      '2019Q1Neg': 28,
      '2019Q2Total': 56,
      '2019Q2Pos': 15,
      '2019Q2Neg': 37,
      '2019Q3Total': 91,
      '2019Q3Pos': 37,
      '2019Q3Neg': 51,
      '2019Q4Total': 151,
      '2019Q4Pos': 29,
      '2019Q4Neg': 115,
      '2020Q1Total': 143,
      '2020Q1Pos': 64,
      '2020Q1Neg': 75,
      '2020Q2Total': 162,
      '2020Q2Pos': 71,
      '2020Q2Neg': 85,
      '2020Q3Total': 66,
      '2020Q3Pos': 28,
      '2020Q3Neg': 33,
      '2020Q4Total': 77,
      '2020Q4Pos': 42,
      '2020Q4Neg': 31,
      '2021Q1Total': 124,
      '2021Q1Pos': 40,
      '2021Q1Neg': 79,
      '2021Q2Total': 114,
      '2021Q2Pos': 60,
      '2021Q2Neg': 49,
      '2021Q3Total': 79,
      '2021Q3Pos': 27,
      '2021Q3Neg': 42,
      '2021Q4Total': 157,
      '2021Q4Pos': 72,
      '2021Q4Neg': 74,
      '2022Q1Total': 141,
      '2022Q1Pos': 80,
      '2022Q1Neg': 54,
      '2022Q2Total': 340,
      '2022Q2Pos': 251,
      '2022Q2Neg': 55,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 13,
      '2018Q1BankPos': 3,
      '2018Q1BankNeg': 10,
      '2018Q2BankTotal': 20,
      '2018Q2BankPos': 11,
      '2018Q2BankNeg': 4,
      '2018Q3BankTotal': 12,
      '2018Q3BankPos': 5,
      '2018Q3BankNeg': 7,
      '2018Q4BankTotal': 18,
      '2018Q4BankPos': 9,
      '2018Q4BankNeg': 9,
      '2019Q1BankTotal': 66,
      '2019Q1BankPos': 34,
      '2019Q1BankNeg': 28,
      '2019Q2BankTotal': 56,
      '2019Q2BankPos': 15,
      '2019Q2BankNeg': 37,
      '2019Q3BankTotal': 91,
      '2019Q3BankPos': 37,
      '2019Q3BankNeg': 51,
      '2019Q4BankTotal': 151,
      '2019Q4BankPos': 29,
      '2019Q4BankNeg': 115,
      '2020Q1BankTotal': 143,
      '2020Q1BankPos': 64,
      '2020Q1BankNeg': 75,
      '2020Q2BankTotal': 162,
      '2020Q2BankPos': 71,
      '2020Q2BankNeg': 85,
      '2020Q3BankTotal': 66,
      '2020Q3BankPos': 28,
      '2020Q3BankNeg': 33,
      '2020Q4BankTotal': 77,
      '2020Q4BankPos': 42,
      '2020Q4BankNeg': 31,
      '2021Q1BankTotal': 124,
      '2021Q1BankPos': 40,
      '2021Q1BankNeg': 79,
      '2021Q2BankTotal': 114,
      '2021Q2BankPos': 60,
      '2021Q2BankNeg': 49,
      '2021Q3BankTotal': 79,
      '2021Q3BankPos': 27,
      '2021Q3BankNeg': 42,
      '2021Q4BankTotal': 157,
      '2021Q4BankPos': 72,
      '2021Q4BankNeg': 74,
      '2022Q1BankTotal': 141,
      '2022Q1BankPos': 80,
      '2022Q1BankNeg': 54,
      '2022Q2BankTotal': 340,
      '2022Q2BankPos': 251,
      '2022Q2BankNeg': 55,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: "NC State Employees' Credit Union",
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 0,
      '2019Q1Pos': 0,
      '2019Q1Neg': 0,
      '2019Q2Total': 0,
      '2019Q2Pos': 0,
      '2019Q2Neg': 0,
      '2019Q3Total': 0,
      '2019Q3Pos': 0,
      '2019Q3Neg': 0,
      '2019Q4Total': 0,
      '2019Q4Pos': 0,
      '2019Q4Neg': 0,
      '2020Q1Total': 0,
      '2020Q1Pos': 0,
      '2020Q1Neg': 0,
      '2020Q2Total': 0,
      '2020Q2Pos': 0,
      '2020Q2Neg': 0,
      '2020Q3Total': 0,
      '2020Q3Pos': 0,
      '2020Q3Neg': 0,
      '2020Q4Total': 0,
      '2020Q4Pos': 0,
      '2020Q4Neg': 0,
      '2021Q1Total': 0,
      '2021Q1Pos': 0,
      '2021Q1Neg': 0,
      '2021Q2Total': 0,
      '2021Q2Pos': 0,
      '2021Q2Neg': 0,
      '2021Q3Total': 0,
      '2021Q3Pos': 0,
      '2021Q3Neg': 0,
      '2021Q4Total': 577,
      '2021Q4Pos': 467,
      '2021Q4Neg': 63,
      '2022Q1Total': 701,
      '2022Q1Pos': 562,
      '2022Q1Neg': 78,
      '2022Q2Total': 722,
      '2022Q2Pos': 532,
      '2022Q2Neg': 129,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 0,
      '2021Q2BankPos': 0,
      '2021Q2BankNeg': 0,
      '2021Q3BankTotal': 0,
      '2021Q3BankPos': 0,
      '2021Q3BankNeg': 0,
      '2021Q4BankTotal': 577,
      '2021Q4BankPos': 467,
      '2021Q4BankNeg': 63,
      '2022Q1BankTotal': 701,
      '2022Q1BankPos': 562,
      '2022Q1BankNeg': 78,
      '2022Q2BankTotal': 722,
      '2022Q2BankPos': 532,
      '2022Q2BankNeg': 129,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'RBC',
    aggData: {
      '2018Q1Total': 46,
      '2018Q1Pos': 35,
      '2018Q1Neg': 5,
      '2018Q2Total': 52,
      '2018Q2Pos': 37,
      '2018Q2Neg': 7,
      '2018Q3Total': 70,
      '2018Q3Pos': 47,
      '2018Q3Neg': 16,
      '2018Q4Total': 76,
      '2018Q4Pos': 58,
      '2018Q4Neg': 10,
      '2019Q1Total': 69,
      '2019Q1Pos': 53,
      '2019Q1Neg': 6,
      '2019Q2Total': 69,
      '2019Q2Pos': 48,
      '2019Q2Neg': 6,
      '2019Q3Total': 104,
      '2019Q3Pos': 77,
      '2019Q3Neg': 11,
      '2019Q4Total': 66,
      '2019Q4Pos': 44,
      '2019Q4Neg': 15,
      '2020Q1Total': 71,
      '2020Q1Pos': 49,
      '2020Q1Neg': 12,
      '2020Q2Total': 106,
      '2020Q2Pos': 81,
      '2020Q2Neg': 15,
      '2020Q3Total': 64,
      '2020Q3Pos': 53,
      '2020Q3Neg': 3,
      '2020Q4Total': 74,
      '2020Q4Pos': 56,
      '2020Q4Neg': 10,
      '2021Q1Total': 43,
      '2021Q1Pos': 30,
      '2021Q1Neg': 6,
      '2021Q2Total': 25,
      '2021Q2Pos': 18,
      '2021Q2Neg': 2,
      '2021Q3Total': 73,
      '2021Q3Pos': 41,
      '2021Q3Neg': 23,
      '2021Q4Total': 53,
      '2021Q4Pos': 44,
      '2021Q4Neg': 5,
      '2022Q1Total': 47,
      '2022Q1Pos': 38,
      '2022Q1Neg': 8,
      '2022Q2Total': 38,
      '2022Q2Pos': 33,
      '2022Q2Neg': 4,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 46,
      '2018Q1CUPos': 35,
      '2018Q1CUNeg': 5,
      '2018Q2CUTotal': 52,
      '2018Q2CUPos': 37,
      '2018Q2CUNeg': 7,
      '2018Q3CUTotal': 70,
      '2018Q3CUPos': 47,
      '2018Q3CUNeg': 16,
      '2018Q4CUTotal': 76,
      '2018Q4CUPos': 58,
      '2018Q4CUNeg': 10,
      '2019Q1CUTotal': 69,
      '2019Q1CUPos': 53,
      '2019Q1CUNeg': 6,
      '2019Q2CUTotal': 69,
      '2019Q2CUPos': 48,
      '2019Q2CUNeg': 6,
      '2019Q3CUTotal': 104,
      '2019Q3CUPos': 77,
      '2019Q3CUNeg': 11,
      '2019Q4CUTotal': 66,
      '2019Q4CUPos': 44,
      '2019Q4CUNeg': 15,
      '2020Q1CUTotal': 71,
      '2020Q1CUPos': 49,
      '2020Q1CUNeg': 12,
      '2020Q2CUTotal': 106,
      '2020Q2CUPos': 81,
      '2020Q2CUNeg': 15,
      '2020Q3CUTotal': 64,
      '2020Q3CUPos': 53,
      '2020Q3CUNeg': 3,
      '2020Q4CUTotal': 74,
      '2020Q4CUPos': 56,
      '2020Q4CUNeg': 10,
      '2021Q1CUTotal': 43,
      '2021Q1CUPos': 30,
      '2021Q1CUNeg': 6,
      '2021Q2CUTotal': 25,
      '2021Q2CUPos': 18,
      '2021Q2CUNeg': 2,
      '2021Q3CUTotal': 73,
      '2021Q3CUPos': 41,
      '2021Q3CUNeg': 23,
      '2021Q4CUTotal': 53,
      '2021Q4CUPos': 44,
      '2021Q4CUNeg': 5,
      '2022Q1CUTotal': 47,
      '2022Q1CUPos': 38,
      '2022Q1CUNeg': 8,
      '2022Q2CUTotal': 38,
      '2022Q2CUPos': 33,
      '2022Q2CUNeg': 4,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 0,
      '2021Q2BankPos': 0,
      '2021Q2BankNeg': 0,
      '2021Q3BankTotal': 0,
      '2021Q3BankPos': 0,
      '2021Q3BankNeg': 0,
      '2021Q4BankTotal': 0,
      '2021Q4BankPos': 0,
      '2021Q4BankNeg': 0,
      '2022Q1BankTotal': 0,
      '2022Q1BankPos': 0,
      '2022Q1BankNeg': 0,
      '2022Q2BankTotal': 0,
      '2022Q2BankPos': 0,
      '2022Q2BankNeg': 0,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'SouthState Bank',
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 0,
      '2019Q1Pos': 0,
      '2019Q1Neg': 0,
      '2019Q2Total': 0,
      '2019Q2Pos': 0,
      '2019Q2Neg': 0,
      '2019Q3Total': 0,
      '2019Q3Pos': 0,
      '2019Q3Neg': 0,
      '2019Q4Total': 0,
      '2019Q4Pos': 0,
      '2019Q4Neg': 0,
      '2020Q1Total': 0,
      '2020Q1Pos': 0,
      '2020Q1Neg': 0,
      '2020Q2Total': 0,
      '2020Q2Pos': 0,
      '2020Q2Neg': 0,
      '2020Q3Total': 303,
      '2020Q3Pos': 232,
      '2020Q3Neg': 45,
      '2020Q4Total': 1697,
      '2020Q4Pos': 876,
      '2020Q4Neg': 678,
      '2021Q1Total': 0,
      '2021Q1Pos': 0,
      '2021Q1Neg': 0,
      '2021Q2Total': 0,
      '2021Q2Pos': 0,
      '2021Q2Neg': 0,
      '2021Q3Total': 0,
      '2021Q3Pos': 0,
      '2021Q3Neg': 0,
      '2021Q4Total': 0,
      '2021Q4Pos': 0,
      '2021Q4Neg': 0,
      '2022Q1Total': 0,
      '2022Q1Pos': 0,
      '2022Q1Neg': 0,
      '2022Q2Total': 0,
      '2022Q2Pos': 0,
      '2022Q2Neg': 0,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 303,
      '2020Q3BankPos': 232,
      '2020Q3BankNeg': 45,
      '2020Q4BankTotal': 1697,
      '2020Q4BankPos': 876,
      '2020Q4BankNeg': 678,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 0,
      '2021Q2BankPos': 0,
      '2021Q2BankNeg': 0,
      '2021Q3BankTotal': 0,
      '2021Q3BankPos': 0,
      '2021Q3BankNeg': 0,
      '2021Q4BankTotal': 0,
      '2021Q4BankPos': 0,
      '2021Q4BankNeg': 0,
      '2022Q1BankTotal': 0,
      '2022Q1BankPos': 0,
      '2022Q1BankNeg': 0,
      '2022Q2BankTotal': 0,
      '2022Q2BankPos': 0,
      '2022Q2BankNeg': 0,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'State Farm Bank',
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 0,
      '2019Q1Pos': 0,
      '2019Q1Neg': 0,
      '2019Q2Total': 0,
      '2019Q2Pos': 0,
      '2019Q2Neg': 0,
      '2019Q3Total': 715,
      '2019Q3Pos': 583,
      '2019Q3Neg': 49,
      '2019Q4Total': 0,
      '2019Q4Pos': 0,
      '2019Q4Neg': 0,
      '2020Q1Total': 0,
      '2020Q1Pos': 0,
      '2020Q1Neg': 0,
      '2020Q2Total': 0,
      '2020Q2Pos': 0,
      '2020Q2Neg': 0,
      '2020Q3Total': 0,
      '2020Q3Pos': 0,
      '2020Q3Neg': 0,
      '2020Q4Total': 0,
      '2020Q4Pos': 0,
      '2020Q4Neg': 0,
      '2021Q1Total': 0,
      '2021Q1Pos': 0,
      '2021Q1Neg': 0,
      '2021Q2Total': 0,
      '2021Q2Pos': 0,
      '2021Q2Neg': 0,
      '2021Q3Total': 0,
      '2021Q3Pos': 0,
      '2021Q3Neg': 0,
      '2021Q4Total': 0,
      '2021Q4Pos': 0,
      '2021Q4Neg': 0,
      '2022Q1Total': 906,
      '2022Q1Pos': 644,
      '2022Q1Neg': 174,
      '2022Q2Total': 0,
      '2022Q2Pos': 0,
      '2022Q2Neg': 0,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 715,
      '2019Q3BankPos': 583,
      '2019Q3BankNeg': 49,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 0,
      '2021Q2BankPos': 0,
      '2021Q2BankNeg': 0,
      '2021Q3BankTotal': 0,
      '2021Q3BankPos': 0,
      '2021Q3BankNeg': 0,
      '2021Q4BankTotal': 0,
      '2021Q4BankPos': 0,
      '2021Q4BankNeg': 0,
      '2022Q1BankTotal': 906,
      '2022Q1BankPos': 644,
      '2022Q1BankNeg': 174,
      '2022Q2BankTotal': 0,
      '2022Q2BankPos': 0,
      '2022Q2BankNeg': 0,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'Truliant Federal Credit Union',
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 0,
      '2019Q1Pos': 0,
      '2019Q1Neg': 0,
      '2019Q2Total': 0,
      '2019Q2Pos': 0,
      '2019Q2Neg': 0,
      '2019Q3Total': 0,
      '2019Q3Pos': 0,
      '2019Q3Neg': 0,
      '2019Q4Total': 0,
      '2019Q4Pos': 0,
      '2019Q4Neg': 0,
      '2020Q1Total': 0,
      '2020Q1Pos': 0,
      '2020Q1Neg': 0,
      '2020Q2Total': 0,
      '2020Q2Pos': 0,
      '2020Q2Neg': 0,
      '2020Q3Total': 0,
      '2020Q3Pos': 0,
      '2020Q3Neg': 0,
      '2020Q4Total': 0,
      '2020Q4Pos': 0,
      '2020Q4Neg': 0,
      '2021Q1Total': 0,
      '2021Q1Pos': 0,
      '2021Q1Neg': 0,
      '2021Q2Total': 0,
      '2021Q2Pos': 0,
      '2021Q2Neg': 0,
      '2021Q3Total': 0,
      '2021Q3Pos': 0,
      '2021Q3Neg': 0,
      '2021Q4Total': 0,
      '2021Q4Pos': 0,
      '2021Q4Neg': 0,
      '2022Q1Total': 490,
      '2022Q1Pos': 192,
      '2022Q1Neg': 257,
      '2022Q2Total': 1510,
      '2022Q2Pos': 773,
      '2022Q2Neg': 620,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 0,
      '2021Q2BankPos': 0,
      '2021Q2BankNeg': 0,
      '2021Q3BankTotal': 0,
      '2021Q3BankPos': 0,
      '2021Q3BankNeg': 0,
      '2021Q4BankTotal': 0,
      '2021Q4BankPos': 0,
      '2021Q4BankNeg': 0,
      '2022Q1BankTotal': 490,
      '2022Q1BankPos': 192,
      '2022Q1BankNeg': 257,
      '2022Q2BankTotal': 1510,
      '2022Q2BankPos': 773,
      '2022Q2BankNeg': 620,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
  {
    _id: 'U.S. Bank Mobile Banking',
    aggData: {
      '2018Q1Total': 0,
      '2018Q1Pos': 0,
      '2018Q1Neg': 0,
      '2018Q2Total': 0,
      '2018Q2Pos': 0,
      '2018Q2Neg': 0,
      '2018Q3Total': 0,
      '2018Q3Pos': 0,
      '2018Q3Neg': 0,
      '2018Q4Total': 0,
      '2018Q4Pos': 0,
      '2018Q4Neg': 0,
      '2019Q1Total': 0,
      '2019Q1Pos': 0,
      '2019Q1Neg': 0,
      '2019Q2Total': 0,
      '2019Q2Pos': 0,
      '2019Q2Neg': 0,
      '2019Q3Total': 0,
      '2019Q3Pos': 0,
      '2019Q3Neg': 0,
      '2019Q4Total': 0,
      '2019Q4Pos': 0,
      '2019Q4Neg': 0,
      '2020Q1Total': 0,
      '2020Q1Pos': 0,
      '2020Q1Neg': 0,
      '2020Q2Total': 0,
      '2020Q2Pos': 0,
      '2020Q2Neg': 0,
      '2020Q3Total': 0,
      '2020Q3Pos': 0,
      '2020Q3Neg': 0,
      '2020Q4Total': 0,
      '2020Q4Pos': 0,
      '2020Q4Neg': 0,
      '2021Q1Total': 0,
      '2021Q1Pos': 0,
      '2021Q1Neg': 0,
      '2021Q2Total': 110,
      '2021Q2Pos': 20,
      '2021Q2Neg': 85,
      '2021Q3Total': 660,
      '2021Q3Pos': 269,
      '2021Q3Neg': 339,
      '2021Q4Total': 581,
      '2021Q4Pos': 271,
      '2021Q4Neg': 243,
      '2022Q1Total': 391,
      '2022Q1Pos': 164,
      '2022Q1Neg': 196,
      '2022Q2Total': 258,
      '2022Q2Pos': 85,
      '2022Q2Neg': 151,
      '2022Q3Total': 0,
      '2022Q3Pos': 0,
      '2022Q3Neg': 0,
      '2022Q4Total': 0,
      '2022Q4Pos': 0,
      '2022Q4Neg': 0,
    },
    unclassData: {
      '2018Q1UnclassTotal': 0,
      '2018Q1UnclassPos': 0,
      '2018Q1UnclassNeg': 0,
      '2018Q2UnclassTotal': 0,
      '2018Q2UnclassPos': 0,
      '2018Q2UnclassNeg': 0,
      '2018Q3UnclassTotal': 0,
      '2018Q3UnclassPos': 0,
      '2018Q3UnclassNeg': 0,
      '2018Q4UnclassTotal': 0,
      '2018Q4UnclassPos': 0,
      '2018Q4UnclassNeg': 0,
      '2019Q1UnclassTotal': 0,
      '2019Q1UnclassPos': 0,
      '2019Q1UnclassNeg': 0,
      '2019Q2UnclassTotal': 0,
      '2019Q2UnclassPos': 0,
      '2019Q2UnclassNeg': 0,
      '2019Q3UnclassTotal': 0,
      '2019Q3UnclassPos': 0,
      '2019Q3UnclassNeg': 0,
      '2019Q4UnclassTotal': 0,
      '2019Q4UnclassPos': 0,
      '2019Q4UnclassNeg': 0,
      '2020Q1UnclassTotal': 0,
      '2020Q1UnclassPos': 0,
      '2020Q1UnclassNeg': 0,
      '2020Q2UnclassTotal': 0,
      '2020Q2UnclassPos': 0,
      '2020Q2UnclassNeg': 0,
      '2020Q3UnclassTotal': 0,
      '2020Q3UnclassPos': 0,
      '2020Q3UnclassNeg': 0,
      '2020Q4UnclassTotal': 0,
      '2020Q4UnclassPos': 0,
      '2020Q4UnclassNeg': 0,
      '2021Q1UnclassTotal': 0,
      '2021Q1UnclassPos': 0,
      '2021Q1UnclassNeg': 0,
      '2021Q2UnclassTotal': 0,
      '2021Q2UnclassPos': 0,
      '2021Q2UnclassNeg': 0,
      '2021Q3UnclassTotal': 0,
      '2021Q3UnclassPos': 0,
      '2021Q3UnclassNeg': 0,
      '2021Q4UnclassTotal': 0,
      '2021Q4UnclassPos': 0,
      '2021Q4UnclassNeg': 0,
      '2022Q1UnclassTotal': 0,
      '2022Q1UnclassPos': 0,
      '2022Q1UnclassNeg': 0,
      '2022Q2UnclassTotal': 0,
      '2022Q2UnclassPos': 0,
      '2022Q2UnclassNeg': 0,
      '2022Q3UnclassTotal': 0,
      '2022Q3UnclassPos': 0,
      '2022Q3UnclassNeg': 0,
      '2022Q4UnclassTotal': 0,
      '2022Q4UnclassPos': 0,
      '2022Q4UnclassNeg': 0,
    },
    cuData: {
      '2018Q1CUTotal': 0,
      '2018Q1CUPos': 0,
      '2018Q1CUNeg': 0,
      '2018Q2CUTotal': 0,
      '2018Q2CUPos': 0,
      '2018Q2CUNeg': 0,
      '2018Q3CUTotal': 0,
      '2018Q3CUPos': 0,
      '2018Q3CUNeg': 0,
      '2018Q4CUTotal': 0,
      '2018Q4CUPos': 0,
      '2018Q4CUNeg': 0,
      '2019Q1CUTotal': 0,
      '2019Q1CUPos': 0,
      '2019Q1CUNeg': 0,
      '2019Q2CUTotal': 0,
      '2019Q2CUPos': 0,
      '2019Q2CUNeg': 0,
      '2019Q3CUTotal': 0,
      '2019Q3CUPos': 0,
      '2019Q3CUNeg': 0,
      '2019Q4CUTotal': 0,
      '2019Q4CUPos': 0,
      '2019Q4CUNeg': 0,
      '2020Q1CUTotal': 0,
      '2020Q1CUPos': 0,
      '2020Q1CUNeg': 0,
      '2020Q2CUTotal': 0,
      '2020Q2CUPos': 0,
      '2020Q2CUNeg': 0,
      '2020Q3CUTotal': 0,
      '2020Q3CUPos': 0,
      '2020Q3CUNeg': 0,
      '2020Q4CUTotal': 0,
      '2020Q4CUPos': 0,
      '2020Q4CUNeg': 0,
      '2021Q1CUTotal': 0,
      '2021Q1CUPos': 0,
      '2021Q1CUNeg': 0,
      '2021Q2CUTotal': 0,
      '2021Q2CUPos': 0,
      '2021Q2CUNeg': 0,
      '2021Q3CUTotal': 0,
      '2021Q3CUPos': 0,
      '2021Q3CUNeg': 0,
      '2021Q4CUTotal': 0,
      '2021Q4CUPos': 0,
      '2021Q4CUNeg': 0,
      '2022Q1CUTotal': 0,
      '2022Q1CUPos': 0,
      '2022Q1CUNeg': 0,
      '2022Q2CUTotal': 0,
      '2022Q2CUPos': 0,
      '2022Q2CUNeg': 0,
      '2022Q3CUTotal': 0,
      '2022Q3CUPos': 0,
      '2022Q3CUNeg': 0,
      '2022Q4CUTotal': 0,
      '2022Q4CUPos': 0,
      '2022Q4CUNeg': 0,
    },
    bankData: {
      '2018Q1BankTotal': 0,
      '2018Q1BankPos': 0,
      '2018Q1BankNeg': 0,
      '2018Q2BankTotal': 0,
      '2018Q2BankPos': 0,
      '2018Q2BankNeg': 0,
      '2018Q3BankTotal': 0,
      '2018Q3BankPos': 0,
      '2018Q3BankNeg': 0,
      '2018Q4BankTotal': 0,
      '2018Q4BankPos': 0,
      '2018Q4BankNeg': 0,
      '2019Q1BankTotal': 0,
      '2019Q1BankPos': 0,
      '2019Q1BankNeg': 0,
      '2019Q2BankTotal': 0,
      '2019Q2BankPos': 0,
      '2019Q2BankNeg': 0,
      '2019Q3BankTotal': 0,
      '2019Q3BankPos': 0,
      '2019Q3BankNeg': 0,
      '2019Q4BankTotal': 0,
      '2019Q4BankPos': 0,
      '2019Q4BankNeg': 0,
      '2020Q1BankTotal': 0,
      '2020Q1BankPos': 0,
      '2020Q1BankNeg': 0,
      '2020Q2BankTotal': 0,
      '2020Q2BankPos': 0,
      '2020Q2BankNeg': 0,
      '2020Q3BankTotal': 0,
      '2020Q3BankPos': 0,
      '2020Q3BankNeg': 0,
      '2020Q4BankTotal': 0,
      '2020Q4BankPos': 0,
      '2020Q4BankNeg': 0,
      '2021Q1BankTotal': 0,
      '2021Q1BankPos': 0,
      '2021Q1BankNeg': 0,
      '2021Q2BankTotal': 110,
      '2021Q2BankPos': 20,
      '2021Q2BankNeg': 85,
      '2021Q3BankTotal': 660,
      '2021Q3BankPos': 269,
      '2021Q3BankNeg': 339,
      '2021Q4BankTotal': 581,
      '2021Q4BankPos': 271,
      '2021Q4BankNeg': 243,
      '2022Q1BankTotal': 391,
      '2022Q1BankPos': 164,
      '2022Q1BankNeg': 196,
      '2022Q2BankTotal': 258,
      '2022Q2BankPos': 85,
      '2022Q2BankNeg': 151,
      '2022Q3BankTotal': 0,
      '2022Q3BankPos': 0,
      '2022Q3BankNeg': 0,
      '2022Q4BankTotal': 0,
      '2022Q4BankPos': 0,
      '2022Q4BankNeg': 0,
    },
    digData: {
      '2018Q1DigTotal': 0,
      '2018Q1DigPos': 0,
      '2018Q1DigNeg': 0,
      '2018Q2DigTotal': 0,
      '2018Q2DigPos': 0,
      '2018Q2DigNeg': 0,
      '2018Q3DigTotal': 0,
      '2018Q3DigPos': 0,
      '2018Q3DigNeg': 0,
      '2018Q4DigTotal': 0,
      '2018Q4DigPos': 0,
      '2018Q4DigNeg': 0,
      '2019Q1DigTotal': 0,
      '2019Q1DigPos': 0,
      '2019Q1DigNeg': 0,
      '2019Q2DigTotal': 0,
      '2019Q2DigPos': 0,
      '2019Q2DigNeg': 0,
      '2019Q3DigTotal': 0,
      '2019Q3DigPos': 0,
      '2019Q3DigNeg': 0,
      '2019Q4DigTotal': 0,
      '2019Q4DigPos': 0,
      '2019Q4DigNeg': 0,
      '2020Q1DigTotal': 0,
      '2020Q1DigPos': 0,
      '2020Q1DigNeg': 0,
      '2020Q2DigTotal': 0,
      '2020Q2DigPos': 0,
      '2020Q2DigNeg': 0,
      '2020Q3DigTotal': 0,
      '2020Q3DigPos': 0,
      '2020Q3DigNeg': 0,
      '2020Q4DigTotal': 0,
      '2020Q4DigPos': 0,
      '2020Q4DigNeg': 0,
      '2021Q1DigTotal': 0,
      '2021Q1DigPos': 0,
      '2021Q1DigNeg': 0,
      '2021Q2DigTotal': 0,
      '2021Q2DigPos': 0,
      '2021Q2DigNeg': 0,
      '2021Q3DigTotal': 0,
      '2021Q3DigPos': 0,
      '2021Q3DigNeg': 0,
      '2021Q4DigTotal': 0,
      '2021Q4DigPos': 0,
      '2021Q4DigNeg': 0,
      '2022Q1DigTotal': 0,
      '2022Q1DigPos': 0,
      '2022Q1DigNeg': 0,
      '2022Q2DigTotal': 0,
      '2022Q2DigPos': 0,
      '2022Q2DigNeg': 0,
      '2022Q3DigTotal': 0,
      '2022Q3DigPos': 0,
      '2022Q3DigNeg': 0,
      '2022Q4DigTotal': 0,
      '2022Q4DigPos': 0,
      '2022Q4DigNeg': 0,
    },
  },
]

export const trendingLanding = [
  {
    _id: 'HSBC',
    servPXI: -56,
    servMention: 21,
    brandPXI: 2,
    brandMention: 27,
    accPXI: -67,
    accMention: 61,
    feesPXI: -77,
    feesMention: 2,
    prodPXI: -60,
    prodMention: 2,
  },
  {
    _id: 'BB&T',
    servPXI: -7,
    servMention: 18,
    brandPXI: 59,
    brandMention: 46,
    accPXI: 23,
    accMention: 41,
    feesPXI: -3,
    feesMention: 8,
    prodPXI: -19,
    prodMention: 1,
  },
  {
    _id: 'Charles Schwab Bank',
    servPXI: 52,
    servMention: 31,
    brandPXI: 69,
    brandMention: 74,
    accPXI: 54,
    accMention: 76,
    feesPXI: 26,
    feesMention: 4,
    prodPXI: 5,
    prodMention: 3,
  },
  {
    _id: 'Coastal Credit Union',
    servPXI: 39,
    servMention: 19,
    brandPXI: 73,
    brandMention: 53,
    accPXI: 33,
    accMention: 56,
    feesPXI: 54,
    feesMention: 3,
    prodPXI: -33,
    prodMention: 1,
  },
  {
    _id: 'Fifth Third Bank',
    servPXI: 34,
    servMention: 42,
    brandPXI: 54,
    brandMention: 59,
    accPXI: -2,
    accMention: 57,
    feesPXI: -20,
    feesMention: 3,
    prodPXI: -42,
    prodMention: 4,
  },
  {
    _id: 'First Citizens Bank',
    servPXI: 44,
    servMention: 21,
    brandPXI: 74,
    brandMention: 55,
    accPXI: 35,
    accMention: 45,
    feesPXI: -44,
    feesMention: 2,
    prodPXI: 8,
    prodMention: 3,
  },
  {
    _id: 'First National Bank Texas',
    servPXI: -69,
    servMention: 38,
    brandPXI: -41,
    brandMention: 33,
    accPXI: -75,
    accMention: 81,
    feesPXI: -92,
    feesMention: 3,
    prodPXI: -79,
    prodMention: 8,
  },
  {
    _id: 'Flagstar Bank',
    servPXI: 25,
    servMention: 26,
    brandPXI: 61,
    brandMention: 55,
    accPXI: 18,
    accMention: 63,
    feesPXI: -37,
    feesMention: 1,
    prodPXI: -12,
    prodMention: 1,
  },
  {
    _id: "NC State Employees' Credit Union",
    servPXI: -33,
    servMention: 19,
    brandPXI: 48,
    brandMention: 41,
    accPXI: -18,
    accMention: 33,
    feesPXI: -37,
    feesMention: 9,
    prodPXI: -53,
    prodMention: 2,
  },
  {
    _id: 'RBC',
    servPXI: 33,
    servMention: 18,
    brandPXI: 74,
    brandMention: 48,
    accPXI: 27,
    accMention: 44,
    feesPXI: -7,
    feesMention: 2,
    prodPXI: -52,
    prodMention: 1,
  },
  {
    _id: 'SouthState Bank',
    servPXI: -36,
    servMention: 21,
    brandPXI: 26,
    brandMention: 32,
    accPXI: -52,
    accMention: 62,
    feesPXI: -55,
    feesMention: 1,
    prodPXI: -62,
    prodMention: 1,
  },
  {
    _id: 'Truliant Federal Credit Union',
    servPXI: -33,
    servMention: 17,
    brandPXI: 50,
    brandMention: 37,
    accPXI: -37,
    accMention: 50,
    feesPXI: -54,
    feesMention: 4,
    prodPXI: -100,
    prodMention: 0,
  },
  {
    _id: 'U.S. Bank Mobile Banking',
    servPXI: -28,
    servMention: 24,
    brandPXI: 49,
    brandMention: 38,
    accPXI: -21,
    accMention: 49,
    feesPXI: -74,
    feesMention: 3,
    prodPXI: -76,
    prodMention: 3,
  },
  {
    _id: 'State Farm Bank',
    servPXI: -2,
    servMention: 17,
    brandPXI: 58,
    brandMention: 42,
    accPXI: -28,
    accMention: 52,
    feesPXI: -53,
    feesMention: 3,
    prodPXI: -44,
    prodMention: 0,
  },
]
