export const topicdatas = [
  {
    'Allreviews PXI': {
      supporters: 5881820,
      detractors: 1914911,
      total: 8681909,
    },
  },
  {
    'Starting PXI': {
      supporters: 1355075,
      detractors: 549302,
      total: 2122509,
    },
  },
  // {
  //   'Starting PXI': {
  //     supporters: 277,
  //     detractors: 229,
  //     total: 591,
  //   },
  // },
  {
    'Design And Functionality': {
      supporters: 832531,
      detractors: 302682,
      total: 1268226,
    },
  },
  {
    'Customer Service': {
      supporters: 990383,
      detractors: 349347,
      total: 1486966,
    },
  },
  {
    'Fees And Incentives': {
      supporters: 95998,
      detractors: 71307,
      total: 187589,
    },
  },
  {
    'Product Offerings': {
      supporters: 52407,
      detractors: 49820,
      total: 117816,
    },
  },
  {
    Accessibility: {
      supporters: 2439258,
      detractors: 1038331,
      total: 3860582,
    },
  },
  {
    'Brand Equity': {
      supporters: 3220495,
      detractors: 382850,
      total: 4038640,
    },
  },
]
