import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CaretDownOutlined, CaretUpOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { Button, Card, Col, Grid, Image, Row } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Bar, BarChart, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { DataContext } from '../../providers'

import './scorecard-bar-graph.css'

const { useBreakpoint } = Grid

export const ScorecardBarGraph = ({ data, totalCount }) => {
  const navigate = useNavigate()
  const parsedPxiData = data.map((object) => (object = { ...object, PXI: parseInt(object.PXI) }))
  const dataByAz = parsedPxiData
  const dataByPxiAsc = parsedPxiData.toSorted((a, b) => a.PXI - b.PXI)
  const dataByPxiDesc = parsedPxiData.toSorted((a, b) => b.PXI - a.PXI)
  const startHeight = totalCount < 6 ? 270 : totalCount * 40 + 30
  const [height, setHeight] = useState(startHeight)
  const [divHeight, setDivHeight] = useState(20)
  const [sortedData, setSortedData] = useState(dataByAz)
  const [sortSelection, setSortSelection] = useState('az')
  const [yCoord, setYCoord] = useState(5)
  const [yFontReg, setYFontReg] = useState(12)
  const [yFontSmall, setYFontSmall] = useState(10)
  const { setIsReviewsCallActive } = useContext(DataContext)
  const { xs, xl, xxl } = useBreakpoint()

  useEffect(() => {
    setSortedData(dataByAz)
  }, [data])

  const handleClick = (data, index) => {
    setIsReviewsCallActive(true)
    let encodedFi = encodeURIComponent(data.fi)
    navigate(`/reviews?fi=${encodedFi}`, {
      state: {
        prevPages: [
          { pageTitle: 'Home', pagePath: '/' },
          { pageTitle: 'Scorecard', pagePath: '/scorecard' },
        ],
      },
    })
    if (index === 'hearful') console.log(index)
  }

  const CustomYAxisLabel = ({ x, y, payload }) => {
    return (
      <foreignObject x={xl || xxl ? x - 210 : xs ? x - 86 : x - 175} y={xl || xxl ? y - yCoord : y - (yCoord - 3)} style={{ overflow: 'visible' }}>
        <div
          style={{
            borderRadius: '5px',
            width: xl || xxl ? `212px` : xs ? `88px` : `177px`,
            height: xl || xxl ? `${divHeight}px` : xs ? `${divHeight * 0.7}px` : `${divHeight * 0.8}px`,
            padding: xl || xxl ? '2px 2px' : '0px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            textAlign: 'right',
          }}
        >
          <p x={x} y={y} style={{ padding: '2px', fontSize: xl || xxl ? yFontReg : yFontSmall }}>
            {payload.value}
          </p>
        </div>
      </foreignObject>
    )
  }

  CustomYAxisLabel.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.object,
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Card
          bordered={false}
          style={{ borderRadius: '10px' }}
          headStyle={{
            width: '325px',
            color: '#d6d8e0',
            backgroundColor: '#606f7d',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottom: '0.5px solid #606f7d',
            paddingLeft: '20px',
          }}
          bodyStyle={{
            color: '#d6d8e0',
            backgroundColor: '#606f7d',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            paddingTop: '6px',
            paddingLeft: '20px',
          }}
          title={<span>{payload[0].payload.fi}</span>}
        >
          <Row>
            <Col span={12}>
              <p>{`PXI: ${payload[0].payload.PXI}`}</p>
              <p>{`Mention Rate: ${payload[0].payload.mentionRate}`}</p>
              <p>{`Reviews: ${payload[0].payload.totalReviews}`}</p>
            </Col>
            <Col span={12}>
              <Image preview={false} width="125px" style={{ paddingBottom: '20px' }} src={`${payload[0].payload.img}`} />
            </Col>
          </Row>
        </Card>
      )
    }
    return null
  }

  CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
  }

  const handleZoomOut = () => {
    if (totalCount < 4) {
      if (yFontReg > 12) {
        setYFontReg(yFontReg / 1.1)
        setYFontSmall(yFontSmall / 1.1)
        return
      }
      return
    }
    if (xl || xxl) {
      if (height <= 500) return
    } else {
      if (height <= 450) return
    }
    setHeight(height - 300)
    setDivHeight(divHeight * 0.9)
    setYCoord(yCoord - 1.1)
    setYFontReg(yFontReg / 1.2)
    setYFontSmall(yFontSmall / 1.2)
  }

  const handleZoomIn = () => {
    if (totalCount < 4) {
      if (yFontReg < 14) {
        setYFontReg(yFontReg * 1.1)
        setYFontSmall(yFontSmall * 1.1)
        return
      }
      return
    }
    if (height >= 570) return
    setDivHeight(divHeight * 1.1)
    setHeight(height + 300)
    setYCoord(yCoord * 1.1)
    setYFontReg(yFontReg * 1.2)
    setYFontSmall(yFontSmall * 1.2)
  }

  const handleSortByAz = () => {
    setSortedData(dataByAz)
    setSortSelection('az')
  }

  const handleSortByPXI = () => {
    if (!_.isEqual(sortedData, dataByPxiDesc)) {
      setSortedData(dataByPxiDesc)
      setSortSelection('pxi-desc')
    } else {
      setSortedData(dataByPxiAsc)
      setSortSelection('pxi-asc')
    }
  }

  const handleReset = () => {
    setHeight(startHeight)
    setDivHeight(20)
    setYCoord(5)
    setYFontReg(12)
    setYFontSmall(10)
    setSortedData(dataByAz)
    setSortSelection('az')
  }

  const CaretsInactive = () => (
    <>
      <CaretUpOutlined className="caret" />
      <CaretDownOutlined className="caret" />
    </>
  )

  const CaretUpActive = () => (
    <>
      <CaretUpOutlined className="caret caret-active" />
      <CaretDownOutlined className="caret" />
    </>
  )

  const CaretDownActive = () => (
    <>
      <CaretUpOutlined className="caret" />
      <CaretDownOutlined className="caret caret-active" />
    </>
  )

  const ZoomButtons = () => (
    <Row className="zoom-buttons">
      <div className="div-count" style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <span className="span-count">Institution Count: {totalCount}</span>
        </div>
        <div>
          <Button className="blue-button" onClick={handleZoomOut}>
            Zoom Out
            <ZoomOutOutlined />
          </Button>
          <Button className="blue-button" onClick={handleZoomIn}>
            Zoom In
            <ZoomInOutlined />
          </Button>
          <Button className="blue-button" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </div>
    </Row>
  )

  const SortButtons = () => (
    <Row className="sort-buttons">
      <div className="div-count" style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Button className="blue-button sort-az" onClick={handleSortByAz}>
            Sort A-Z
          </Button>
          <Button className="blue-button" onClick={handleSortByPXI}>
            <div className="sort-pxi-content">
              Sort by PXI
              <div className="bargraph-sort-arrows">{sortSelection === 'az' ? <CaretsInactive /> : sortSelection === 'pxi-desc' ? <CaretDownActive /> : <CaretUpActive />}</div>
            </div>
          </Button>
        </div>
      </div>
    </Row>
  )

  return (
    <div className="bargraph-div">
      <div className="bargraph-buttons-div">
        <SortButtons />
        <ZoomButtons />
      </div>
      <ResponsiveContainer height={height} width="100%">
        <BarChart
          layout="vertical"
          barCategoryGap={2}
          height={height}
          data={sortedData}
          margin={{
            top: 10,
            right: 10,
            bottom: 10,
            left: xl || xxl ? -60 : xs ? 5 : -100,
          }}
        >
          <XAxis orientation="top" type="number" stroke="var(--gridlines)" padding={{ left: 30, right: 30 }} />
          <YAxis type="category" width={xs ? 90 : 285} interval={0} dataKey="fi" stroke="var(--gridlines)" tick={<CustomYAxisLabel />} />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
          <ReferenceLine x={0} strokeWidth={2} stroke="var(--btn-bkg)" />
          <Bar dataKey="PXI" onClick={handleClick}>
            {data.map((entry) => (
              <Cell className="bargraph-cell" cursor="pointer" fill="var(--btn-bkg)" key={`cell-${entry._id}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

ScorecardBarGraph.propTypes = {
  data: PropTypes.array.isRequired,
  dataSet: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
}
