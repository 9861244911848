import { API } from 'aws-amplify'

const apiName = process.env.REACT_APP_API_GATEWAY_NAME
const myInit = {
  response: true, // OPTIONAL
}

export const usersAPI = {
  addUser: (email) =>
    API.post(apiName, '/users/add-user', {
      body: {
        email,
      },
      response: true,
    }),
  getUserByEmail: (email) =>
    API.get(
      apiName,
      '/users/get-by-email',
      {
        queryStringParameters: {
          email,
        },
      },
      myInit,
    ),
  putUserDarkMode: (isDarkMode, email) => {
    API.put(apiName, '/users/dark-mode', {
      body: {
        isDarkMode,
        email,
      },
    })
  },
  putUserFISets: (savedFISet, email) =>
    API.put(apiName, '/users/fis', {
      body: {
        savedFISet,
        email,
      },
    }),
  putUserFI: (userFI, email) =>
    API.put(apiName, '/users/fi', {
      body: {
        userFI,
        email,
      },
    }),
  putUserCompFISet: (savedFISet, email) =>
    API.put(apiName, '/users/fis/comp', {
      body: {
        savedFISet,
        email,
      },
    }),
  putUserFilterSets: (savedFilterSet, email) =>
    API.put(apiName, '/users/filters', {
      body: {
        savedFilterSet,
        email,
      },
    }),
  updateUserFilterSets: (filterSet, email) =>
    API.put(
      apiName,
      '/users/filters/update',
      {
        body: {
          filterSet,
          email,
        },
      },
      myInit,
    ),
  updateUserFISets: (fiSet, email) =>
    API.put(
      apiName,
      '/users/fis/update',
      {
        body: {
          fiSet,
          email,
        },
      },
      myInit,
    ),
}
