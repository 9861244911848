import { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Layout } from 'antd'

import { NavbarDesign, TopFilters } from '../../components'

// import { ThemeContext } from '../../providers'

// import logoWhite from '../../assets/hearful-logo-green.svg'
// import logoBlue from '../../assets/hearful-logo.svg'

const { Content } = Layout

export const FiltersLayout = () => {
  // const { theme } = useContext(ThemeContext)
  const { pathname } = useLocation()
  const [state, setState] = useState({
    isCollapsed: false,
  })
  const { isCollapsed } = state

  const toggleIsCollapsed = () => {
    setState({
      ...state,
      isCollapsed: !isCollapsed,
    })
  }

  return (
    <>
      <NavbarDesign />
      <Content className="page-container">
        <Layout className="site-layout-background">
          <Layout className="site-layout">
            {pathname !== '/settings' ? <TopFilters isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed} /> : null}
            <Outlet />
          </Layout>
        </Layout>
      </Content>
    </>
  )
}
