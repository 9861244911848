/* eslint-disable no-unused-vars */
import { useContext, useState } from 'react'

import { Button, Col, Input, Row } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import { ReviewTag, ToggleSwitch } from '../../components'

import { DataContext, FiltersContext } from '../../providers'

import { reviewsAPI } from '../../api'

import { changeKeyData, formatReviewFIs, formatReviews } from '../../common/utils/helpers'

const { Search } = Input

export const ForumControls = (props) => {
  const { expandName, forum, handleExpandClick, handleToggle, isToggleAnd } = props
  const { totalPerPage, setForumsData, setTotalForums, setReviewsText, reviewsText, setCurrentPage, isReviewCardExpanded, setReviewCsvData } = useContext(DataContext)
  const { filterSelection, chosenFIs, setFIs } = useContext(FiltersContext)
  const [inputValue, setInputValue] = useState('')

  const fetchReviewsText = async (updatedReviews) => {
    reviewsAPI.putReviews(totalPerPage, chosenFIs, filterSelection, updatedReviews, isToggleAnd, forum).then(({ data, total, fis }) => {
      if (!_.isEmpty(data)) {
        const updatedKeyData = changeKeyData(data)
        setReviewCsvData(updatedKeyData)
        setCurrentPage(1)
        const newData = formatReviews(data, isReviewCardExpanded)
        setForumsData(newData)
        setTotalForums(total)
        // if (!_.isEmpty(fis)) setFIs(formatReviewFIs(fis))
      } else {
        reviewsText.pop()
        const newArr = [...reviewsText]
        setReviewsText(newArr)
        Swal.fire({
          text: 'Unfortunately these particular selections yielded no reviews. Please select different filters and try again.',
        })
      }
    })
  }

  const searchText = (text) => {
    fetchReviewsText(text)
  }

  const updateReviewsText = (input) => {
    if (input) {
      if (!reviewsText.includes(input)) {
        reviewsText.push(input)
        const newArr = [...reviewsText]
        searchText(newArr)
        setReviewsText(newArr)
      }
      setInputValue('')
    }
  }

  const onClose = (e, current) => {
    e.preventDefault()

    const filteredArr = reviewsText.filter((word) => word !== current)
    setReviewsText(filteredArr)
    searchText(filteredArr)
  }

  //BTN HANDLER FOR DOWNLOAD CSV
  // const handleOnClick = (e) => {
  //   console.log(e)
  // }

  const data = (
    <div style={{ color: 'var(--hearful-blue)' }} className="title-popover">
      <p>Toggling to &quot;and&quot; will search for Forums that contain all of your choices.</p>
      <p>
        For example, if you search for the words &quot;friendly&quot; and &quot;quick&quot; and have the toggler set to &quot;and&quot;, then you will only see Forums containing both of those search
        words.
      </p>
      <p>If you have the toggler set to &quot;or&quot; then the Forums will contain at least one, but not necessarily both, search words.</p>
      <p>If you would like to search a phrase, such as &quot;great app&quot;, be sure to wrap it in quotations.</p>
    </div>
  )

  return (
    <>
      <Row className="flex space-between review-controls-row">
        <Col className="left-reviews-control">
          <Input.Group compact>
            <Search
              allowClear
              style={{ width: '70%' }}
              placeholder="Search in Forum..."
              onSearch={updateReviewsText}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              enterButton
              suffix={
                <ToggleSwitch
                  key={`review-controls-toggle`}
                  checked={isToggleAnd}
                  onChange={(input) => {
                    handleToggle(input)
                  }}
                  content={data}
                  title=""
                />
              }
            />
            <Button
              className="blue-button reviews-expand-all"
              style={{ margin: '0 5px' }}
              onClick={(event) => {
                handleExpandClick(event)
              }}
            >
              {expandName}
            </Button>
          </Input.Group>
        </Col>
      </Row>
      {reviewsText.length > 0 ? (
        <Row className="review-controls-row">
          <h5>Searched: </h5>
          {reviewsText.map((searchedWord, i) => (
            <ReviewTag key={i} name={searchedWord} onClose={(e) => onClose(e, searchedWord)} />
          ))}
        </Row>
      ) : null}
    </>
  )
}

ForumControls.propTypes = {
  expandName: PropTypes.object.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isToggleAnd: PropTypes.bool.isRequired,
  forum: PropTypes.string.isRequired,
}
