import React from 'react'
import ReactDOM from 'react-dom'

import 'antd/dist/antd.min.css'

import { Authenticator } from '@aws-amplify/ui-react'

import { ThemeProvider } from './providers'

import App from './App'
import './common/styles/global.css'
import './index.css'

ReactDOM.render(
  // <React.StrictMode>
  <Authenticator.Provider>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </Authenticator.Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
)
