/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, Col, Image, Layout, Row } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { CartesianGrid, Label, Legend, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts'
import Swal from 'sweetalert2'

import { CheckUserInfo, InstructionDiv, Loading, PageHeader } from '../../components'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import { scorecardAPI } from '../../api'

import { findHeightChart } from '../../common/utils/helpers'
import './topics-page.css'

const { Content } = Layout
export const TopicsPage = () => {
  const navigate = useNavigate()

  const { setFis, isFiltersActive, topicTitle, chosenFIs, setChosenFIs, filterSelection } = useContext(FiltersContext)
  const { userInfo, getChosenFIs } = useContext(ThemeContext)
  const {
    setIsReviewsCallActive,
    topicsData,
    setTopicsData,
    topicsResponse,
    setTopicsResponse,
    isTopicsLoading,
    setIsTopicsLoading,
    isTopicsCallActive,
    setIsTopicsCallActive,
    setTopicsFilterSelection,
    currentTopicPage,
  } = useContext(DataContext)
  const [height, setHeight] = useState(findHeightChart())

  // general useEffect, empty callback
  useEffect(() => {
    window.scrollTo(0, 0)
    setHeight(findHeightChart())
  }, [])

  // useEffect that listens for isTopicsCallActive
  useEffect(() => {
    if (!isTopicsCallActive) {
      return
    }

    if (isTopicsCallActive) {
      if (!_.isEmpty(filterSelection) && !_.isEmpty(chosenFIs)) {
        setIsTopicsLoading(true)
        setTopicsFilterSelection(filterSelection)
        const sentData = {
          time: Date.now(),
          filterSelection,
          chosenFIs,
          pageNumber: currentTopicPage,
        }
        updateTopicData(sentData)
      }
    }
  }, [isTopicsCallActive, currentTopicPage])

  // useEffect that listens for isFiltersActive
  // TODO: debug this if statement. setFIs is getting hit, but not actually clearing out the FI select
  useEffect(() => {
    if (!isFiltersActive) {
      // setFIs([])
    }
  }, [isFiltersActive])

  const updateTopicData = (sentData) => {
    scorecardAPI
      .putScorecardFilterSelection(sentData)
      .then((response) => {
        if (!_.isEmpty(response)) {
          const { data } = response
          data.map((item, i) => {
            item.key = `${item._id}-${i}`
            item.fi = item._id
            item.img = `https://fi-ui-brand-logos.s3.amazonaws.com/${item._id.toLowerCase().replaceAll(' ', '-')}.png`
            item.PXI = item.pxi
            item.totalReviews = new Intl.NumberFormat().format(item.total)
          })
          setTopicsResponse(data)
          setTopicsData(data)
          setIsTopicsLoading(false)
          setIsTopicsCallActive(false)
        } else if (_.isEmpty(response)) {
          Swal.fire({
            text: 'Unfortunately these particular Topics Page filter selections yielded no results. Please select different filters and try again.',
          })
          setIsTopicsLoading(false)
          setIsTopicsCallActive(false)
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  const updateInitialTopicData = (chosenFIs) => {
    setIsTopicsLoading(true)
    // const filterSelection = {
    //   sources: [],
    //   classifications: [],
    //   sizes: [],
    //   time: {
    //     beforetimepage: 'reviews',
    //     startDate: '2019-01-01T08:00:00.000Z',
    //     endDate: '2024-10-01T07:00:00.000Z',
    //   },
    //   customerService: [],
    //   feesAndIncentives: [],
    //   productOfferings: [],
    //   accessibility: [],
    //   brandEquity: [],
    //   designAndFunctionality: [],
    //   customer: [],
    //   product: [],
    //   isCustomerServiceToggleAnd: false,
    //   isFeesAndIncentivesToggleAnd: false,
    //   isDesignAndFunctionalityToggleAnd: false,
    //   isProductOfferingsToggleAnd: false,
    //   isAccessibilityToggleAnd: false,
    //   isBrandEquityToggleAnd: false,
    //   isCustomerToggleAnd: false,
    //   isProductToggleAnd: false,
    //   isFilterTopicToggleAnd: false,
    //   pathname: '/topics',
    //   isTrendsMentionRate: false,
    //   isMultipleTopics: false,
    // }

    const sentData = {
      time: Date.now(),
      filterSelection,
      chosenFIs,
      pageNumber: currentTopicPage,
    }
    updateTopicData(sentData)
  }

  const checkUserInfo = async () => {
    const updatedChosenFIs = await getChosenFIs()
    if (!_.isEmpty(updatedChosenFIs)) {
      setChosenFIs(updatedChosenFIs)
      updateInitialTopicData(updatedChosenFIs)
    } else {
      setIsTopicsLoading(false)
    }
  }

  useEffect(() => {
    checkUserInfo()
  }, [userInfo])

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Card
          bordered={false}
          style={{ borderRadius: '10px' }}
          headStyle={{
            width: '325px',
            color: '#d6d8e0',
            backgroundColor: '#606f7d',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottom: '0.5px solid #606f7d',
            paddingLeft: '20px',
          }}
          bodyStyle={{
            color: '#d6d8e0',
            backgroundColor: '#606f7d',
            paddingTop: '6px',
            paddingLeft: '20px',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
          title={<span>{payload[0].payload._id}</span>}
        >
          <Row>
            <Col span={12}>
              <p>{`PXI: ${payload[0].value.toFixed(0)}`}</p>
              <p>{`Mention Rate: ${payload[1].value.toFixed(2)}%`}</p>
              <p>{`Reviews: ${payload[0].payload.totalReviews}`}</p>
            </Col>
            <Col span={12}>
              <Image preview={false} width="125px" style={{ paddingBottom: '20px' }} src={`${payload[0].payload.img}`} />
            </Col>
          </Row>
        </Card>
      )
    }

    return null
  }

  CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
  }

  const handleClick = (data) => {
    // setChosenFIs([data.fi])
    setTopicsData(topicsResponse)
    setFis([data.fi])
    setIsReviewsCallActive(true)
    // setValue([{ label: data.fi, value: data.fi }])
    let encodedFi = encodeURIComponent(data.fi)
    navigate(`/reviews?fi=${encodedFi}`, {
      state: {
        prevPages: [
          { pageTitle: 'Home', pagePath: '/' },
          { pageTitle: 'Topics', pagePath: '/topics' },
        ],
      },
    })
  }

  const getDataCeiling = (dataMax) => {
    if (dataMax > 75) {
      return 100
    } else if (dataMax > 50) {
      return 75
    } else if (dataMax > 25) {
      return 50
    }
    return 25
  }

  const renderCustomLegend = () => {
    return <span style={{ color: 'var(--btn-bkg)', fontSize: '0.9em' }}>This chart includes only institutions with a minimum of 500 total reviews.</span>
  }

  return (
    <>
      <Content className="page-content">
        <CheckUserInfo>
          {isFiltersActive ? (
            <Loading isLoading={isTopicsLoading}>
              <PageHeader
                title={topicTitle.length > 0 ? `Topics - ${topicTitle}` : `Topics`}
                description="Scatterplot representing the PXI of all reviews when specific topics are mentioned. Hover to see the name and corresponding data for each Financial Institution. Click to see the corresponding reviews."
              />
              <Card className="page-card align-items space-between" bordered={false}>
                <ResponsiveContainer height={height} width="100%">
                  <ScatterChart
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 5,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="var(--cartesian-gridline)" />
                    <XAxis type="number" tickCount={10} dataKey="PXI" name="PXI" domain={[-100, 100]} stroke="var(--gridlines)">
                      <Label value="PXI" offset={-10} position="insideBottom" style={{ fontSize: '1.3em', fill: '#d6d8e0' }} />
                    </XAxis>
                    <YAxis type="number" dataKey="mentionRate" domain={[0, getDataCeiling]} name="Mention Rate" unit="%" stroke="var(--gridlines)">
                      <Label value="Mention Rate" offset={0} position="insideLeft" angle={-90} style={{ fontSize: '1.3em', fill: '#d6d8e0' }} />
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />
                    <Legend formatter={renderCustomLegend} iconSize={0} align="left" style={{ backgroundColor: 'transparent !important' }} />
                    <Scatter cursor="pointer" data={topicsData} fill="var(--scatter-dot)" onClick={handleClick} />
                  </ScatterChart>
                </ResponsiveContainer>
              </Card>
            </Loading>
          ) : (
            <Card className="page-card" bordered={false} style={{ backgroundColor: '#2a3743' }}>
              <InstructionDiv />
            </Card>
          )}
        </CheckUserInfo>
      </Content>
    </>
  )
}
