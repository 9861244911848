/* eslint-disable no-unused-vars */
import { useContext, useEffect } from 'react'

// import { CSVLink } from 'react-csv'
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'
import { Grid, Layout, List, Space } from 'antd'
import _ from 'lodash'

import { CheckUserInfo, ForumControls, ForumsCard, Loading, PageHeader } from '../../components'

import { DataContext, FiltersContext, ThemeContext } from '../../providers'

import { reviewsAPI } from '../../api'

import { changeKeyData, formatReviewFIs, formatReviews } from '../../common/utils/helpers'

const { Content } = Layout
const { useBreakpoint } = Grid
export const ForumMiningPage = () => {
  const { chosenFIs, setChosenFIs, setFIs, filterSelection } = useContext(FiltersContext)
  const { userInfo, getChosenFIs } = useContext(ThemeContext)
  const { md, lg, xl, xxl } = useBreakpoint()
  const {
    forumsData,
    setForumsData,
    setLandingReviewsData,
    isReviewsLoading,
    setIsReviewsLoading,
    setResp,
    totalForums,
    setTotalForums,
    setTotalPerPage,
    setLandingTotalReviews,
    currentPage,
    setCurrentPage,
    isTextAnd,
    setIsTextAnd,
    reviewsText,
    isReviewCardExpanded,
    setIsReviewCardExpanded,
    isForumsCallActive,
    setIsForumsCallActive,
    setReviewsFilterSelection,
    // reviewsCsvData,
    // setReviewCsvData,
  } = useContext(DataContext)

  //general useEffect, empty callback
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const checkUserInfo = async () => {
    const updatedChosenFIs = await getChosenFIs()
    if (!_.isEmpty(updatedChosenFIs)) {
      setChosenFIs(updatedChosenFIs)
    } else {
      setIsReviewsLoading(false)
    }
  }

  useEffect(() => {
    checkUserInfo()
  }, [userInfo])

  //useEffect to handle isReviewsCallActive
  useEffect(() => {
    if (!isForumsCallActive) return

    if (isForumsCallActive) {
      setIsReviewsLoading(true)
      //if toggling over from Drivers for reviews
      reviewsAPI.putReviewsNewPage(1, 10, filterSelection, chosenFIs, [], false, true).then((response) => {
        setIsReviewsLoading(false)
        setReviewsFilterSelection(filterSelection)
        const { data, total, fis } = response
        // const updatedKeyData = changeKeyData(data)
        // setReviewCsvData(updatedKeyData)
        const newData = formatReviews(data, isReviewCardExpanded)
        setForumsData(newData)
        setTotalForums(total)
        setLandingTotalReviews(total)
        setLandingReviewsData(response)
        setResp(response)
        setIsForumsCallActive(false)
        // if (fis) setFIs(formatReviewFIs(fis))
      })
      //if no reviews and no filters selected
      if (forumsData.length === 0) {
        reviewsAPI.putReviewsNewPage(1, 10, {}, [], [], false, true).then((response) => {
          setIsReviewsLoading(false)
          const { data, total, fis } = response
          const newData = formatReviews(data, isReviewCardExpanded)
          setForumsData(newData)
          setTotalForums(total)
          setLandingTotalReviews(total)
          setLandingReviewsData(response)
          setResp(response)
          setIsForumsCallActive(false)
          // if (fis) setFIs(formatReviewFIs(fis))
        })
      }
    }
  }, [isForumsCallActive])

  const fetchReviews = (page, total) => {
    reviewsAPI.putReviewsNewPage(page, total, filterSelection, chosenFIs, reviewsText, isTextAnd, true).then(({ data, total, fis }) => {
      setIsReviewsLoading(false)
      // const updatedKeyData = changeKeyData(data)
      // setReviewCsvData(updatedKeyData)
      const newData = formatReviews(data, isReviewCardExpanded)
      setForumsData(newData)
      setTotalForums(total)
      // if (fis) setFIs(formatReviewFIs(fis))
    })
  }
  const handleExpandClick = () => {
    const cardData = forumsData.map((item) => {
      const { index, _id, rating, username, review_date, review_text, review_title } = item
      if (isReviewCardExpanded) {
        return {
          index,
          _id,
          rating,
          username,
          review_date,
          review_text,
          id: 'collapsed',
          review_title,
        }
      } else {
        return {
          index,
          _id,
          rating,
          username,
          review_date,
          review_text,
          id: 'expanded',
          review_title,
        }
      }
    })
    setForumsData(cardData)
    setIsReviewCardExpanded(!isReviewCardExpanded)
  }

  const handleToggle = (value) => {
    setIsTextAnd(value)
  }

  const onMoreClick = (e) => {
    let btnText
    let btnId
    if (e.target.tagName === 'SPAN') {
      btnText = e.target.innerHTML
      btnId = e.target.parentNode.dataset.id
    } else {
      btnText = e.target.firstElementChild.innerHTML
      btnId = e.target.dataset.id
    }

    if (btnText === '...more') {
      const cardData = forumsData.map((item) => {
        const { index, _id, rating, username, review_date, review_text, id, review_title } = item
        if (btnId === item.index) {
          return {
            index,
            _id,
            rating,
            username,
            review_date,
            review_text,
            id: 'expanded',
            review_title,
          }
        } else {
          return {
            index,
            _id,
            rating,
            username,
            review_date,
            review_text,
            id,
            review_title,
          }
        }
      })
      setForumsData(cardData)
    } else if (btnText === '...less') {
      const cardData = forumsData.map((item) => {
        const { index, _id, rating, username, review_date, review_text, id, review_title } = item
        if (btnId === item.index) {
          return {
            index,
            _id,
            rating,
            username,
            review_date,
            review_text,
            id: 'collapsed',
            review_title,
          }
        } else {
          return {
            index,
            _id,
            rating,
            username,
            review_date,
            review_text,
            id,
            review_title,
          }
        }
      })
      setForumsData(cardData)
    }
  }

  const ExpandName = () => {
    if (!isReviewCardExpanded) {
      return <span>{!md ? <ArrowsAltOutlined /> : 'Expand All'}</span>
    } else {
      return <span>{!md ? <ShrinkOutlined /> : `Collapse All`}</span>
    }
  }

  const Header = () => {
    return <ForumControls handleToggle={handleToggle} isToggleAnd={isTextAnd} handleExpandClick={handleExpandClick} forum="forum" expandName={<ExpandName />} />
  }

  return (
    <>
      <Content className="page-content">
        <Loading isLoading={isReviewsLoading}>
          <CheckUserInfo>
            <PageHeader title="Forums" description="Use the controls to search for key words in forum." />
            {/* <CSVLink data={reviewsCsvData} filename="Forums.csv">
              Download CSV
            </CSVLink> */}
            {forumsData.length > 0 ? (
              <List
                bordered={false}
                className="reviews-list"
                header={<Header />}
                itemLayout="vertical"
                size="small"
                pagination={{
                  current: currentPage,
                  className: 'reviews-pagination',
                  onChange: (page, total) => {
                    setCurrentPage(page)
                    setTotalPerPage(total)
                    fetchReviews(page, total)
                  },
                  showSizeChanger: true,
                  position: 'both',
                  showQuickJumper: xl || xxl ? true : false,
                  total: totalForums,
                  showTotal: (total, range) =>
                    md || lg || xl || xxl ? `${new Intl.NumberFormat().format(range[0])} - ${new Intl.NumberFormat().format(range[1])} of ${new Intl.NumberFormat().format(total)} reviews` : null,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  style: {
                    color: 'var(--btn-bkg)',
                  },
                }}
                dataSource={forumsData}
                renderItem={(item, i) => (
                  <List.Item>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                      <ForumsCard
                        key={item.id}
                        fi={item._id}
                        rating={item.rating}
                        username={item.username}
                        review_date={item.review_date}
                        review_text={item.review_text}
                        id={item.id}
                        onMoreClick={onMoreClick}
                        index={item.index}
                        title={item.review_title}
                      />
                    </Space>
                  </List.Item>
                )}
              ></List>
            ) : null}
          </CheckUserInfo>
        </Loading>
      </Content>
    </>
  )
}
