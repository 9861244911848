import React, { useContext } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { Amplify } from 'aws-amplify'

import {
  DataTablePage,
  DriversPage, // ForumMiningPage,
  HomePage,
  LoginPage,
  MentionRateTrendsPage,
  PXITrendsPage,
  PageNotFoundPage, // PracticePage,
  ReviewMiningPage,
  ScorecardPage,
  SettingsPage,
  SignupPage,
  TopicsPage, // TrendsPage,
  TrendsPage2,
} from './pages'

import { FiltersLayout, Footer, PrivateRoute } from './components'

import { DataProvider, FiltersProvider, ThemeContext } from './providers'

import replacement from '../src/assets/hearful_lp_horiz_b.jpg'
import logo_tp from '../src/assets/logo_transparent.png'
import { demoDataSet } from '../src/common/utils/data'

// import awsExports from './aws-exports'
import '@aws-amplify/ui-react/styles.css'

// Amplify.configure({
//   ...awsExports,
//   aws_cloud_logic_custom: [
//     ...awsExports.aws_cloud_logic_custom,
//     {
//       name: 'hf-financial-api',
//       endpoint: process.env.REACT_APP_API_GATEWAY_URL,
//     },
//   ],
// })

Amplify.configure({
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cloud_logic_custom: [
    {
      name: process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_NAME,
      endpoint: process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_ENDPOINT,
      region: process.env.REACT_APP_REGION,
    },
    {
      name: process.env.REACT_APP_API_GATEWAY_NAME,
      endpoint: process.env.REACT_APP_API_GATEWAY_URL,
    },
  ],
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_USERPOOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_WEBCLIENT_ID,
})

const App = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <div className={theme}>
      <img
        className="replacement-image"
        src={replacement}
        alt="Image is displayed with the following text: 'Hearful is best experienced on larger devices. Minimum viewable resolution of 820 x 1180'"
      />
      <div className="replacement-text-flex-container">
        <div className="replacement-text-flex-subcontainer1">
          <img className="logo-transparent" src={logo_tp} alt="Hearful logo" />
          <h1 className="replacement-h1">Is Best Experienced on Larger Devices</h1>
        </div>
        <div className="replacement-text-flex-subcontainer2">
          <h2 className="replacement-h2">Minimum Viewport Size of 1000 x 600</h2>
        </div>
      </div>
      <FiltersProvider>
        <DataProvider>
          <Router>
            <Routes>
              <Route path="*" element={<PageNotFoundPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/" element={<PrivateRoute />}>
                {/* <Route exact path="/home" element={<HomePage />} /> */}
                <Route exact path="/" element={<HomePage />} />
                <Route path="/" element={<FiltersLayout />}>
                  <Route path="/scorecard" element={<ScorecardPage dataSet={demoDataSet} />} />
                  <Route path="/drivers" element={<DriversPage />} />
                  <Route path="/topics" element={<TopicsPage />} />
                  <Route path="/trends" element={<TrendsPage2 />} />
                  <Route path="/pxitrends" element={<PXITrendsPage />} />
                  <Route path="/mentionratetrends" element={<MentionRateTrendsPage />} />
                  <Route path="/reviews" element={<ReviewMiningPage />} />
                  {/* <Route path="/forums" element={<ForumMiningPage />} /> */}
                  {/* <Route path="/practice" element={<PracticePage />} /> */}
                  <Route path="/data" element={<DataTablePage dataSet={demoDataSet} />} />
                  <Route exact path="/settings" element={<SettingsPage />} />
                </Route>
              </Route>
            </Routes>
          </Router>
        </DataProvider>
      </FiltersProvider>
      <Footer />
    </div>
  )
}

export default App
