import { QuestionCircleFilled } from '@ant-design/icons'
import { Popover, Switch } from 'antd'
import PropTypes from 'prop-types'

import './toggle-switch.css'

export const ToggleSwitch = (props) => {
  const { checked, onChange, popoverClassName, content, title } = props

  return (
    <>
      <Switch checked={checked} checkedChildren="and" unCheckedChildren="or" onChange={(value) => onChange(value)} />
      <Popover content={content} placement="topRight" className={popoverClassName} title={title}>
        <QuestionCircleFilled style={{ verticalAlign: 'top', color: '#D6D8E0', marginLeft: '10px' }} />
      </Popover>
    </>
  )
}

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  popoverClassName: PropTypes.string,
  content: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}
