import React, { createContext, useEffect, useState } from 'react'

import { useAuthenticator } from '@aws-amplify/ui-react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { usersAPI } from '../../api'

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [userInfo, setUserInfo] = useState({})
  const [theme, setTheme] = useState('light-theme')
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    checkUser(user)
  }, [user])

  const checkUser = async (cogUser) => {
    if (!_.isEmpty(cogUser?.attributes?.email)) {
      const {
        attributes: { email },
      } = cogUser

      const { response } = await usersAPI.getUserByEmail(email)
      if (!_.isEmpty(response)) {
        setUserInfo(response)
        getTheme(response)
      } else {
        console.warn(`UserInfo from ${email} not found! Got: ${response}`)
      }
    }
  }

  const getChosenFIs = async () => {
    if (!_.isEmpty(userInfo)) {
      const { competitiveSet, userFI } = userInfo
      if (!_.isEmpty(competitiveSet) && !_.isEmpty(userFI)) {
        const compSetFIs = competitiveSet.map((fi) => fi.value)
        let updatedChosenFIs = [...compSetFIs]
        let index = updatedChosenFIs.findIndex((item) => item.name === userFI)
        if (index === -1) {
          updatedChosenFIs.push(userFI)
        } else {
          // If found, log a message indicating that the userFI already exists
          console.log(`userFI ${userFI} already exists`)
        }
        return updatedChosenFIs
      }
      return []
    }
  }

  const getTheme = async (user) => {
    if (!_.isEmpty(user) && user?.isDarkMode) {
      setTheme('dark-theme')
      setChecked(true)
    } else {
      setTheme('light-theme')
      setChecked(false)
    }
  }

  const toggleTheme = () => {
    if (theme === 'dark-theme') {
      setTheme('light-theme')
    } else {
      setTheme('dark-theme')
    }
  }

  const setNewTheme = async () => {
    toggleTheme()

    if (!_.isEmpty(user)) {
      const {
        attributes: { email },
      } = user

      let isDarkMode = false
      if (theme === 'light-theme') {
        isDarkMode = true
        setChecked(true)
      } else {
        isDarkMode = false
        setChecked(false)
      }

      try {
        usersAPI.putUserDarkMode(isDarkMode, email)
      } catch (err) {
        console.error(err)
      }
    }
  }

  return (
    <ThemeContext.Provider
      value={{
        userInfo,
        setUserInfo,
        theme,
        setTheme,
        checked,
        setChecked,
        setNewTheme,
        getChosenFIs,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.any,
}
