import { Tag } from 'antd'
import PropTypes from 'prop-types'

import './filter-tag.css'

export const FilterTag = (props) => {
  const { name, open, tagStyle, closable, onClose } = props

  return (
    <Tag className="filter-tag" open={open} style={tagStyle} closable={closable} onClose={onClose}>
      {name}
    </Tag>
  )
}

FilterTag.propTypes = {
  tagStyle: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
}
