import { useContext } from 'react'

import { Button, Card, Col, Grid, Popover, Row } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { FiltersContext } from '../../providers'

import './trend-line-chart.css'

const { useBreakpoint } = Grid

export const TrendLineChart = ({
  data,
  lineArr,
  dataValue,
  // chartType,
  height,
  width,
  btnClassClass,
  btnFIClass,
  btnAggClass,
  onClassifiClick,
  onFIClick,
  onAggToggle,
  legendName,
  onYearlyToggle,
  timeBtnName,
}) => {
  const { chosenFIs } = useContext(FiltersContext)
  const { xs, sm, md } = useBreakpoint()

  const renderCustomLegend = (value) => {
    return <span style={{ color: 'var(--btn-bkg)', fontSize: xs ? '8px' : sm && !md ? '8px' : '12px' }}>{`${value}-${legendName}`}</span>
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const title = payload[0].payload.name
      let ending = 'PXI'
      return (
        <div className="trends-tooltip">
          <h5>{title.length === 4 ? title : `20${title}`}</h5>
          {payload.map((lineItem, i) => {
            if (dataValue === 'Mention Rate') {
              ending = '% Mention rate'
            }
            return <p key={lineItem + i} style={{ color: lineItem.color }}>{`${lineItem.name}: ${lineItem.value} ${ending}`}</p>
          })}
        </div>
      )
    }

    return null
  }

  const colors2 = ['#f8f53f', '#65f60b', 'var(--link-blue)', '#f52891', '#f58827', '#d027f5', '#27f5d9', '#f52727', '#c0f600', '#fffde6']
  const popoverContent = <p style={{ padding: '10px 10px' }}>Select between 1 and 12 Financial Institutions to see this data.</p>
  const popoverOverTenContent = <p style={{ padding: '10px 10px' }}>Select no more than 12 Financial Institutions to see this data.</p>
  return (
    <>
      <div className="trends-button-row">
        <Col>
          <Button className={`trends-btn ${btnAggClass}`} type="link" onClick={() => onAggToggle(dataValue)}>
            Aggregation
          </Button>
        </Col>
        <Col>
          {!_.isEmpty(chosenFIs) && chosenFIs.length < 13 ? (
            <Button className={`trends-btn fi-btn-tags ${btnFIClass}`} onClick={() => onFIClick(dataValue)} type="link">
              Financial Institutions
            </Button>
          ) : !_.isEmpty(chosenFIs) && chosenFIs.length > 12 ? (
            <Popover content={popoverOverTenContent}>
              <Button className={`trends-btn fi-btn-no-tags ${btnFIClass}`} onClick={() => onFIClick(dataValue)} type="link">
                Financial Institutions
              </Button>
            </Popover>
          ) : (
            <Popover content={popoverContent}>
              <Button className={`trends-btn fi-btn-no-tags ${btnFIClass}`} onClick={() => onFIClick(dataValue)} type="link">
                Financial Institutions
              </Button>
            </Popover>
          )}
        </Col>
        <Col>
          <Button className={`trends-btn ${btnClassClass}`} onClick={() => onClassifiClick(dataValue)} type="link">
            Classifications
          </Button>
        </Col>
        <Col>
          <Button className="trends-btn" type="link" onClick={() => onYearlyToggle(dataValue)}>
            {timeBtnName}
          </Button>
        </Col>
      </div>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="justify-center"
      >
        <Card className="page-card align-items space-between" bordered={false}>
          <ResponsiveContainer height={height} width="100%">
            <LineChart
              height={height}
              width={width}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} fill={'var(--hearful-blue'} strokeDasharray="1" />
              <XAxis dataKey="name" tick={{ stroke: 'var(--gridlines)', strokeWidth: 0.5 }} />
              <YAxis padding={{ bottom: 10 }} tick={{ stroke: 'var(--gridlines)', strokeWidth: 0.5 }} />
              <Tooltip content={<CustomTooltip />} />
              {lineArr.map((line, i) => {
                return <Line legendType="diamond" key={line + i} stroke={colors2[i]} dataKey={line} />
              })}
              <Legend formatter={renderCustomLegend} />
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </Row>
    </>
  )
}

TrendLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataValue: PropTypes.string.isRequired,
  // chartType: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number,
  btnClassClass: PropTypes.string.isRequired,
  btnFIClass: PropTypes.string.isRequired,
  btnAggClass: PropTypes.string.isRequired,
  onClassifiClick: PropTypes.func.isRequired,
  onFIClick: PropTypes.func.isRequired,
  onAggToggle: PropTypes.func.isRequired,
  legendName: PropTypes.string.isRequired,
  lineArr: PropTypes.array.isRequired,
  timeBtnName: PropTypes.string.isRequired,
  onYearlyToggle: PropTypes.func.isRequired,
  payload: PropTypes.array,
  active: PropTypes.bool,
}

{
  /* <div className="center-flex">
        <h4>{`${dataValue} - ${chartType}`}</h4>
      </div> */
}
