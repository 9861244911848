import { WarningOutlined } from '@ant-design/icons'
import { Layout } from 'antd'

import './instruction-div.css'

const { Content } = Layout

export const InstructionDiv = () => {
  return (
    <Content>
      <div className="center-flex instruction-div">
        <WarningOutlined style={{ color: 'var(--btn-bkg)', fontSize: '36px' }} />
        <h4> This feature requires the selection of a topic category or at least one sub-topic. Please choose one from the Filters and submit.</h4>
      </div>
    </Content>
  )
}
