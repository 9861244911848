/* eslint-disable no-unused-vars */
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { loadStripe } from '@stripe/stripe-js'
import { Button } from 'antd'
import { Auth } from 'aws-amplify'
import _ from 'lodash'

import { usersAPI } from '../../api'

import './login-page.css'

export const LoginPage = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const navigate = useNavigate()
  const services = {
    async handleSignIn(formData) {
      let { username, password } = formData

      const authResponsePromise = Auth.signIn({
        username,
        password,
      })
      const authResponse = await authResponsePromise
      // console.log(authResponse)
      if (authResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        try {
          await usersAPI.addUser(username)
        } catch (error) {
          console.error(error)
        }
      }
      localStorage.setItem('username', username)
      // console.log('Username set in local storage:', localStorage.getItem('username'))
      navigate('/')
      return authResponsePromise
    },
  }

  async function handleSubscribeClick() {
    let endpoint = location.origin
    // console.log(endpoint)
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: process.env.REACT_APP_STRIPE_PRODUCT_PRICE_ID, quantity: 1 }],
      mode: 'subscription',
      successUrl: `${endpoint}/login`,
      cancelUrl: `${endpoint}/login`,
    })
    if (!_.isEmpty(error)) {
      console.error(error)
    }
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <div>
          <h1>Welcome to Hearful</h1>
        </div>
        <div style={{ textAlign: 'center' }}>
          {authStatus !== 'authenticated' && <p style={{ textAlign: 'center', color: 'var(--hearful-blue) !important' }}>Please login</p>}
          {authStatus === 'configuring' && 'Loading...'}
          <Authenticator services={services} hideSignUp={true}>
            {({ signOut, user }) => (
              <main>
                <h4 style={{ textAlign: 'center' }}>Signed in as:</h4>
                <h4 style={{ textAlign: 'center' }}>{user?.attributes?.email}</h4>
                <div>
                  <Button type="primary" className="login-button-left" href="/">
                    Go to Home / Settings Page
                  </Button>
                  <Button className="login-button-right" onClick={signOut}>
                    Sign out
                  </Button>
                </div>
              </main>
            )}
          </Authenticator>

          {authStatus !== 'authenticated' && (
            <p style={{ marginTop: '30px', color: 'black' }}>
              <span>Don&apos;t have an account?</span>
              <br />
              <span>Click on &apos;Subscribe Now&apos; button to make an account.</span>
              <br />
              <Button onClick={handleSubscribeClick}>Subscribe Now</Button>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
