import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, Table } from 'antd'
import PropTypes from 'prop-types'

import { Loading } from '../../components'

import { DataContext } from '../../providers'

import './trending.css'

export const Trending = ({ trendingData, isLoading }) => {
  const { setIsReviewsCallActive } = useContext(DataContext)
  const navigate = useNavigate()
  const columns = [
    {
      title: 'Buzz Rank',
      dataIndex: 'rank',
      width: '130px',
      sorter: (a, b) => a.rank - b.rank,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Financial Institution',
      dataIndex: '_id',
      width: '430px',
      sorter: (a, b) => a._id.localeCompare(b._id),
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
      width: '320px',
      sorter: (a, b) => a.topic.localeCompare(b.topic),
    },
    {
      title: 'Mention Rate',
      dataIndex: 'mention',
      sorter: (a, b) => a.mention - b.mention,
      width: '155px',
      render: (_, { mention }) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ marginBottom: '0px', color: 'var(--text-heading)' }}>{mention.toFixed(1)}%</p>
          </div>
        )
      },
    },
    {
      title: 'Vs MR Avg',
      dataIndex: 'varMention',
      sorter: (a, b) => b.varMention - a.varMention,
      width: '155px',
      render: (_, { varMention }) => {
        // const totalAvgMention = trendingData.reduce((acc, data) => acc + data.avgMention, 0) / trendingData.length
        // const vsAvgMention = (record.mention - record.avgMention).toFixed(1)
        const vsAvgMention = varMention.toFixed(1)
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ marginBottom: '0px', color: 'var(--text-heading)' }}>{`${vsAvgMention}%`}</p>
          </div>
        )
      },
      // sorter: (a, b) => a.avgMention - b.avgMention,
      // render: (_, { avgMention }) => {
      //   return (
      //     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      //       <p style={{ marginBottom: '0px', color: 'var(--text-heading)' }}>{avgMention}%</p>
      //     </div>
      //   )
      // },
    },
    {
      title: 'PXI',
      dataIndex: 'pxi',
      sorter: (a, b) => a.pxi - b.pxi,
      width: '100px',
    },
    {
      title: 'Vs PXI Avg',
      dataIndex: 'varPXI',
      sorter: (a, b) => a.varPXI - b.varPXI,
      width: '135px',
      render: (_, { varPXI }) => {
        // const totalAvgPXI = trendingData.reduce((acc, data) => acc + data.avgPXI, 0) / trendingData.length
        // const vsAvgPXI = record.pxi - record.avgPXI
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ marginBottom: '0px', color: 'var(--text-heading)' }}>{`${varPXI}`}</p>
          </div>
        )
      },
      // sorter: (a, b) => a.avgPXI - b.avgPXI,
      // width: '100px',
    },
  ]

  const onRowClick = (record) => {
    // console.log('Row clicked:', record)
    // setChosenFIs([record._id])
    setIsReviewsCallActive(true)
    let encodedFi = encodeURIComponent(record._id)
    navigate(`/reviews?fi=${encodedFi}`, {
      state: {
        prevPages: [
          { pageTitle: 'Home', pagePath: '/' },
          { pageTitle: 'Trends', pagePath: '/trends' },
        ],
      },
    })
  }

  const rowProps = (record) => ({
    onClick: () => onRowClick(record), // Call onRowClick when a row is clicked
  })

  return (
    <div className="trending-content">
      <Loading isLoading={isLoading}>
        <Card className="page-card align-items space-between" bordered={false}>
          <Table
            rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light trends-row' : 'table-row-dark trends-row')}
            className="table"
            columns={columns}
            dataSource={trendingData}
            pagination={false}
            onRow={rowProps}
          />
        </Card>
      </Loading>
    </div>
  )
}

Trending.propTypes = {
  trendingData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
