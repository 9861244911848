import React from 'react'

import { Button, Card, Form, Input } from 'antd'

import './signup-page.css'

export const SignupPage = () => {
  const onFinish = (values) => {
    // Handle signup logic
    console.log('Received values:', values)

    // Handle payment logic (replace with your actual payment handling code)
    console.log('Processing payment...')
  }

  return (
    <div className="signup-container">
      <div className="signup-card">
        <div style={{ background: '#1890ff', height: '70px' }}>
          <h2 style={{ padding: '13px 25px', color: '#ffffff' }}>Create User</h2>
        </div>
        <Form className="signForm" layout="inline" name="signup" onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          {/* Your signup form fields */}
          <Form.Item
            label="Username"
            name="username"
            rules={[
              { required: true, message: 'Please input your username!' },
              { pattern: /^[a-zA-Z0-9._-]+$/, message: 'Invalid username format!' },
              { min: 8, message: 'Must be at least 8 characters!' },
              { max: 20, message: 'Must be at most 20 characters!' },
            ]}
            style={{ padding: '15px 0px' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: (
                  <span>
                    Must be at least 8 characters and start with an alphanumeric. <br />
                    Must contain letters, numbers, and at least one capital letter. <br />
                    Must contain at least one of the special characters: underscore(_), dot(.), hyphen(-), !, @, #, &, $.
                  </span>
                ),
                validator: (_, value) => {
                  // Custom validation logic for additional password requirements
                  const isValid = value && value.length >= 8 && /^[A-Za-z\d]/.test(value) && /[A-Z]/.test(value) && /[_.\-!@#&$]/.test(value)

                  if (!isValid) {
                    return Promise.reject('Invalid password format! Please check the requirements.')
                  }

                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          {/* Payment Section */}
          <Card title="" style={{ marginTop: '20px' }}>
            {/* Add your payment form fields */}
            <span>Pay with Card</span>
            <img src="https://pay.skype.com/static/3.33/commerce.payment.psp.card.capture.pci/frame-2.3/images/method_list.svg" style={{ display: 'inline-block', marginLeft: '43px' }} />
            <div style={{ borderBottom: '1px solid #000', margin: '20px -5px' }}></div>
            <Form.Item label="Card Number" name="cardNumber" rules={[{ required: true, message: 'Please input your card number!' }]} style={{ padding: '5px' }}>
              <Input />
            </Form.Item>

            <Form.Item label="Expiration Date" name="expirationDate" rules={[{ required: true, message: 'Please input the expiration date!' }]} style={{ padding: '5px' }}>
              <Input placeholder="MM/YYYY" />
            </Form.Item>

            <Form.Item label="CVV" name="cvv" rules={[{ required: true, message: 'Please input your CVV!' }]} style={{ padding: '5px' }}>
              <Input />
            </Form.Item>

            <Form.Item label="Postal Code" name="postalCode" rules={[{ required: true, message: 'Please input your postal code!' }]} style={{ padding: '5px' }}>
              <Input />
            </Form.Item>

            {/* Submit Button for Payment */}
            {/* <Button type="primary" htmlType="submit" style={{ marginTop: '10px' }}>
              Pay with Card
            </Button> */}
          </Card>

          {/* Submit Button for Signup */}
          <Form.Item wrapperCol={{ span: 24 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '13px 25px' }}>
              <a href="/login">
                <Button className="custom-cancel-button">Cancel</Button>
              </a>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
