import { useContext } from 'react'

import { WarningOutlined } from '@ant-design/icons'
import { Layout } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { ThemeContext } from '../../providers'

const { Content } = Layout

export const CheckUserInfo = (props) => {
  const { children } = props
  const { userInfo } = useContext(ThemeContext)

  return (
    <>
      {!_.isEmpty(userInfo?.userFI) && !_.isEmpty(userInfo?.competitiveSet) ? (
        <>{children}</>
      ) : (
        <Content>
          <div className="center-flex instruction-div">
            <WarningOutlined style={{ color: 'var(--btn-bkg)', fontSize: '36px' }} />
            <h4>
              No data found for selected FIs. Please check User FI and/or Competitive FI Set in <a href="/settings">settings</a> page.
            </h4>
          </div>
        </Content>
      )}
    </>
  )
}

CheckUserInfo.propTypes = {
  children: PropTypes.node.isRequired,
}
