import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { Spin } from 'antd'
import { Auth } from 'aws-amplify'

export const PrivateRoute = () => {
  const [loginUser, setLoginUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser()
      setLoginUser(user)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // console.log(error)
    }
  }

  if (isLoading) {
    return (
      <div className="private-route flex align-center mt-50" style={{ flexDirection: 'column', color: 'var(--hearful-blue)' }}>
        <Spin size="large" />
        <p>Loading</p>
      </div>
    )
  }

  return loginUser ? <Outlet className="private-route" /> : <Navigate to="/login" className="private-route" />
}
